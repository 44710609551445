import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { toast } from 'react-toastify';
import { Icon, Segment } from 'semantic-ui-react';
import { useLightbox } from 'simple-react-lightbox';
import styled from 'styled-components';
import { useStore } from '../../../app/stores/store';
import LoadingComponent from '../../layout/LoadingComponent';
import ChatAttachmentHiddenImage from '../chat/ChatAttachmentHiddenImage';
import ChatAttachmentImage from '../chat/ChatAttachmentImage';

//#region Styling
const StyledSegment = styled(Segment) <{ hasView: boolean, isAttachment: boolean, isMyMessage: boolean }>`
    padding: 6px !important;
    margin: 0px 0px 0 0 !important;
    display: flex;
    flex-direction: flex-end;
    box-shadow:none;
    transition: box-shadow .3s;
    justify-content: left;
    max-width: auto;
    min-width: 12em;
    border-radius: 0 !important;
    margin: 0 0 !important;
    height: 5em;
    ${p => (p.hasView ? `
        &:hover{
            margin-top: -1px;
            margin-left: -1px;
            box-shadow: 0 0 11px rgba(33,33,33,.2);
            cursor: pointer;
        }
    ` : '')};
    
`;
const ActionWrappers = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;

`;

const StyledIcon = styled(Icon)`
    && {
        box-shadow: none !important;
        &:hover{
            background-color: #eee;
            cursor: pointer;
        }
    }
`;

const FileNameContainer = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 70px;
    `;

const AttachemntDetails = styled.div`
    height: 2em !important;
`;

//#endregion

interface Props {
    fileName: string;
    fileId: string;
    size: number;
    lightBoxIndex: number;
    onRemove?: (fileName: string) => void | undefined;
    isOpen?: boolean;
    attachmentId?: string;
    onDelete?: (attachmentId: string) => void | undefined;
    isLast?: boolean;
    fileType?: string;
    isMyMessage?: boolean;
}


const AttachmentLoadingIndicator = () => {

    return (
        <>
            <StyledSegment 
               >
                { <Icon size='big' color='grey' name='file alternate outline' style={{ margin: '7px 5px 7px 0' }} />}
                <AttachemntDetails className='container-attachment' >
                    <LoadingComponent content="Sending..." />
                </AttachemntDetails>
            </StyledSegment>
        </>
    );
}

export default observer(AttachmentLoadingIndicator);