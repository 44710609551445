import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agents";
import { CardDate } from "../models/cardDate";

export default class CardDateStore {

    cardDates: CardDate[] = [];
    cardDate: CardDate | undefined;

    constructor() {
        makeAutoObservable(this);
    }

    addSchedule = async (cardDate) => {
        try {
            runInAction(async () => {
                await agent.CardDate.addSchedule(cardDate).then(() => this.getSchedule(cardDate.cardId));
            });
            toast.success('Schedule was saved successfully.');

        } catch (e) {
            console.error(e);
        }
    }

    getSchedule = async (id: string) => {
        try {
            const schedule = await agent.CardDate.getSchedule(id);
            this.cardDate = schedule;
            return this.cardDate;

        } catch (e) {
            console.log(e);
        }
    }

    deleteSchedule = async (id: string) => {
        try {
            runInAction(async () => {
                await agent.CardDate.deleteSchedule(id);
                this.cardDate = undefined;
            });
        } catch (e) {
            console.log(e);
        }
    }
}



