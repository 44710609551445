import { observer } from "mobx-react-lite";
import { FaDownload } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import styled from "styled-components";
import { useStore } from "../../stores/store";

const CloseButton = styled.div`
    position: absolute;
    margin: 0;
      top: 3%;
    left: 99%;
    -ms-transfor
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
   
    & .exit-view-attachment {
        font-size: 30px;
        color: #8a8a8a;

        &:hover{
             color: white;
             cursor: pointer;
        }
    }
`;

const DownloadButton = styled.div`
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    position: absolute;
    margin: 0;
      top: 92%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: rgba(0,0,0,.6);
    border-radius: 10px;
     & .download-attachment {
        font-size: 30px;
        color: #8a8a8a;

        &:hover{
             color: white;
             cursor: pointer;
        }
    }
`;

interface Props {
    attachmentId: string;
    fileName: string;
    onClose: () => void;
}

const ActionsContainer = ({ attachmentId, fileName, onClose }: Props) => {
    const { mediaStore } = useStore();

    const handleDownloadAttachment = () => {
        mediaStore.downloadAttachment(attachmentId, fileName);
    }

    return (
        <>
            <CloseButton onClick={onClose}>
                <MdOutlineClose className="exit-view-attachment" />
            </CloseButton>
            <DownloadButton onClick={handleDownloadAttachment} >
                <FaDownload className="download-attachment" />
            </DownloadButton>
        </>
    )
}

export default observer(ActionsContainer);
