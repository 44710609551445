import { saveAs } from 'file-saver';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { Button, Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import agent from '../../app/api/agents';
import TableColumnPopover from '../../app/common/popover/TableColumnPopover';
import PaginatedTable from '../../app/common/table/PaginatedTable';
import DateHelper from '../../app/helpers/dateHelper';
import { TimeRecord } from '../../app/models/timeRecord';
import { TimeRecordFilterValues } from '../../app/models/common/filterValues';
import { useStore } from '../../app/stores/store';

const dateHelper = new DateHelper();

const StyledHeader = styled(Header)`margin-bottom: 20px !important;`;

const StyledColumn = styled(Grid.Column)`
        display: flex !important;
        align-items: flex-end !important;
        justify-content: flex-end !important;
`;

const StyledContainer = styled.div`
        padding: 1rem 3rem 5rem 3rem;
`;

const DtrSummaryList = () => {
    const { timeRecordStore, commonStore } = useStore();
    const { filterTimeRecords, isTimeRecordsLoading, timeRecords, timeRecordsTotal } = timeRecordStore;

    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    const [filterValues, setFilterValues] = useState<TimeRecordFilterValues>({
        pageSize: 20,
        pageNumber: 1,
        searchKeyword: "",
        startDate: null,
        endDate: null,
        department: '',
        dtrStatusId: ''
    });

    const handleOnChangePage = (page: number) => {
        setFilterValues({ ...filterValues, pageNumber: page })
    }

    const handleSearch = (searchText: string) => {
        if (filterValues.searchKeyword != searchText) {
            setFilterValues({ ...filterValues, pageNumber: 1, searchKeyword: searchText.trim() });
        } else {
            setFilterValues({ ...filterValues, searchKeyword: searchText.trim() });
        }
    }

    const handleDateRangeFilter = (startDate?: Date, endDate?: Date) => {
        if (startDate && endDate) {
            if (filterValues.startDate != startDate && filterValues.endDate != endDate) {
                setFilterValues({ ...filterValues, pageNumber: 1, startDate: startDate, endDate: endDate });
            }
            else
                setFilterValues({ ...filterValues, startDate: startDate, endDate: endDate });
        } else if (startDate == null && endDate == null) {
            setFilterValues({ ...filterValues, startDate: startDate, endDate: endDate });
        }
    }

    const downloadFile = async (query: TimeRecordFilterValues) => {
        setIsDownloading(true);

        await agent.DtrSummary.download(query, { responseType: 'blob' })
            .then((response) => {
                const filename = `Daily-Time-Records-Summary.xlsx`;

                saveAs(response, filename);

                setIsDownloading(false);
            })
            .catch((e) => {
                setIsDownloading(false);

                console.error(e);

                toast.error('An error has occurred');
            });
    };

    const handleDownload = async () => {
        await downloadFile(filterValues);
    };

    const onStatusFilterChanged = statusId => {
        setFilterValues(prev => {
            return { ...prev, dtrStatusId: statusId, pageNumber: 1 };
        });
    };

    useEffect(() => {
        filterTimeRecords(filterValues);

    }, [filterValues, filterTimeRecords])

    useEffect(() => {
        if (commonStore.getDtrStatuses.length <= 0)
            commonStore.loadDtrStatuses();
    }, []);

    const dtrStatuses = commonStore.getDtrStatuses.filter(g => g.text.toLowerCase() != "absent");

    const statusOptionFilter = { options: dtrStatuses, label: "Status", placeholder: "All", onChange: onStatusFilterChanged, value: filterValues.dtrStatusId };

    return (
        <StyledContainer>
            <StyledHeader>
                <Grid>
                    <Grid.Column width={8}>
                        Daily Time Record Summary
                    </Grid.Column>
                    <StyledColumn width={8}>
                        <Button onClick={handleDownload} className={isDownloading ? 'ui loading button' : ''} icon='file excel outline' color='green' content='Export Report' />
                    </StyledColumn>
                </Grid>
            </StyledHeader>
            <PaginatedTable<TimeRecord>
                columns={[
                    {
                        title: '#', field: 'orderNumber',
                        cellStyle: {
                            width: 50,
                            maxWidth: 50
                        },
                        headerStyle: {
                            width: 50,
                            maxWidth: 50
                        },
                    },
                    {
                        title: 'Employee Name', field: 'user.name',
                        cellStyle: {
                            width: 250,
                            minWidth: 250
                        },
                        headerStyle: {
                            width: 250,
                            minWidth: 250
                        },
                    },
                    {
                        title: 'Time In', field: 'timeInDate',
                        render: (data: TimeRecord) => {
                            if (data.timeInDate)
                                return dateHelper.formatShortMonthDateTime(data.timeInDate.toString());
                            return '';
                        },
                        cellStyle: {
                            width: 250,
                            minWidth: 250
                        },
                        headerStyle: {
                            width: 250,
                            minWidth: 250
                        },
                    },
                    {
                        title: 'Time Out', field: 'timeOutDate',
                        render: (data: TimeRecord) => {
                            if (data.timeOutDate)
                                return dateHelper.formatShortMonthDateTime(data.timeOutDate.toString());
                            return '';
                        },
                        cellStyle: {
                            width: 250,
                            minWidth: 250
                        },
                        headerStyle: {
                            width: 250,
                            minWidth: 250
                        },
                    },
                    {
                        title: 'Total Time', field: 'user.name',
                        render: (data: TimeRecord) => {
                            if (data.timeInDate && data.timeOutDate) {
                                let timeIn = moment(data.timeInDate);
                                let timeOut = moment(data.timeOutDate);
                                let difference = timeOut.diff(timeIn);
                                let duration = moment.duration(difference);
                                let hourDuration = Math.floor(duration.asHours());
                                let minSecDuration = moment.utc(difference).format('mm:ss');

                                return `${hourDuration}:${minSecDuration}`;
                            }
                            return '';
                        },
                        cellStyle: {
                            width: 250,
                            minWidth: 250
                        },
                        headerStyle: {
                            width: 250,
                            minWidth: 250
                        },
                    },
                    {
                        title: 'Late Reason',
                        render: (data: TimeRecord) => (
                            <TableColumnPopover width={ 200} content={data.reason} />
                        ),
                        cellStyle: {
                            width: 300,
                            minWidth: 300
                        },
                        headerStyle: {
                            width: 300,
                            minWidth: 300
                        },
                    },
                    { title: 'Department', field: 'user.department.name' },
                ]}
                isLoading={isTimeRecordsLoading}
                data={timeRecords!}
                total={timeRecordsTotal}
                onChangePage={handleOnChangePage}
                pageSize={filterValues.pageSize}
                pageNumber={filterValues.pageNumber}
                onSearchChange={handleSearch}
                toolbar={true}
                search={true}
                sorting={false}
                draggable={false}
                searchLabel='Employee Name'
                dateFilterLabel='Filter by Date'
                onDateRangeFilter={handleDateRangeFilter}
                dateRangeValues={[filterValues.startDate!, filterValues.endDate!]}
                dateRangeFilter={true}
                customOptionFilters={[statusOptionFilter]}
            />
        </StyledContainer>
    );
}

export default observer(DtrSummaryList);