import saveAs from 'file-saver';
import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import agent from '../api/agents';
import { FilterValues } from '../models/common/filterValues';
import { Overtime } from '../models/overtime';
import { store } from './store';

export default class OvertimeStore {
    overtimes: Overtime[] = [];
    overtimesTotal: number = 0;
    isOvertimeListLoading: boolean = false;
    isInitialLoading: boolean = false;

    overtime: Overtime | undefined;
    isOvertimeDetailsLoading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    filterOvertimes = async (filters: FilterValues, isInitial?: boolean) => {
        if (isInitial) this.isInitialLoading = true;
        this.isOvertimeListLoading = true;

        try {
            let { total, data } = await agent.OvertimeRequests.filter({
                id: filters.id,
                pageNumber: filters.pageNumber,
                pageSize: filters.pageSize,
                searchKeyword: filters.searchKeyword

            });

            runInAction(() => {
                this.overtimes = data.map(o => {
                    if (o.otherApproverId) {
                        o.approver.name = "";
                    }

                    return o;
                });
                this.overtimesTotal = total;
                this.isOvertimeListLoading = false;
                this.isInitialLoading = false;

                this.retrieveAndRenderOtherApprover();

            });
        } catch (e) {
            console.log(e);
            this.isOvertimeListLoading = false;
            this.isInitialLoading = false;
        }
    }

    retrieveAndRenderOtherApprover = async () => {
        if (this.overtimes.length > 0) {
            let otherUserIds = new Array<{
                id: string,
                clientId: string,
                clientName: string
            }>();

            this.overtimes
                .forEach(overtime => {
                    if (overtime.otherApproverId && !otherUserIds.some(i => i.id === overtime.otherApproverId)) {
                        otherUserIds.push({
                            id: overtime.otherApproverId,
                            clientId: overtime.approver.clientId,
                            clientName: overtime.approver.name
                        });
                    }
                });

            otherUserIds.forEach(async otherUser => {
                let name = "";

                try {
                    const user = await agent.Clients.getExternalUser(otherUser.id, otherUser.clientId);
                    name = user.name;

                } catch (e) {
                    name = otherUser.clientName;
                }

                this.overtimes = this.overtimes
                    .map(overtime => {
                        if (otherUser.id === overtime.otherApproverId) {
                            overtime.approver.name = name;
                        }

                        return overtime;
                    });
            });
        }
    }

    loadOvertime = async (id: string) => {
        this.isOvertimeDetailsLoading = true;
        try {
            const overtime = await agent.OvertimeRequests.get(id);
            runInAction(() => {
                this.overtime = overtime;
                this.isOvertimeDetailsLoading = false;

            })
        } catch (e) {
            console.log(e);
            this.isOvertimeDetailsLoading = false;
        }
    }

    approveOvertime = async (id: string, filters: FilterValues) => {
        this.isOvertimeListLoading = true;

        try {
            await agent.OvertimeRequests.approve(id, store.userStore.displayName);
            await this.filterOvertimes(filters);
            toast.success('Overtime approved.');
            this.isOvertimeDetailsLoading = false;

        } catch (e) {
            console.log(e);
            this.isOvertimeDetailsLoading = false;

        }
    }

    rejectOvertime = async (id: string, filters: FilterValues) => {
        this.isOvertimeDetailsLoading = true;

        try {
            await agent.OvertimeRequests.reject(id, store.userStore.displayName);
            await this.filterOvertimes(filters);
            toast.success('Overtime rejected.');
            this.isOvertimeDetailsLoading = false;

        } catch (e) {
            console.log(e);
            this.isOvertimeDetailsLoading = false;

        }
    }
}