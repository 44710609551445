import { useField } from 'formik';
import * as React from 'react';
import { Form, Label, TextArea } from 'semantic-ui-react';
import styled from 'styled-components';

interface Props {
    placeholder: string;
    name: string;
    label?: string;
    type?: string;
    rows: number;
    isResizable?: boolean;
    value?: string;
}

const StyledTextArea = styled(TextArea) <{ isResizable?: boolean }>`
            resize: ${p => p.isResizable ? `vertical` : `none`} !important;
`;

export default function FormTextArea(props: Props) {
    const [field, meta] = useField(props.name);
    return (
        <Form.Field error={meta.touched && !!meta.error} style={{ textAlign: 'left' }}>
            <label style={{ textAlign: 'left', color: '#6a7e99', fontSize: '12px' }}>
                {props.label}
            </label>
            <StyledTextArea {...field} {...props} />
            {meta.touched && meta.error ? (<Label style={{ marginTop: '10px' }} basic color='red'>{meta.error}</Label>) : null}
        </Form.Field>
    );
}