import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { store } from "../stores/store";

const axiosService = axios.create();

axiosService.defaults.baseURL = process.env.REACT_APP_API_URL;

axiosService.interceptors.request.use(config => {
    const token = store.authStore.token;
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
});

axiosService.interceptors.response.use(async res => {
    return res;
}, (error: AxiosError) => {
    const { status, data, headers } = error.response!;
    switch (status) {
        case 400:
            toast.error(data);
            break;
        case 401:
            if (headers['www-authenticate'] && headers['www-authenticate'].startsWith('Bearer error="invalid_token"')) {
                store.modalStore.closeModal();
                store.authStore.logout();
                toast.error('Session expired. Please login again', {
                    toastId: 'session-expired'
                });
            } else if (data)
                toast.error(data);
            else {
                toast.error("Unauthorized.");
            }
            break;
        case 403:
            if (data) {
                toast.error(data);
            } else {
                toast.error('Unable to login. Please contact your system administrator.');
            }
            break;
        case 404:
            if (data) {
                toast.error(data);
            } else {
                toast.error('Not Found');
            }
            break;
        case 409:
            if (data) {
                toast.error(data);
            } else {
                toast.error('Conflict');
            }
            break;
        case 500:
            //toast.error('Server Error');
            break;
    }
    return Promise.reject(error);
});

export default axiosService;