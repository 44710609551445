import { Collapse, fade, makeStyles, Paper, Typography } from '@material-ui/core';
import InputCard from './InputCard';
import React, { useState } from 'react';

const useStyle = makeStyles((theme) => ({
    root: {
        width: '272px',

    },
    addCard: {
        padding: theme.spacing(1, 1, 1, 2),
        margin: theme.spacing(0, 1, 1, 1),
        backgroundColor: '#ebecf0',
        '&:hover': {
            backgroundColor: fade('#000', 0.25)
        }
    },
    addCardButton: {
        cursor:'pointer'
    }
}));


interface Props {
    listId?: string;
}

const InputCardContainer = ({ listId }: Props) => {
    const classes = useStyle();
    const [open, setOpen] = useState(false);

    const handleOnClickAdd = () => {
        setOpen(!open);
    }

    return (
        <div className={classes.root}>
            <Collapse in={open} timeout={85}>
                <InputCard setOpen={setOpen} listId={listId!} open={ open} />
            </Collapse>
            <Collapse in={!open} timeout={0}>
                <Paper className={classes.addCard} elevation={0} onClick={handleOnClickAdd} >
                    <Typography className={classes.addCardButton}>
                        + Add a Task
                    </Typography>
                </Paper>
            </Collapse>

        </div>
    );
}

export default InputCardContainer;