import Birthday from "./Birthday";

const SidePanel = () => {
    return (
        <div className='side-panel'>
            <Birthday />
        </div>
        )
}

export default SidePanel;