import { Popover } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from '../../stores/store';
import styled from 'styled-components';
import { MdOutlineClose } from 'react-icons/md';

const CloseIcon = styled(MdOutlineClose)`
    border-radius: 4px;
    color: #42526e;
    height: 32px;
    margin: 4px;
    overflow: hidden;
    padding: 4px;
    position: absolute;
    right: 0;
    top: 0;
    transition: background-color 85ms,color 85ms;
    width: 32px;
    z-index: 2;
    cursor:pointer;

    &:hover {
        background-color: #091e4214;
        color: #42526e;
    }
`;

const StyledModalContent = styled.div`
    && {
        background-color: #fff;
    }
`;

const PopoverContainer = () => {
    const { popOverStore } = useStore()
    const { isOpen, popOver, closePopover } = popOverStore;

    return (
        <Popover
            id={isOpen ? 'popover-common' : undefined}
            open={isOpen}
            anchorEl={popOver.anchorEl}
            onClose={popOverStore.closePopover}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            elevation={1}
        >
            <CloseIcon onClick={() => closePopover()} />
            <StyledModalContent>
                {popOverStore.popOver.body}
            </StyledModalContent>
        </Popover>
    );

}

export default observer(PopoverContainer);