import {  Header as SemanticUIHeader } from "semantic-ui-react";
import styled, { DefaultTheme } from "styled-components";

export const defaultTheme: DefaultTheme = {
    borderRadius: '0',
    palette: {
        common: {
            black: '#222831',
            white: '#ffffff'
        },
        primary: {
            main: '#1592ff',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#709fb0',
            contrastText: '#ffffff'
        }
    },
    padding: {
        featureContainer: '1rem 3rem 5rem 3rem'
    }
}

export const Wrapper = styled.div`
        padding: ${props => props.theme.padding.featureContainer};
`;

export const CardWrapper = styled.div`
        padding: 1.5em 2em;
        background-color: #fff;
        border-radius: 16px;
`;

export const Header = styled(SemanticUIHeader)`margin-bottom: 20px !important;`;