import { observer } from "mobx-react-lite";
import { Redirect, Route, Switch } from "react-router-dom";
import { Container, Placeholder } from "semantic-ui-react";
import DailyReportRequestList from '../../features/daily-reports/DailyReportList';
import DtrSummaryList from "../../features/dtr-summary/DtrSummaryList";
import EmployeeList from "../../features/employees/dashboard/EmployeeList";
import EmployeeDetailsPage from "../../features/employees/details/EmployeeDetailsPage";
import BoardDetails from "../../features/kanban/boards/BoardDetails";
import BoardForm from "../../features/kanban/boards/BoardForm";
import BoardList from "../../features/kanban/boards/BoardList";
import WorkSpaceForm from "../../features/kanban/workspaces/WorkSpaceForm";
import WorkSpaceList from "../../features/kanban/workspaces/WorkSpaceList";
import WorkSpaceMemberList from "../../features/kanban/workspaces/WorkSpaceMemberList";
import LeaveRequestDetails from "../../features/leave-requests/LeaveRequestDetails";
import LeaveRequestList from "../../features/leave-requests/LeaveRequestList";
import OvertimeRequestDetails from "../../features/overtime-requests/OvertimeRequestDetails";
import OvertimeRequestList from "../../features/overtime-requests/OvertimeRequestList";
import SubClientList from "../../features/subClients/SubClientList";
import UndertimeRequestDetails from "../../features/undertime-requests/UndertimeRequestDetails";
import UndertimeRequestList from "../../features/undertime-requests/UndertimeRequestList";
import ForgotPasswordForm from "../../features/users/ForgotPasswordForm";
import LoginForm from "../../features/users/LoginForm";
import ResetPasswordForm from "../../features/users/ResetPasswordForm";
import Newsfeed from "../../features/newsfeeds/Newsfeed";
import { Role } from "../enums/role.enum";
import PrivateRoute from "./PrivateRoute";
import { useEffect } from "react";
import { useStore } from "../stores/store";
import NotificationPage from "../common/notification/NotificationPage";
import TimeAdjustmentList from "../../features/time-adjustment-request/TimeAdjustmentList";
import TimeAdjustmentDetails from "../../features/time-adjustment-request/TimeAdjustmentDetails";
import RosterSchedules from "../../features/roster-schedules/RosterSchedules";

function ApplicationRouter() {
    const { commonStore } = useStore();

    useEffect(() => {
        if (window.innerWidth <= 992) {
            commonStore.setSideBar(true);
        }

        window.matchMedia("(min-width: 992px)").addEventListener('change', (e) => {
            commonStore.setSideBar(!e.matches);
        });
    }, [])

    return (
        <>
            <Switch>
                <PrivateRoute exact={true} roles={[Role.CLIENT]} path='/' component={() => <Redirect to="/newsfeed" />} />
                <PrivateRoute exact={true} roles={[Role.CLIENT]} path='/notifications' component={NotificationPage} />
                <PrivateRoute exact={true} roles={[Role.CLIENT]} path='/report/daily-reports' component={DailyReportRequestList} />
                <PrivateRoute exact={true} roles={[Role.CLIENT]} path='/messages' component={() => <Placeholder name='Messages' />} />
                <PrivateRoute roles={[Role.CLIENT]} path='/employees/:id' component={EmployeeDetailsPage} />
                <PrivateRoute exact={true} roles={[Role.CLIENT]} path='/external-users' component={SubClientList} />
                <PrivateRoute exact={true} roles={[Role.CLIENT]} path='/employees' component={EmployeeList} />
                <PrivateRoute roles={[Role.CLIENT]} path='/requests/leave-requests/:id' component={LeaveRequestDetails} />
                <PrivateRoute roles={[Role.CLIENT]} path='/requests/leave-requests/:id' component={LeaveRequestDetails} />
                <PrivateRoute roles={[Role.CLIENT]} path='/requests/overtime-requests/:id' component={OvertimeRequestDetails} />
                <PrivateRoute roles={[Role.CLIENT]} exact={true} path='/requests/leave-requests' component={LeaveRequestList} />
                <PrivateRoute roles={[Role.CLIENT]} exact={true} path='/requests/overtime-requests' component={OvertimeRequestList} />
                <PrivateRoute roles={[Role.CLIENT]} exact={true} path='/requests/undertime-requests' component={UndertimeRequestList} />
                <PrivateRoute roles={[Role.CLIENT]} exact={true} path='/requests/time-adjustments' component={TimeAdjustmentList} />
                <PrivateRoute roles={[Role.CLIENT]} exact={true} path='/requests/time-adjustments/:id' component={TimeAdjustmentDetails} />
                <PrivateRoute roles={[Role.CLIENT]} exact={true} path='/report/dtr-summary' component={DtrSummaryList} />
                <PrivateRoute roles={[Role.CLIENT]} path='/requests/undertime-requests/:id' component={UndertimeRequestDetails} />
                <PrivateRoute roles={[Role.CLIENT]} exact={true} path='/workspaces' component={WorkSpaceList} />
                <PrivateRoute roles={[Role.CLIENT]} exact={true} path='/workspaces/add' component={WorkSpaceForm} />
                <PrivateRoute roles={[Role.CLIENT]} exact={true} path='/workspaces/workspace-members/:id' component={WorkSpaceMemberList} />
                <PrivateRoute roles={[Role.CLIENT]} path='/workspaces/edit/:id' component={WorkSpaceForm} />
                <PrivateRoute roles={[Role.CLIENT]} path='/workspaces/board/:id' component={BoardList} />
                <PrivateRoute roles={[Role.CLIENT]} exact={true} path='/workspaces/projects/add' component={BoardForm} />
                <PrivateRoute roles={[Role.CLIENT]} exact={true} path='/workspaces/projects-edit/:id' component={BoardForm} />
                <PrivateRoute roles={[Role.CLIENT]} path='/workspaces/projects/:id' component={BoardDetails} />
                <PrivateRoute roles={[Role.CLIENT]} path='/newsfeed' component={Newsfeed} />
                <Route component={PublicLayout} />
            </Switch>
        </>
    )
}

const PublicLayout = () => (
    <>
        <Route path='/login' component={LoginForm} />
        <Route path='/forgotpassword' component={ForgotPasswordForm} />
        <Route path='/Account/ResetPassword/:id' component={ResetPasswordForm} />
    </>
);

export default observer(ApplicationRouter);