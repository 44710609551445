import { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import agent from "../../app/api/agents";
import { PostLikes } from "../../app/models/post";
import { useStore } from "../../app/stores/store";

interface Props {
    postLikes: PostLikes
    commentCount: number
}

const PostStats = (props: Props) => {
    const { userStore: { user } } = useStore();

    const getLikes = () => {
        let container = '';

        if (props.postLikes) {
            let postLikes = props.postLikes;
            let totalLikes = postLikes.likes.length;
            let otherLikesCount = totalLikes;
            if (postLikes.liked) {
                container += 'You ';
                otherLikesCount--;

                if (totalLikes == 2) {
                    container += 'and ' + postLikes.likes.filter(l => l.user.id !== user?.id)[0].user.name;
                }
                else if (totalLikes > 2) {
                    otherLikesCount--;

                    container += ', ' + postLikes.likes.filter(l => l.user.id !== user?.id)[0].user.name + ' and ' + otherLikesCount + ' others';
                }
            }
            else {
                if (totalLikes == 1) {
                    container += postLikes.likes[0].user.name;
                }
                else if (totalLikes == 2) {
                    container += postLikes.likes[0].user.name + ' and ' + postLikes.likes[1].user.name;
                }
                else if (totalLikes > 2) {
                    otherLikesCount -= 2;

                    container += postLikes.likes[0].user.name + ' and ' + postLikes.likes[1].user.name + ' and ' + otherLikesCount + ' others';
                }
            }
        }


        return container;
    }

    const like = getLikes();

    return (
        <div className='stats'>
            {like &&
                <div className='like-stats'>
                    <Icon color='blue' name='thumbs up outline' /><span>{like}</span>
                </div>
            }
            <div className='comment-stats'>
                <span>{props.commentCount}</span> {props.commentCount === 1 ? <>Comment</> : <>Comments</>}
            </div>
        </div>
    )
}

export default PostStats;