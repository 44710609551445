import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { Menu, MenuItemProps, Popup } from "semantic-ui-react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useStore } from "../../../app/stores/store";

const swal = withReactContent(Swal);

const useStyle = makeStyles((theme) => ({
    moreIcon: {
        fontSize: '20px',
        borderRadius: '4px',
        width: '25px',
        height: '25px',
        alignSelf: 'flex-start',
        padding: '4px',
        '&:hover': {
            backgroundColor: '#cbc3c3',
            cursor: 'pointer'
        }
    }
}));

interface Props {
    listId: string;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CardListTitlePopup = ({ listId, setOpen }: Props) => {
    const { cardGroupStore } = useStore();
    const { deleteCardGroup } = cardGroupStore;
    const classes = useStyle();
    const [open, setIsOpen] = useState(false);

    return (
        <Popup
            position='bottom left'
            basic
            offset={[0, 0]}
            content={
                <Menu
                    items={[
                        {
                            key: 'header',
                            content: 'List Action',
                            header: true,
                            style: {
                                width: '100%',
                                fontSize: '1.1em', borderBottom: 'solid 1px #ddd2d2',
                                borderRadius: '0', textAlign: 'center',
                                background: 'unset',
                                cursor: 'default'
                            }
                        },
                        { key: 'delete', content: 'Delete', icon: 'trash alternate' },
                        { key: 'edit', content: 'Edit', icon: 'edit outline' },
                    ]}
                    onItemClick={(event: React.MouseEvent<HTMLAnchorElement>, data: MenuItemProps) => {
                        if (data.index == 1) {
                            swal.fire({
                                title: 'Delete?',
                                text: "Are you sure you want to delete this list?",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Confirm'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    deleteCardGroup(listId);
                                }
                            });
                        } else if (data.index == 2) {
                            setOpen(true);
                        }

                        setIsOpen(false);
                    }}
                    secondary
                    vertical
                />
            }
            on='click'
            onClose={() => setIsOpen(false)}
            onOpen={() => setIsOpen(true)}
            open={open}
            popper={{ id: 'popper-container', style: { zIndex: 2000 } }}
            trigger={<FiMoreHorizontal className={classes.moreIcon} />}
        />
    );
}

export default CardListTitlePopup;