import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PaginatedTable from '../../app/common/table/PaginatedTable';
import { FilterValues } from '../../app/models/common/filterValues';
import { SubClient } from '../../app/models/subClient';
import { useStore } from '../../app/stores/store';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';

const swal = withReactContent(Swal);

const StyledHeader = styled(Header)`margin-bottom: 20px !important;`;

const StyledColumn = styled(Grid.Column)`
        display: flex !important;
        align-items: flex-end !important;
        justify-content: flex-end !important;
`;

const StyledContainer = styled.div`
        padding: ${props => props.theme.padding.featureContainer};
`;

const SubClientList = () => {
    const { subClientStore } = useStore();
    const { filterSubClients, isSubClientListLoading, subClients,
        subClientsTotal, deleteSubClient } = subClientStore;
    const history = useHistory();

    const [filterValues, setFilterValues] = useState<FilterValues>({
        pageSize: 20,
        pageNumber: 1,
        searchKeyword: ""
    });

    const handleSearch = (searchText: string) => {
        if (filterValues.searchKeyword != searchText) {
            setFilterValues({ ...filterValues, pageNumber: 1, searchKeyword: searchText });
        } else {
            setFilterValues({ ...filterValues, searchKeyword: searchText });
        }
    }

    const handleOnChangePage = (page: number) => {
        setFilterValues({ ...filterValues, pageNumber: page })
    }

    useEffect(() => {
        filterSubClients(filterValues);
    }, [filterValues, filterSubClients])

    return (
        <>
            <StyledContainer>
                <StyledHeader>
                    <Grid>
                        <Grid.Column width={8}>
                            External Users
                        </Grid.Column>
                        <StyledColumn width={8}>
                            <Button icon='add' color='blue' as={Link} to='/settings/external-users/add' content='Add a New External User' />
                        </StyledColumn>
                    </Grid>
                </StyledHeader>
                <PaginatedTable<SubClient>
                    columns={[
                        { title: 'Name', field: 'name' },
                    ]}
                    isLoading={isSubClientListLoading}
                    data={subClients!}
                    total={subClientsTotal}
                    onChangePage={handleOnChangePage}
                    pageSize={10}
                    pageNumber={filterValues.pageNumber}
                    toolbar={true}
                    search={true}
                    onSearchChange={handleSearch}
                    sorting={false}
                    draggable={false}
                    actions={[
                        rowData => ({
                            icon: () => <FaPencilAlt style={{ color: '#97a4b5', fontSize: '16px' }} />,
                            tooltip: 'Edit',
                            onClick: e => {
                                history.push(`/settings/external-users/edit/${rowData.id}`)
                            },
                        }),
                        rowData => ({
                            icon: () => <FaTrashAlt style={{ color: '#97a4b5', fontSize: '16px' }} />,
                            tooltip: 'Delete',
                            onClick: e => {
                                swal.fire({
                                    title: 'Delete?',
                                    text: "Are you sure you want to delete this External User?",
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    cancelButtonText: 'No',
                                    confirmButtonText: 'Yes'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        swal.disableButtons();
                                        deleteSubClient(rowData.id, filterValues);
                                    }
                                });
                            },
                        }),
                    ]}
                />
            </StyledContainer>
        </>

    );
};

export default observer(SubClientList);