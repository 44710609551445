import { useEffect } from 'react';
import MainPanel from './MainPanel';
import './newsfeed.css';
import SidePanel from './SidePanel';


const Newsfeed = () => {
    return (
        <div className='newsfeed'>
            <MainPanel />
            <SidePanel />
        </div>
    )
}

export default Newsfeed;