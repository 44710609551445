import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { useStore } from "../../stores/store";
import ActionsContainer from "./ActionsContainer";
import AttachmentFileName from "./AttachmentFileName";
import AttachmentLoading from "./AttachmentLoading";
import ImageAttachment from './ImageAttachment';
import VideoAttachment from './VideoAttachment';

const StyledDiv = styled.div`
        z-index: 101;
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0,0,0,.87);
        animation: zoomInOverlay 0.5s forwards;

        @keyframes zoomInOverlay {
        from { opacity: 0; }
        to { opacity: 1; }
}
`;

const Container = styled.div`
        height: 75%;
        width: 75%;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        animation: zoomInOverlay 1s forwards;

        @keyframes zoomInOverlay {
            from { opacity: 0; }
            to { opacity: 1; }
        }
`;

interface Props {
    attachmentId: string;
    attachmentType: string;
    attachmentName: string;
    onPreviewClose: () => void;
}

const ViewRequestAttachment = ({ attachmentId, attachmentType, attachmentName, onPreviewClose }: Props) => {
    const { mediaStore } = useStore();
    const [dataUri, setDataUri] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        if (attachmentId) {
            mediaStore.getRequestAttachment(attachmentId)
                .then(media => {
                    if (media) {
                        setDataUri(media.url)
                        setIsLoading(false);
                    }
                }).catch((e) => {
                    setIsLoading(false);
                    console.error(e);
                });
        }

        const handleKeyDown = (e) => {
            if (e.keyCode == 27) {
                setIsLoading(false);
                onPreviewClose();
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        // Don't forget to clean up
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }

    }, [attachmentId])

    let renderImage: boolean = (!isLoading && dataUri.trim().length !== 0 && attachmentType.includes("image"));
    let renderVideo: boolean = (!isLoading && dataUri.trim().length !== 0 && attachmentType.includes("video"));

    return (
        createPortal(
            <StyledDiv>
                {isLoading && < AttachmentLoading />}

                {!isLoading && <Container>

                    {renderImage && <ImageAttachment imageUri={dataUri} />}

                    {renderVideo && <VideoAttachment videoUri={dataUri} type={attachmentType} />}

                </Container>}

                {!isLoading && <AttachmentFileName fileName={attachmentName} />}

                {< ActionsContainer
                    attachmentId={attachmentId}
                    fileName={attachmentName}
                    onClose={onPreviewClose}
                />}

            </StyledDiv>
            , document.body
        )
    )
};

export default observer(ViewRequestAttachment);