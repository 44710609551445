import { useState } from 'react';
import { Popup } from 'semantic-ui-react';

interface Props {
    content: string;
    color: string;
    cursor?: string;
    tooltip?: string | null;
}

const StyleCellTable = (props: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleMouseEnter = () => {
        setIsOpen(true);
    };

    const handleMouseLeave = () => {
        setIsOpen(false);
    };

    const cellStyle = {
        color: props.color,
        cursor: props.cursor || 'default',
    };

    const contentElement = (
        <span
            style={cellStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {props.content}
        </span>
    );

    return props.tooltip ? (
        <Popup
            content={props.tooltip}
            trigger={contentElement}
            on="hover"
            open={isOpen}
            hideOnScroll
            onClose={() => setIsOpen(false)}
        />
    ) : (
        contentElement
    );
};

export default StyleCellTable;
