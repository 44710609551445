import { observer } from "mobx-react-lite";
import { Placeholder } from 'semantic-ui-react';
import styled from "styled-components";


const NotificationPlaceholderContainer = styled.div`
    && {
      width: 95%;
      margin-top: 10px;
    }
`;


function NotificationPlaceholder() {

    return (
        <NotificationPlaceholderContainer>
            <Placeholder style={{marginTop: '1rem', maxWidth: "100%"}}>
                <Placeholder.Header image >
                    <Placeholder.Line style={{ width: '100%' }} />
                    <Placeholder.Line />
                </Placeholder.Header>
            </Placeholder>
            <Placeholder style={{ marginTop: '1rem', maxWidth: "100%" }}>
                <Placeholder.Header image >
                    <Placeholder.Line style={{ width: '100%' }} />
                    <Placeholder.Line />
                </Placeholder.Header>
            </Placeholder>
            <Placeholder style={{ marginTop: '1rem', maxWidth: "100%" }}>
                <Placeholder.Header image >
                    <Placeholder.Line style={{ width: '100%' }} />
                    <Placeholder.Line />
                </Placeholder.Header>
            </Placeholder>
            <Placeholder style={{ marginTop: '1rem', maxWidth: "100%" }}>
                <Placeholder.Header image >
                    <Placeholder.Line style={{ width: '100%' }} />
                    <Placeholder.Line />
                </Placeholder.Header>
            </Placeholder>
            <Placeholder style={{ marginTop: '1rem', maxWidth: "100%" }}>
                <Placeholder.Header image >
                    <Placeholder.Line style={{ width: '100%' }} />
                    <Placeholder.Line />
                </Placeholder.Header>
            </Placeholder>
            <Placeholder style={{ marginTop: '1rem', maxWidth: "100%" }}>
                <Placeholder.Header image >
                    <Placeholder.Line style={{ width: '100%' }} />
                    <Placeholder.Line />
                </Placeholder.Header>
            </Placeholder>
            <Placeholder style={{ marginTop: '1rem', maxWidth: "100%" }}>
                <Placeholder.Header image >
                    <Placeholder.Line style={{ width: '100%' }} />
                    <Placeholder.Line />
                </Placeholder.Header>
            </Placeholder>
     
        </NotificationPlaceholderContainer>
    )
}

export default observer(NotificationPlaceholder);