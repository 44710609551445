import { useState } from "react";
import { Button } from "semantic-ui-react";
import AvatarImage from "../../app/common/image/AvatarImage";
import TextEditor from "../../app/common/text-editor/TextEditor";
import { useStore } from "../../app/stores/store";

interface Props {
    message?: string
    onSubmit?: any
    onClickClose?: any
    isAvatarHidden?: boolean
    loading?: boolean
}

const CommentForm = ({ onSubmit, onClickClose, isAvatarHidden, message, loading }: Props) => {
    const { userStore } = useStore();
    const [comment, setComment] = useState(message ? message : "");

    const handleSubmit = (e) => {
        e.preventDefault();

        if (onSubmit) {
            onSubmit(comment);
        }
    }

    return (
        <form onSubmit={handleSubmit} className='comment-form'>
            <div className={`comment-header ${!isAvatarHidden ? '' : 'no-avatar'}` }>
                {!isAvatarHidden && <AvatarImage userId={userStore.user?.id!} hasPhoto={userStore.user?.hasProfilePicture!} />}
                <TextEditor className='text-editor' placeholder='Write a comment' value={comment} onChange={setComment} />
                {/*<textarea placeholder='Write a comment' defaultValue={message ? message : ''} name='message' rows={4}></textarea>*/}
            </div>
            <div className='comment-form-actions'>
                <Button disabled={loading} loading={loading} type='submit' className='save'>Save</Button>
                <Button onClick={onClickClose} className='cancel'>Cancel</Button>
            </div>
        </form>
    )
}

export default CommentForm;