import { useField } from 'formik';
import * as React from 'react';
import { Form, Label } from 'semantic-ui-react';

interface Props {
    placeholder: string;
    name: string;
    label?: string;
    type?: string;
    style?: any;
    ref?: any;
}

export default function FormTextInput(props: Props) {
    const [field, meta] = useField(props.name);
    return (
        <Form.Field error={meta.touched && !!meta.error} style={{ textAlign: 'left' }}>
            <label style={{ textAlign: 'left', color: '#6a7e99', fontSize: '12px' }}>
                {props.label}
            </label>
            <Form.Input
                {...field}
                {...props}
                style={props.style}
                ref={props.ref}
                focus={true}
            />
            {meta.touched && meta.error ? (<Label style={{ marginTop: '10px' }} basic color='red' pointing>{meta.error}</Label>) : null}
        </Form.Field>
    );
}