import { observer } from 'mobx-react-lite';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { ScheduleNotification } from '../../app/models/scheduleNotification';
import { useStore } from '../../app/stores/store';
import NotificationList from './NotificationList';

//#region Styling
const StyledHeader = styled.h5`
&& {
    text-align: center;
    font-size: 23px;
    font-weight: bold;
}
`;

const StyledSpan = styled.span`
&& {
}
`;

const StyledTaskTitle = styled.span`
&& {
    display: block;
    color: #ababab;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    font-size: 18px;
    margin-top: 18px;
}
`;

const StyledButton = styled(Button)`
&& {
    padding-left: 11px !important;
    padding-right: 11px !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
`;
//#endregion


interface Props {
    notifications: ScheduleNotification[];
}

const NotificationDetails = ({ notifications }: Props) => { 
    const { notificationModalStore } = useStore();
    const { closeModal } = notificationModalStore;

     

    const handleCloseClick = () => {
        closeModal();
    }
 
    return (
        <>

            <StyledHeader>
                <StyledSpan>
                    <Icon size='small' color='blue' name='bell' />
                </StyledSpan>Reminder
            </StyledHeader>
            <NotificationList notifications={notifications} />
            <StyledButton type="button" className="ui primary button" onClick={handleCloseClick}>OK</StyledButton>
        </>
    );
}

export default observer(NotificationDetails);