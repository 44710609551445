import { useField } from 'formik';
import React from 'react';
import Select, { Props as SelectProps } from 'react-select';
import { Form, Label } from 'semantic-ui-react';

const selectStyles = {
    container: (provided, state) => ({
        ...provided,
        margin: '5px',
        width: '97%'
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        overflowY: 'auto',
        maxHeight: '100px'
    }),
    menuList: (provided, state) => ({
        ...provided,
        overflowY: 'auto',
        maxHeight: '190px'
    }),
}

interface Props extends SelectProps {
    name: string;
}

const ReceiverMultiSelect = (props: Props) => {
    const [field, meta, { setValue, setTouched }] = useField(props.name);

    return (
        <Form.Field error={meta.touched && !!meta.error} style={{ textAlign: 'left' }}>
            <Select
                styles={selectStyles}
                {...field}
                {...props}
                name={field.name}
                onChange={(options) => setValue(options)}
                onBlur={() => setTouched(true)}
                isMulti
                autoFocus
            />
            {meta.touched && meta.error ? (
                <Label
                    style={{ marginTop: '10px' }}
                    pointing
                    basic
                    color='red'
                >{meta.error}</Label>
            ) : null}
        </Form.Field>
    );
}

export default ReceiverMultiSelect;