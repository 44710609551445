import {
    Button, ChatContainer, ConversationHeader, Message, MessageInput, MessageList, MessageSeparator
} from '@chatscope/chat-ui-kit-react';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { observer } from "mobx-react-lite";
import { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from "react";
import Linkify from 'react-linkify';
import { toast } from "react-toastify";
import { Dropdown, Grid, Header, Icon } from "semantic-ui-react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import styled from "styled-components";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { MessageAttachment } from "../../models/message";
import { useStore } from "../../stores/store";
import AddMemberModal from "./AddMemberModal";
import ChatAttachment from "./ChatAttachment";
import ChatInputAttachment from './ChatInputAttachment';
import EditGroupChatNameModal from './EditGroupChatNameModal';
import LeaveGroupModal from './LeaveGroupModal';
import ViewMembersModal from './ViewMembersModal';
const swal = withReactContent(Swal)
// #region Styling
const MinimizeIcon = styled(Icon)`
    && {
        color: #6ea9d7;
        margin-right: 5px;
        &:hover{
            opacity: .6;
            cursor: pointer;
        }
    }
`;

const dropdownStyle = ({ hover }) => ({

    color: hover ? '#9cc3e1' : '#6ea9d7',
    margin: '0px 4.9em 0.2em .2em'
  
})


const ConversationContainer = styled.div<{ isShow: boolean, isMinimized: boolean }>`
    && {
        display: ${p => p.isShow ? 'block' : 'none'};
        position: fixed;
        right: 15px;
        bottom: 0px;
        background-color: #fff;
        width: 350px;
        max-height:450px;
        box-shadow: 0 0 5px #ccc;
        border-radius: 5px 5px 0 0;
        &:hover{
            ${p => p.isMinimized ? `cursor:pointer` : ''}
        }
    }
`;

const SystemGeneratedText = styled.small`
    && {
        color: #9b9b9b;
        font-size: 12px;
        display: block;
        text-align: center;
        margin: 10px 0 20px 0;
    }
`;
const UnreadMessagesContainer = styled.div`
    && {
        position: relative;
        margin: auto;
        background-color: #6c757d !important;
        cursor: pointer;
        color: rgba(0,0,0,.87);
        height: 2em; 
        width: fit-content;
        padding: 0.2rem;
        border-radius: 2px;
    }
`;
const UnreadMessagesWrapper = styled.div`
    && {
        position: fixed;
        width: 350px;
        margin-left: -11px;
        margin-top: 5px;
        z-index: 999;
    }
`;
const UnreadMessage = styled.span`
    && {
      color: aliceblue;
      font-size: 10px;
      padding: 1rem;
    }
`;
const Sending = styled.span`
    && {
       float: right;
    font-size: .7em;
    color: rgba(77,77,77,.87);
    margin-top: 0px;
    width:100%;
    text-align: end;
 
    }
`;

const ConversationName = styled(Header)`
    && {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width:300px;
    }
`;

const AttachmentContainer = styled.div < { isMinimize: boolean }>`
    && {
    bottom: 5em;
    position: relative; 
    height: 105px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 5px;
    margin: 10px;
    display: ${p => p.isMinimize ? 'none' : 'grid'};
    flex-direction: row;
    &::-webkit-scrollbar {
        display: none;
    }

    }
`;
const MessageListContainer = styled.div<MessageList>`
overflow-y: auto !important;
overscroll-behavior-y: none;
    overflow-anchor: auto;
    touch-action: none;

.cs-message-list {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow: hidden;
    min-height: 1.25em;
    position: relative;
    color: rgba(0,0,0,.87);
    background-color: #fff;
}
.scrollbar-container {
    position: relative;
    height: 100%;
}
.ps {
    overflow: hidden !important;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
}
.cs-message-list__scroll-wrapper {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    padding: 0 1.2em 0 0.8em;
}
::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    ::-webkit-scrollbar {
        width: 5px;
        background-color: #c6e3fa;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
        background-color: #c6e3fa;
    } 
    ::scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
        background-color: #c6e3fa;
    }

`;

const StyledMessage = styled(Message)`
    .cs-message__content{
        padding: 0 !important;
        border-radius: 0.7em !important;
        width: fit-content !important;
    }
    .cs-message__content-wrapper{
        max-width: 80%;
       
    }
`;
const StyledDropdownItem = styled(Dropdown.Item)`
    &:hover{
        background: aliceblue;
     }
`;
// #endregion
const data = {
   scrollHeightCounter: 0,
   scrollHeight: 0,
}
const attachLightBoxIndex = (attachments: MessageAttachment[]) => {
    let count = 0;
    return attachments.map(a => {
        if (a.fileName.match(/.(jpg|jpeg|png)$/i)) {
            let attachment = {
                ...a,
                index: count
            }
            count++;
            return attachment;
        } else {
            return a;
        }
    })
}

const htmlFrom = (htmlString) => {
    const cleanHtmlString = DOMPurify.sanitize(htmlString,
        { USE_PROFILES: { html: true } });
    const html = parse(cleanHtmlString);
    return html;
}

function MessageContainer() {
    const { chatStore, messageStore, conversationStore, modalStore, userStore } = useStore();
    const { messageBoxShow, hideMessageBox, dataURLtoFile, isMymessage, isImageAttachmentLoading } = chatStore;
    const { messages, readMessages, setMessages, addMessageWithAttachment,
        isMinimize, setIsMinimize, files, setFiles, unreadMessages, messagesWithDates,
        unreadScrollHeight, setUnreadScrollHeight, setIsBackread, isBackread
    } = messageStore;
    const { selectedConversation, loadMoreConversation } = conversationStore;
    const [content, setContent] = useState("");
    const [showChatMenu, toggleshowChatMenu] = useState(false);
    const [hover, setHover] = useState(false);
    const { user, getClientUsedStorage } = userStore;
    const handleClickShowChatMenu = () => {
        toggleshowChatMenu(!showChatMenu);
    };
    let messagesEndRef: HTMLDivElement;
    let firstUnreadMessage: HTMLDivElement;
    let msgListRef = useRef<MessageList>(null)
    const inputFile = useRef<HTMLInputElement>(null);
    const inputRef = useRef<any>();
    const [modalState, setmodalState] = useState(false);
    const [viewMembersModalState, setViewMembersModalState] = useState(false);
    const [viewEditModalState, setEditMembersModalState] = useState(false);
    const [leaveGroupModalState, setLeaveGroupModalState] = useState(false);
    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {

       inputRef.current.focus();
        if (!content && event.target.files?.length! > 0) setContent(' ');

        if (event.currentTarget.value === "" || event.target.files == null) {
            toast.error('No file Selected');
            return;
        }

        for (const file of event.target.files as any) {
            if (file.size > 100000000) {
                toast.error('File must not exceed 100 MB');
                return;
            }
            file.id = 'files' + files.length;
            files.push(file);
        }
        //const mergingFiles = files != null ? files : [];

        //setFiles([...mergingFiles, ...Array.from(event.target.files)]);
        event.currentTarget.value = "";
    }

    const handleFileUpload = () => {
        if (inputFile != null && inputFile.current != null)
            inputFile.current.click();
    }
   

    const handleMessageOnChange = (value: string) => setContent(value);

    const handleSend = async () => {
        const cleanedContent = content.replace(/&nbsp;|<br>/g, ' ').trim();
        if ((selectedConversation && cleanedContent.length > 0) || files.length > 0) {

            if (files.length > 0) {
                let totalFileSize = 0;

                for (var i in files) {
                    totalFileSize += files[i].size/(1024*1024);
                }

                let usedStorage = await getClientUsedStorage(user!.clientId);

                if (Math.round(parseInt(usedStorage!.totalSize)) + Math.round(totalFileSize) > 10000) {
                    toast.error('You have reached your 10,000 MB storage capacity for attachment.')
                } else {
                    let response = addMessageWithAttachment(
                        {
                            content: cleanedContent,
                            conversationId: selectedConversation?.id,
                            receiversId: [],
                            connectionId: chatStore.connectionId,
                            files: files
                        }
                    );
                    setContent('');
                    setFiles([]);
                    scrollToBottom();
                }
            } else {
                let response = addMessageWithAttachment(
                    {
                        content: cleanedContent,
                        conversationId: selectedConversation?.id,
                        receiversId: [],
                        connectionId: chatStore.connectionId,
                        files: files
                    }
                );
                setContent('');
                setFiles([]);
                scrollToBottom();
            }
        } else {
            toast.error('You cannot send an empty message.')
        }
        //msgListRef.current.scrollToBottom()
    }

    const scrollToBottom = () => {
        
        //if (messagesEndRef)
        //    messagesEndRef.scrollIntoView({ block: "end" })
        if (msgListRef)
            msgListRef.current.scroll({ top: msgListRef.current.scrollHeight });
    }
    const scrollToFirstUnread = async () => {
    
        if (firstUnreadMessage && msgListRef) {
            let messageIds:string[] = [];

            unreadMessages.forEach((msg, i) => {
                messageIds.push(msg.id);
            });
            await readMessages(messageIds)
           
            msgListRef.current!.scrollTop = firstUnreadMessage.offsetTop - msgListRef.current!.offsetTop + 40
            
        }
    }
    const handleMinimization = (e: SyntheticEvent) => {
        e.stopPropagation();
        setIsMinimize(true);
    }

    const handleCloseMessageBox = () => {
        setMessages([]);
        setContent("");
   
        hideMessageBox();
        data.scrollHeightCounter = 0;
    }

    const handleScroll = async (e) => {
        setIsBackread((e.target.scrollHeight - e.target.scrollTop) > 600);
        if (unreadScrollHeight > 330 && data.scrollHeightCounter > 1) {

            if ((((e.target.scrollHeight - e.target.scrollTop) - (unreadScrollHeight * .08)) > (unreadScrollHeight))) {
                let messageIds: string[] = [];

                unreadMessages.forEach((msg, i) => {
                    messageIds.push(msg.id);
                });
                await readMessages(messageIds)
            }
        } else {
            let messageIds: string[] = [];

            unreadMessages.forEach((msg, i) => {
                messageIds.push(msg.id);
            });
            await readMessages(messageIds)
        }
    }
  
   
    const handlePaste = (e) => {
        e.preventDefault();
        const clipboardItems = (e.clipboardData || e.originalEvent.clipboardData).items;
        const item = clipboardItems[0];
        if (item.type.split('/')[0].toString() == 'image') {
            let file = item.getAsFile();
            if (file.size > 100000000) {
                toast.error('File must not exceed 100 MB');
                return;
            }
            file.id = 'files' + files.length;
            files.push(file)
            setContent(" ");
         
        } else if (item.type.toString() == 'text/html') {
           
            item.getAsString((data) => {
                let html = htmlFrom(data);
                let imgUrl = html[1].props.src;
                var file = dataURLtoFile(imgUrl, 'image.png') as any;
                if (file.size > 100000000) {
                    toast.error("File must not exceed 100 MB.")
                    return;
                } 
                file.id = 'files' + files.length;
                files.push(file)

            });
            setContent(" ");
        } else if (item.type.toString() == 'text/plain') {
            setContent(content + e.clipboardData.getData("text"));
        }
    }
  

    useEffect(() => {
        scrollToBottom();
    }, []);


    useEffect(() => {
        if (!isBackread) {
            scrollToBottom();
        }
    }, [messages]);

    useEffect(() => {
        if (!isImageAttachmentLoading && isMymessage) {
            /* msgListRef.current.scrollToBottom()*/
            scrollToBottom();
        }
       
    }, [isImageAttachmentLoading]);

    useEffect(() => {
        setmodalState(false)
        setViewMembersModalState(false)
        setEditMembersModalState(false)
        setLeaveGroupModalState(false)
        scrollToBottom();
    }, [messageBoxShow]);
 

    return (
        <>
          
            <ConversationContainer
                isShow={messageBoxShow} isMinimized={isMinimize} onClick={() => setIsMinimize(false)} >
                <input type='file' id='attachment' multiple ref={inputFile} onChange={handleFileChange} style={{ display: 'none' }} />
                <ChatContainer
                  
                    style={{ borderRadius: '5px 5px 0 0' }}>
                    <ConversationHeader style={{ borderRadius: '5px 5px 0 0' }}>
                        <ConversationHeader.Content style={{ flexFlow: "row", alignItems: "flex-end", justifyContent: "start" }} >
                            <ConversationName as='h4' style={{ marginBottom: ".37em" }}>{selectedConversation?.name!}</ConversationName>
                            {!isMinimize && selectedConversation?.type == "Group" ?
                                <Dropdown
                                    style={dropdownStyle({ hover })}
                                    onPointerOver={() => setHover(true)}
                                    onPointerOut={() => setHover(false)}
                                    title="Manage Group" icon='chevron down' className='small dropdown-button' pointing='top left'
                                >
                                    <Dropdown.Menu >
                                        <Dropdown.Item
                                            title="" 
                                            className='small dropdown-button' text='Add Member' icon='add user' onClick={() => { setmodalState(true); }} />
                                        <Dropdown.Item
                                            title="" 
                                            className='small dropdown-button' text='View Members' icon='eye' onClick={() => { setViewMembersModalState(true); }} />
                                        <Dropdown.Item
                                            title="" 
                                            className='small dropdown-button' text='Edit group name' icon='edit' onClick={() => { setEditMembersModalState(true) }} />
                                        <Dropdown.Item
                                            title=""
                                            className='small dropdown-button' text='Leave group' icon='sign out' onClick={() => { setLeaveGroupModalState(true) }} />
                                    </Dropdown.Menu>
                                </Dropdown>
                                : null}
                        </ConversationHeader.Content>
                        <ConversationHeader.Actions>
                            {!isMinimize && <MinimizeIcon name='minus' onClick={handleMinimization} />}
                            <Button onClick={handleCloseMessageBox} icon={<Icon name='close' />} labelPosition="left" />
                        </ConversationHeader.Actions>
                      
                    </ConversationHeader>

                    {/*<MessageList  ref={msgListRef}*/}
                    {/*    onScroll={handleScroll}*/}
                    {/*    style={{ display: isMinimize ? 'none' : 'block', overflowY: 'auto', height: '350px', marginBottom: '70px', paddingBottom: files.length ? '80px' : '5px' }}*/}
                    {/*>*/}
                    <MessageListContainer as={MessageList} ref={ msgListRef}
                        className='cs-message-list scrollbar-container ps cs-message-list__scroll-wrapper'
                        onScroll={handleScroll}
                        style={{ display: isMinimize ? 'none' : 'block', overflowY: 'auto',  marginBottom: '70px', height: files.length ? '270px' : '350px' }}

                    >
                        
                        {
                            unreadMessages.length>0 && <UnreadMessagesWrapper>
                            <UnreadMessagesContainer onClick={scrollToFirstUnread} >
                                    <UnreadMessage>{unreadMessages.length} Unread Messages.</UnreadMessage>
                            </UnreadMessagesContainer>
                             </UnreadMessagesWrapper>
                        }
                    
                        {
                            messagesWithDates.map( (message, index) => {
                               
                                let isSystemGeneratedMessage = message.content?.includes('[[SystemGenerated]]');
                                let isValidAndNotSameSender = index != 0 && messages[index - 1].sender !== message.sender || index != 0 && messages[index - 1].content?.includes('[[SystemGenerated]]');
                                let senderNameLabel = isValidAndNotSameSender && !message.isMyMessage && message.isGroupConversation ? message.sender : '';

                                return (
                                    <div key={message.id} ref={el => {
                                        if (messages.length - 1 === index && el)
                                            messagesEndRef = el
                                        if (unreadMessages.length > 0 && message.id === unreadMessages[0].id && el) 
                                            firstUnreadMessage = el

                                        if (el && el.scrollHeight > 0) {
                                            if (!message.isMyMessage && !isSystemGeneratedMessage && !message.messageReadReceiptUserIds?.includes(userStore.user!.id) && data.scrollHeightCounter < unreadMessages.length) {
                                                data.scrollHeightCounter++
                                                setUnreadScrollHeight(el.scrollHeight);
                                            }
                                        } 

                                    }}>
                                        {isSystemGeneratedMessage ?
                                          
                                            (
                                                <>
                                                    {
                                                      message.sentDate != "" ?
                                                            <MessageSeparator content={message.sentDate} />
                                                            : null
                                                    }
                                                    <SystemGeneratedText>{message.content?.replace('[[SystemGenerated]]', '')}</SystemGeneratedText>
                                                   

                                                </>
                                            )
                                            : (
                                                <>
                                               
                                                    {
                                                        (message.sentDate && !message.isMessageUploading) && <MessageSeparator content={message.sentDate} />
                                                          
                                                    }
                                                    <div style={{ textAlign: 'left', color: '#6a7e99', fontSize: '12px', lineHeight:'.4em' }}>
                                                        <label>
                                                            {senderNameLabel}
                                                        </label>
                                                   </div>

                                                    {message.attachments.length ? (
                                                        <SimpleReactLightbox>
                                                            <SRLWrapper>
                                                                <StyledMessage
                                                                    style={{
                                                                        fontSize: '14px',
                                                                    }}
                                                                    key={message.id}
                                                                    model={{
                                                                        message: message.content,
                                                                        sentTime: "15 mins ago",
                                                                        sender: message.sender,
                                                                        direction: message.isMyMessage ? "outgoing" : "incoming",
                                                                        position: "single"
                                                                    }}
                                                                >
                                                                    <Message.CustomContent>
                                                                        <div style={{ padding: message.content ? '0.6em 0.9em' : '0.1em 0.0em', backgroundColor: message.content ? '' : '#fff' }}>
                                                                           
                                                                            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => <a target="blank" rel="noopener" href={decoratedHref} key={key}>
                                                                                {decoratedText}
                                                                            </a>}>
                                                                                {htmlFrom(message.content)}
                                                                            </Linkify>
                                                                        </div>
                                                                        <div style={{ borderRadius: '0 0 0.7em 0.7em', borderRight: message.isMessageUploading ? '0px solid #d4d4d5' : '2px solid #d4d4d5' }}>
                                                                            {message.attachments.length ? attachLightBoxIndex(message.attachments).map((a, i, arr) => (
                                                                                <ChatAttachment
                                                                                    key={a.id}
                                                                                    attachmentId={a.id}
                                                                                    isLast={i + 1 == arr.length}
                                                                                    fileName={a.fileName}
                                                                                    size={a.size}
                                                                                    lightBoxIndex={a.index!}
                                                                                    fileType={a.type}
                                                                                    isMyMessage={message.isMyMessage}
                                                                                    isUploading={message.isMessageUploading}
                                                                                />
                                                                            )) : ''}
                                                                          
                                                                        </div>
                                                                      
                                                                    </Message.CustomContent>
                                                                   
                                                                    {
                                                                        message.isMyMessage ?
                                                                            !message.isMessageUploading && < Message.Footer style={{ marginBottom: '5px', fontSize: '.7em ' }} sentTime={message.sentTime} /> :
                                                                            !message.isMessageUploading && <Message.Footer style={{ marginBottom: '5px', fontSize: '.7em ' }} sender={message.sentTime} />

                                                                    }
                                                                    {
                                                                        message.isMyMessage &&
                                                                        message.isMessageUploading &&
                                                                        <Message.Footer style={{ marginBottom: '5px', fontSize: '.7em ' }} sentTime="" /> 
                                                                            

                                                                    }
                                                                
                                                               
                                                                </StyledMessage>
                                                               
                                                            </SRLWrapper>
                                                        </SimpleReactLightbox>
                                                    ) : (
                                                            <>
                                                                
                                                            <Message
                                                                style={{
                                                                    fontSize: '14px',
                                                                    width: '100%',
                                                                    maxWidth: '80%'

                                                                }}
                                                                key={message.id}
                                                                model={{

                                                                    sentTime: message.sentTime,
                                                                    sender: message.sender,
                                                                    direction: message.isMyMessage ? "outgoing" : "incoming",
                                                                    position: "single"
                                                                }}
                                                            >
                                                        
                                                                <Message.CustomContent
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                  
                                                                    <Linkify properties={{ color: '#006dd9; !important' }} componentDecorator={(decoratedHref, decoratedText, key) => <a style={{ color: '#006dd9' }} target="blank" rel="noopener" href={decoratedHref} key={key}>
                                                                        {decoratedText}
                                                                    </a>}>
                                                                       
                                                                        {message.content}
                                                                    </Linkify>
                                                                </Message.CustomContent>
                                                                {
                                                                    message.isMyMessage ?
                                                                            message.sentTime != "" ? !message.isMessageUploading &&<Message.Footer style={{
                                                                            marginBottom: '5px',
                                                                            fontSize: '.7em '
                                                                        }}
                                                                            sentTime={message.sentTime} /> : null
                                                                        :
                                                                            message.sentTime != "" ? !message.isMessageUploading &&<Message.Footer style={{
                                                                            marginBottom: '5px',
                                                                            fontSize: '.7em '
                                                                        }}
                                                                            sender={message.sentTime} /> : null
                                                                }

                                                                </Message>
                                                                {message.isMessageUploading && <Sending>Sending...</Sending>} 
                                                            </>
                                                    )}

                                                </>

                                            )}


                                    </div>

                                );
                            })}

 
                       
                        </MessageListContainer>
                    {/*</MessageList>*/}
                    
                    <div is='MessageInput'>
                        {files.length ? (
                            <AttachmentContainer isMinimize={isMinimize}>
                                <Grid style={{
                                    marginLeft: '.5em', marginBottom: '2.4em', marginTop: '0.2em'
                                 } } >
                                    {files.map(f => (
                                        <Grid.Column key={f.id} width={8} style={{ paddingLeft: 0, paddingBottom: 0, paddingTop: '.3em' }} >
                                            <ChatInputAttachment
                                                fileName={f.name}
                                                fileId={f.id}
                                                size={f.size}
                                                lightBoxIndex={0}
                                                onRemove={fileId => setFiles(files.filter(fs => fs.id !== fileId))}
                                        />

                                 </Grid.Column >
                                ))
                                }
                            </Grid>
                            </AttachmentContainer>
                            //<AttachmentContainer isMinimize={isMinimize}>
                            //    {files.map(f => (
                            //        <ChatInputAttachment
                            //            fileName={f.name}
                            //            size={f.size}
                            //            lightBoxIndex={0}
                            //            onRemove={fileName => setFiles(files.filter(fs => fs.name !== fileName))}
                            //        />

                            //    ))
                            //    }

                            //</AttachmentContainer>

                        ) : ''}

                        <MessageInput
                            ref={inputRef} 
                            onPaste={handlePaste}
                            sendDisabled={!(files.length > 0 || content)}
                            attachButton={true}
                            placeholder="Type message here"
                            value={content}
                            onChange={handleMessageOnChange}
                            onSend={handleSend}
                            onAttachClick={handleFileUpload}
                            style={{ display: isMinimize ? 'none' : 'inline-flex', position: 'fixed', bottom: '5px', width: '340px', maxHeight: '60px', overflowY: 'auto' }}
                        >
                        </MessageInput>
                    </div>
                </ChatContainer>
            </ConversationContainer>
            <AddMemberModal open={modalState} setOpen={setmodalState} />
            <ViewMembersModal open={viewMembersModalState} setOpen={setViewMembersModalState} />
            <EditGroupChatNameModal open={viewEditModalState} setOpen={setEditMembersModalState} groupChatName={selectedConversation?.name!} />
            <LeaveGroupModal open={leaveGroupModalState} setOpen={setLeaveGroupModalState} />
        </>
    )
}

export default observer(MessageContainer);