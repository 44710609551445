import { User } from "../../app/models/user";
import { Comment } from "semantic-ui-react";
import { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import moment from "moment";

interface Props {
    user: User
}

const BirthdayLink = (props: Props) => {
    const { mediaStore } = useStore();
    const [uri, setUri] = useState('/assets/default-profile-picture.svg');
    const [date, setDate] = useState('');

    useEffect(() => {
        mediaStore.getProfilePicture(props.user.id).then(media => {
            if (media) {
                setUri(media.url);
            }
        });

        let date = moment(props.user.dateOfBirth || "").format("MMMM DD");
        setDate(date)
    }, []);

    return (
        <Comment>
            <Comment.Avatar as='a' src={uri} />
            <Comment.Content>
                <Comment.Author>{props.user.name}</Comment.Author>
                <Comment.Actions>
                    <Comment.Action className='not-clickable'>{date}</Comment.Action>
                </Comment.Actions>
            </Comment.Content>
        </Comment>
        );
}

export default BirthdayLink