import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";
import { PostAttachment as PostAttachmentModel } from "../../app/models/post";
import { useStore } from "../../app/stores/store";
import FileAttachment from "./FileAttachment";
import ImageAttachment from "./ImageAttachment";
import VideoAttachment from "./VideoAttachment";
import ImageAttachmentModal from "./ImageAttachmentModal";
import { Media } from "../../app/models/common/media";

interface Props {
    postId: string;
    isEditing?: boolean
    onRemoveAttachment?: (id: string) => void
}

const PostAttachment = forwardRef(({ postId, isEditing, onRemoveAttachment }: Props, ref) => {
    const { postStore: { getAttachements }, mediaStore } = useStore();
    const [postAttachment, setPostAttachement] = useState<PostAttachmentModel[]>([])
    const [originalPostAttachment, setOriginalPostAttachment] = useState<PostAttachmentModel[]>([])
    const [isViewerOpen, setIsViewerOpen] = useState({
        open: false,
        index: 0
    });
    const [attachmentMedia, setAttachmentMedia] = useState<Media[]>([]);

    useEffect(() => {
        retrieveAttachments();
    }, []);

    useImperativeHandle(ref, () => ({
        handleCancelEdit,
        retrieveAttachments,
        getOriginalPostAttachmentCount
    }));

    const getOriginalPostAttachmentCount = () => originalPostAttachment.length;

    const retrieveAttachments = () => {
        getAttachements(postId).then(async attachments => {
            setPostAttachement(attachments);
            setOriginalPostAttachment(attachments);

            let images = attachments.filter(a => a.type.includes('image'));

            let newMedia = Array<Media>();

            for (let image of images) {
                let media = await mediaStore.getPostAttachment(image.id);
                if (media) newMedia.push(media);
            }

            setAttachmentMedia(newMedia);
        });
    }

    const handleRemoveAttachment = (id) => {
        if (originalPostAttachment.length === 0) setOriginalPostAttachment(postAttachment);

        if (onRemoveAttachment) onRemoveAttachment(id);
        setPostAttachement(prev => prev.filter(p => p.id !== id));
    }

    const handleCancelEdit = () => setPostAttachement(originalPostAttachment);

    const onClickImage = (index: number) => {
        setIsViewerOpen({
            open: true,
            index: index
        });
    }

    const getImageAttachments = () => {
        let images = postAttachment.filter(a => a.type.includes('image'));

        if (!isEditing) {
            if (images.length === 1) {
                return <ImageAttachment onClick={() => onClickImage(0)} key={images[0].id} id={images[0].id} />
            }
            else if (images.length === 2) {
                return (
                    <>
                        <div className="left">
                            <ImageAttachment onClick={() => onClickImage(0)} key={images[0].id} id={images[0].id} />
                        </div>
                        <div className="right">
                            <ImageAttachment onClick={() => onClickImage(1)} key={images[1].id} id={images[1].id} />
                        </div>
                    </>
                )
            }
            else if (images.length === 3) {
                return (
                    <>
                        <div className="left">
                            <ImageAttachment onClick={() => onClickImage(0)} key={images[0].id} id={images[0].id} />
                        </div>
                        <div className="right">
                            <ImageAttachment onClick={() => onClickImage(1)} key={images[1].id} id={images[1].id} />
                            <ImageAttachment onClick={() => onClickImage(2)} key={images[2].id} id={images[2].id} />
                        </div>
                    </>
                )
            }
            else if (images.length >= 4) {
                let num = images.length - 4;
                let overlay = '';
                if (!(num <= 0)) {
                    overlay = `${num}`;
                }

                return (
                    <>
                        <div className="left">
                            <ImageAttachment onClick={() => onClickImage(0)} key={images[0].id} id={images[0].id} />
                            <ImageAttachment onClick={() => onClickImage(1)} key={images[1].id} id={images[1].id} />
                        </div>
                        <div className="right">
                            <ImageAttachment onClick={() => onClickImage(2)} key={images[2].id} id={images[2].id} />
                            <ImageAttachment overlay={overlay} onClick={() => onClickImage(3)} key={images[3].id} id={images[3].id} />
                        </div>
                    </>
                )


            }
        }
        else {
            return images.map(image => {
                return <ImageAttachment key={image.id} id={image.id} onClose={handleRemoveAttachment} />
            })
        }
    };

    const getVideoAttachments = () => {
        let video = postAttachment.filter(a => a.type.includes('video'));
        if (!isEditing) {
            if (video.length === 1) {
                return <VideoAttachment key={video[0].id} id={video[0].id} />
            }
            else if (video.length === 2) {
                return (
                    <>
                        <div className="left">
                            <VideoAttachment key={video[0].id} id={video[0].id} />
                        </div>
                        <div className="right">
                            <VideoAttachment key={video[1].id} id={video[1].id} />
                        </div>
                    </>
                )
            }
            else if (video.length === 3) {
                return (
                    <>
                        <div className="left">
                            <VideoAttachment key={video[0].id} id={video[0].id} />
                        </div>
                        <div className="right">
                            <VideoAttachment key={video[1].id} id={video[1].id} />
                            <VideoAttachment key={video[2].id} id={video[2].id} />
                        </div>
                    </>
                )
            }
            else if (video.length >= 4) {
                return (
                    <>
                        <div className="left">
                            <VideoAttachment key={video[0].id} id={video[0].id} />
                            <VideoAttachment key={video[1].id} id={video[1].id} />
                        </div>
                        <div className="right">
                            <VideoAttachment key={video[2].id} id={video[2].id} />
                            <VideoAttachment key={video[3].id} id={video[3].id} />
                        </div>
                    </>
                )
            }
        }
        else {
            return video.map(v => {
                return <VideoAttachment key={v.id} id={v.id} onClose={handleRemoveAttachment} />
            })
        }
    }

    const otherAttachment = () => {
        let files = postAttachment.filter(a => !a.type.includes('video') && !a.type.includes('image'));
        let fileJsx = new Array<JSX.Element>();

        files.forEach(file => {
            fileJsx.push(
                <FileAttachment
                    key={file.id}
                    id={file.id}
                    fileName={file.fileName}
                    onClose={isEditing ? handleRemoveAttachment : undefined}
                />
            )
        })

        return (fileJsx)
    }

    const handleOnCloseImageAttachment = () => setIsViewerOpen({ ...isViewerOpen, open: false });

    return (
        <>
            <div className={`post-attachments ${isEditing && 'editing'}`}>
                {!isEditing ?
                    <div className='image-video-attachments'>
                        {getImageAttachments()}
                        {getVideoAttachments()}
                    </div>
                    :
                    <>
                        {getImageAttachments()}
                        {getVideoAttachments()}
                    </>
                }
                {otherAttachment()}
            </div>
            {isViewerOpen &&
                <ImageAttachmentModal
                    onClose={handleOnCloseImageAttachment}
                    images={attachmentMedia.map(m => {
                        return {
                            source: m.url
                        }
                    })}
                    open={isViewerOpen.open}
                    index={isViewerOpen.index}
                />
            }
        </>
    )
});

export default PostAttachment;