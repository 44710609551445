import parse from 'html-react-parser';

interface Props {
    message: string
}

const PostMessage = (props: Props) => {
    let message = props.message;
    let parsed = parse(message);

    return (
        (typeof parsed === "string") ?
            <div className='post-message' dangerouslySetInnerHTML={{
                __html: parsed as string
            }}>
            </div> :
            <div className='post-message'>
                {parsed}
            </div>
    )

}

export default PostMessage;