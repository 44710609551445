import { Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { useStore } from '../../../app/stores/store';
import DateDetails from '../card-dates/DateDetails';

//#region Styling
const Menu = styled(Button)`
    background:#091e4214 !important;
    margin-bottom: 8px !important;

    & i{
        background:none !important;
    }
`;
//#endregion

interface Props {
    cardId: string;
}

const DateMenu = ({ cardId }: Props) => {
    const { dateModalStore } = useStore();

    const handleClick = () => {
        dateModalStore.openModal(<DateDetails cardId={cardId} />)
    }
   
    return (
        <Menu
            icon='clock outline'
            labelPosition='left'
            content='Dates'
            onClick={handleClick}
            fluid
        />
    );
}

export default DateMenu;