import { Form, Formik } from 'formik';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Grid, Header, Image } from 'semantic-ui-react';
import * as Yup from 'yup';
import PasswordInput from '../../app/common/form/PasswordInput';
import { useStore } from '../../app/stores/store';


function ResetPasswordForm() {
    const { userStore } = useStore();
    const { id } = useParams<{ id: string }>();

    const validationSchema = Yup.object({
        password: Yup.string()
                        .required('New Password field is required')
                        .min(8, 'New Password is too short - should be 8 chars minimum.')
            .matches(/^(?=.*\d)(?=.*[a-zA-Z]).+$/, 'New Password should be  a combination of alphanumeric. A special character is optional')
        ,
        confirmPassword: Yup.string()
                            .required('Confirm Password field is required')
                            .test('passwords-match', 'Passwords must match', function (value) {
                            return this.parent.password === value
                            })
    });

    return (
        <Formik
            initialValues={{
                password: '',
                confirmPassword: '',
                userCode: id
            }}
            onSubmit={(values, { setErrors }) => userStore.resetPassword(values)}
            validationSchema={validationSchema}
        >
            {
                ({ handleSubmit, isSubmitting, errors }) => (

                    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
                        <Grid.Column style={{
                            width: '436px',
                            position: 'relative',
                            top: '-20px',
                            padding: '54px 32px',
                            boxShadow: '0px 3px 6px rgb(21 146 255 / 16%)',
                            borderRadius: '16px',
                            backgroundColor: '#ffffff'
                        }}>
                            <div style={{ textAlign: 'center', marginBottom: '32px' }}>
                                <Image src={`../../assets/kasina-logo.svg`} style={{ width: '135px', marginBottom: '12px' }} centered />
                                <Header as='h6' style={{ letterSpacing: '5.6px', fontSize: '8px', marginTop: 0, marginLeft: '10px' }}>CLIENT PORTAL</Header>
                            </div>
                            <Header as='h3' style={{ marginBottom: '32px', textAlign: 'left' }}>Reset Password</Header>
                            <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                <PasswordInput name='password' placeholder='New Password' label='New Password' />
                                <PasswordInput name='confirmPassword' placeholder='Confirm Password' label='Confirm Password' />
                                <Grid columns='equal' style={{ marginTop: '30px', alignItems: 'center' }}>
                                    <Grid.Column style={{ textAlign: 'left' }}>
                                        <i className='angle left icon'></i>
                                        <Link to={`/login`}>
                                            Back to login
                                        </Link >
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button loading={isSubmitting} color='blue' style={{ backgroundColor: '#1592ff', borderRadius: '8px', height: '42px'  }} fluid content='Reset Password' type='submit' size='medium' />
                                    </Grid.Column>
                                </Grid>
                            </Form>
                        </Grid.Column>
                    </Grid>
                )
            }
        </Formik>
    );
}

export default ResetPasswordForm;