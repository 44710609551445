import React, { useEffect, useState } from 'react';
import { Image, ImageProps } from 'semantic-ui-react';
import { useLightbox } from 'simple-react-lightbox';
import styled from 'styled-components';
import { useStore } from '../../stores/store';

const ComponentWrapper = styled.div`
    width: 120px !important;
    height: 80px !important;
`;

const Thumbnail = styled.div<{dataUrl: string}>`
    width: 100% !important;
    height: 100% !important;
    background: ${p => (p.dataUrl ? 'url(' + p.dataUrl + ')' : '/assets/default-profile-picture.svg')};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`

interface Props extends ImageProps {
    attachmentId: string;
}

const fileToDataUri = (file: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
        resolve(event.target!.result)
    };
    reader.readAsDataURL(file);
})

const AttachmentThumbnail = ({ attachmentId, ...rest }: Props) => {
    const { cardAttachmentStore } = useStore();
    const [dataUri, setDataUri] = useState('');

    useEffect(() => {
        let unmounted = false;
        try {
            cardAttachmentStore.getBlob(attachmentId).then(v => {
                if (v != undefined && !unmounted)
                    fileToDataUri(v).then(dataUri => {
                        setDataUri(dataUri as string)
                    })
            });
        } catch (e) {
            if (!unmounted)
                setDataUri('/assets/default-profile-picture.svg');
        }

        return () => { unmounted = true };
    }, [attachmentId, cardAttachmentStore])

    return (
        <ComponentWrapper>
            <img src={dataUri || '/assets/default-profile-picture.svg'} style={{ display:'none' }} />
            <Thumbnail
                dataUrl={dataUri}
            />
        </ComponentWrapper>
    );
}

export default AttachmentThumbnail;