import { useRef, useState } from "react";
import { GrAttachment } from 'react-icons/gr';
import { toast } from "react-toastify";
import { Button } from "semantic-ui-react";
import AvatarImage from "../../app/common/image/AvatarImage";
import { PostForm as PostFormModel } from "../../app/models/post";
import { useStore } from "../../app/stores/store";
import { CardWrapper } from "../../app/styles/theme";
import FileAttachment from "./FileAttachment";
import ImageAttachment from "./ImageAttachment";
import VideoAttachment from "./VideoAttachment";
import TextEditor from "../../app/common/text-editor/TextEditor";
import { v4 as uuid } from 'uuid';

interface Props {
    message?: string
    isEditing?: boolean
    onClickCancel?: any
    onSubmit?: (post: PostFormModel) => void
    isLoading?: boolean
}

const PostForm = (props: Props) => {
    const inputFileRef = useRef<any>(null);
    const { userStore, postStore } = useStore();
    const [attachments, setAttachments] = useState<{
        fileName: string
        jsx: JSX.Element
    }[]>([]);
    const handleFileClick = () => inputFileRef.current.click();
    const [validatedFiles, setValidatedFiles] = useState<File[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(props.message ? props.message : "")

    const handleInputChange = (e) => {
        setAttachments([]);
        let files = Object.values<File>(inputFileRef.current.files);

        if (files.length > 0) {
            setValidatedFiles(files.filter(f => getSizeInMb(f.size) <= 10));

            let images = files.filter(a => a.type.includes('image')),
                videos = files.filter(a => a.type.includes('video')),
                others = files.filter(a => !a.type.includes('image') && !a.type.includes('video'));

            const handleClose = (id, fileName) => {
                files = files.filter(a => a.name !== fileName)
                setAttachments(prev => prev.filter(a => a.fileName !== fileName));
                setValidatedFiles(files);
            }


            images.forEach(blob => {
                if (getSizeInMb(blob.size) <= 10) {

                    setAttachments(prev => {
                        return [...prev,
                        {
                            fileName: blob.name,
                            jsx: <ImageAttachment
                                key={uuid()}
                                id={uuid()}
                                blob={blob}
                                onClose={handleClose}
                                fileName={blob.name}
                            />
                        }]
                    });
                }
                else {
                    toast.error(`The attachment, "${blob.name}", has exceeded the 10MB limit.`)
                }
            });

            videos.forEach(blob => {
                console.log(getSizeInMb(blob.size));
                if (getSizeInMb(blob.size) <= 10) {
                    setAttachments(prev => {
                        return [...prev,
                        {
                            fileName: blob.name,
                            jsx: <VideoAttachment
                                key={uuid()}
                                id={uuid()}
                                blob={blob}
                                onClose={handleClose}
                                fileName={blob.name}
                            />
                        }]
                    });
                }
                else {
                    toast.error(`The attachment, "${blob.name}", has exceeded the 10MB limit.`)
                }

            });

            others.forEach(blob => {
                if (getSizeInMb(blob.size) <= 10) {
                    setAttachments(prev => {
                        return [...prev,
                        {
                            fileName: blob.name,
                            jsx: <FileAttachment
                                key={uuid()}
                                id={uuid()}
                                fileName={blob.name}
                                onClose={handleClose} />
                        }]
                    });
                }
                else {
                    toast.error(`The attachment, "${blob.name}", has exceeded the 10MB limit.`)
                }

            });

        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (!props.isEditing) {
            if ((message.trim() && message.trim() != "<p></p>")
                || validatedFiles.length > 0) {
                setIsLoading(true);
                postStore.add({
                    message: message.trim(),
                    clientId: userStore.user?.clientId!,
                    files: validatedFiles
                }).then(() => {
                    setMessage('');
                    setAttachments([]);
                    setValidatedFiles([]);
                    setIsLoading(false);
                }).catch(e => {
                    const { response } = e;
                    setIsLoading(false);
                    if (response.data.errors && response.data.status === 400) {
                        toast.error('Post exceeded the maximum limit.');
                    }

                });
            } else {
                toast.error('You have nothing to post.');
            }
        }
        else {
            if (props.onSubmit)
                props.onSubmit({
                    message: message,
                    clientId: userStore.user?.clientId!,
                    files: validatedFiles
                });
        }
    };

    const getSizeInMb = (sizeInBytes: number) => (sizeInBytes / (1024 * 1024));

    return (
        <CardWrapper className={`post-form ${props.isEditing && 'editing'}`}>
            <div className='flex'>
                <div className='image-container'>
                    <AvatarImage className='image' userId={userStore.user?.id!} hasPhoto={true} />
                </div>
                <form onSubmit={handleSubmit} className='textarea-container'>
                    <TextEditor
                        className='text-editor'
                        placeholder='What is on your mind?'
                        onChange={setMessage}
                        value={message}
                    />
                    {/*<textarea*/}
                    {/*    ref={inputMessageRef}*/}
                    {/*    defaultValue={props.message ? props.message : ''}*/}
                    {/*    rows={5}*/}
                    {/*    placeholder='What is on your mind?'*/}
                    {/*/>*/}
                    <GrAttachment onClick={handleFileClick} className='file-button' />
                    <input onChange={handleInputChange} multiple ref={inputFileRef} hidden type='file' />
                    <Button disabled={isLoading || props.isLoading} loading={isLoading || props.isLoading} className='post-button'>{!props.isEditing ? "Post" : "Update"}</Button>
                    {props.isEditing && <Button onClick={props.onClickCancel} className='post-button cancel'>Cancel</Button>}
                </form>
            </div>
            <div className='input-attachments'>
                {attachments.map(a => a.jsx)}
            </div>
        </CardWrapper>
    )
}

export default PostForm;