import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agents";
import { CardAttachmentFormValues } from "../models/cardAttachment";
import { store } from "./store";

export default class CardAttachmentStore {
    uploading: boolean = false;
    showAllAttachments: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    upload = async (file: Blob, cardId: string) => {
        this.uploading = true;
        try {
            const response = await agent.CardAttachments.upload(file, cardId);
            if (response) {
                store.boardStore.hubConnection!.invoke('AddCardAttachments', cardId);
                runInAction(() => this.uploading = false);
                return response
            } else {
                return Promise.reject();
            }
        } catch (error) {
            console.log(error);
            runInAction(() => this.uploading = false);
            return Promise.reject(error);
        }
    }

    uploadMultiple = async (files: File[], cardId: string, clientId: string) => {
        this.uploading = true;
        try {
            const response = await agent.CardAttachments.uploadMultiple(files, cardId, clientId);
            if (response) {
                store.boardStore.hubConnection!.invoke('AddCardAttachments', cardId);
                runInAction(() => this.uploading = false);
                return response
            } else {
                return Promise.reject();
            }
        } catch (error) {
            console.log(error);
            runInAction(() => this.uploading = false);
            return Promise.reject(error);
        }
    }

    getBlob = async (attachmentId: string) => {
        try {
            return await agent.CardAttachments.getAttachment(attachmentId, {
                responseType: 'blob'
            });
        } catch (e) {
            console.log(e);
        }
    }

    add = async (cardAttachment: CardAttachmentFormValues) => {
        return store.boardStore.hubConnection?.invoke('SendAttachment', cardAttachment);
    }

    update = async (cardAttachment: CardAttachmentFormValues) => {
        try {
            store.boardStore.hubConnection?.invoke('UpdateAttachment', cardAttachment);
        } catch (e) {
            console.log(e);
        }
    }

    updateFileName = async (fileName: string, id: string) => {
        try {
            store.boardStore.hubConnection?.invoke('UpdateAttachmentFileName', { fileName: fileName, id: id });
            return Promise.resolve();
        } catch (e) {
            console.log(e);
            return Promise.reject();
        }
    }

    deleteAttachment = async (attachmentId: string) => {
        try {
            store.boardStore.hubConnection?.invoke('DeleteAttachment', attachmentId);
            runInAction(() => toast.success('Attachment was deleted successfully.'));
        } catch (e) {
            console.log(e);
        }
    }

    setShowAllAttachments = (status: boolean) => {
        this.showAllAttachments = status;
    };
    
}
