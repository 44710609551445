import moment from 'moment-timezone';

export default class DateHelper {
    formatLocalDate = (date: string) => {
        let output = '';

        if (date) {
            let utcDate = moment.utc(date);
            let localDate = utcDate.local();

            output = localDate.format('dddd, MMMM DD, YYYY')
        }

        return output;
    };

    customFormatLocalDate = (date: string, format: string) => {
        let output = '';

        if (date) {
            let utcDate = moment.utc(date);
            let localDate = utcDate.local();

            output = localDate.format(format)
        }

        return output;
    };

    formatShortLocalDate = (date: string) => {
        let output = '';

        if (date) {
            let utcDate = moment.utc(date);
            let localDate = utcDate.local();

            output = localDate.format('MM/DD/YYYY')
        }

        return output;
    };

    formatLocalDateTime = (date: string) => {
        let output = '';

        if (date) {
            let utcDate = moment.utc(date);
            let localDate = utcDate.local();

            output = localDate.format('MM/DD/YYYY hh:mm A')
        }

        return output;
    };

    formatLocalShortMonthDateTime = (date: string) => {
        let output = '';

        if (date) {
            let utcDate = moment.utc(date);
            let localDate = utcDate.local();

            output = localDate.format('MMM DD YYYY (ddd) hh:mm A')
        }

        return output;
    };

    formatLocalShortMonthDay = (date: string) => {
        let output = '';

        if (date) {
            let utcDate = moment.utc(date);
            let localDate = utcDate.local();

            output = localDate.format('MMMM DD, YYYY (ddd)')
        }

        return output;
    };

    getLocalTime = (utcTime: string) => {
        if (!utcTime)
            return '';

        let date = new Date(`1/1/2000 ${utcTime} UTC`);

        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    toLocalTimeString = (param) => {
        let date = new Date(param);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    toReadableDate = (date) => {
        let today = new Date();
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let formatted_date = months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear()
        return formatted_date;
    };

    getDateOnly = (date: Date | null | undefined) => {
        return date ? moment(date).format("YYYY-MM-DD") : '';
    };

    dayOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    getDayOfWeek = (date: Date) => {

        let day = date.getDay();

        return this.dayOfWeek[day];
    };

    getLocalDayOfWeek = (date: string) => {
        let localDate = new Date(this.formatShortLocalDate(date));

        return this.getDayOfWeek(localDate);
    };

    getDaysDifference = (start: Date | string, end: Date | string) => {
        return moment(end).diff(moment(start), 'days');
    };

    getLocaleTimeString = (utcTime: string) => {
        if (!utcTime)
            return '';

        let date = new Date(`1/1/2000 ${utcTime} UTC`);
        let localTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

        if (localTime === "24:00")
            localTime = "00:00";

        return localTime;
    };

    getTimeString = utcTime => {
        if (!utcTime)
            return '';

        let date = new Date(`1/1/2000 ${utcTime}`);
        let localTime = date.toTimeString().slice(0, 5);

        if (localTime === "24:00")
            localTime = "00:00";

        return localTime;
    }

    getTime = (time: string) => {
        return time ? moment(time, "HH:mm:ss").format("hh:mm A") : '';
    };

    formatDateTime = (date: string) => {
        return date ? moment.utc(date).format('MM/DD/YYYY hh:mm A') : '';
    };

    formatShortMonthDateTime = (date: string) => {
        let output = '';

        if (date) {
            let localDate = moment(date);

            output = localDate.format('MMM DD YYYY (ddd) hh:mm A')
        }

        return output;
    };

    formatShortDate = (date: string) => {
        let output = '';

        if (date)
            output = moment(date).format('MM/DD/YYYY');

        return output;
    };

    formatMonthYear = (date: string) => {
        let output = '';

        if (date)
            output = moment(date).format('MMMM yyyy');

        return output;
    };

    formatUtcToLocal = (date) => {
        return moment(date).local();
    };

    formatToTimeZoneDateTime = (dateTime: Date, timeZone: string) => {
        const date = moment(dateTime).format();

        return (dateTime) ? moment.tz(date, timeZone).toDate() : null;
    };

    formatToTimeZoneDateTimeISOString = (dateTime: Date, timeZone: string) => {
        const format = "M/D/YYYY h:mm A";
        const date = moment(dateTime).format(format);

        return (dateTime) ? moment.tz(date, format, timeZone).toISOString() : '';
    };

    formatToTimeZoneShortDateTime = (dateTime: Date, timeZone: string) => {
        const format = "M/D/YYYY h:mm A";
        const date = moment(dateTime).format(format);

        return (dateTime) ? moment.tz(date, format, timeZone).format("M/D/YYYY") : '';
    };

    serializeUtc = (utc: string) => {
        return moment.utc(utc).format();
    };

    // Changes the date's timezone to UTC 
    // while retaining the original date
    convertToUtcDate = (date: string) => {
        let dateString = this.formatLocalDate(date);

        return this.serializeUtc(dateString);
    };

    customFormatDate = (date: string, format: string) => {
        return (date) ? moment(date).format(format) : '';
    };

    formatToMoment = (date) => {
        return moment(date);
    }
}