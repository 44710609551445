import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Item, Progress } from 'semantic-ui-react';
import styled from 'styled-components';
import { CardChecklistItem } from '../../../app/models/cardChecklist';

//#region Styling

const StyledProgress = styled(Progress)`
    && {
        flex-grow:1;
        margin: 6px 0 !important;
    }
`;

const StyledItem = styled(Item)`
    &&{
        width:100%;
        display: flex;
    }
`;

const ItemContent = styled(Item.Content)`
    &&{
        margin: 0 0 0 0 !important;
        width: 100%;
    }
`;

const PercentContainer = styled.div`
color: #5e6c84;
    font-size: 11px;
    left: 0;
    line-height: 10px;
    text-align: center;
    width: 32px;
    margin: 0 8px 8px -4px !important;
`;

//#endregion

interface Props {
    items: CardChecklistItem[]
}

const ChecklistProgress = ({ items }: Props) => {
    const computePercent = () => {
        let completedPercent = 0;

        if (items) {
            let percent = 0;
            if (items.length) {
                let completedCount = items.filter(i => i.isCompleted).length;
                percent = (completedCount / items.length) * 100;
            }
            completedPercent = Math.floor(percent);
        } 

        return completedPercent;
    }

    return (
        <StyledItem>
            <PercentContainer>
                <p>{computePercent()}%</p>
            </PercentContainer>
            <ItemContent>
                <StyledProgress
                    indicating
                    autoSuccess
                    value={items ? items.filter(i => i.isCompleted).length : 0}
                    total={items ? items.length : 0}
                    size='tiny' />
            </ItemContent>
        </StyledItem>

    );
}

export default observer(ChecklistProgress);