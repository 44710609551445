import React, { ChangeEvent, useRef } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { useStore } from '../../../app/stores/store';

const Menu = styled(Button)`
    background:#091e4214 !important;
    margin-bottom: 8px !important;
    & i{
        background:none !important;
    }
`;

interface Props {
    cardId: string
}

const AttachmentMenu = ({ cardId }: Props) => {
    const { cardAttachmentStore, boardStore, userStore } = useStore();
    const { user, getClientUsedStorage } = userStore;
    const { uploadMultiple, uploading } = cardAttachmentStore;
    const { board } = boardStore;

    const inputFile = useRef<HTMLInputElement>(null);

    const handleFileUpload = () => {
        if (inputFile != null && inputFile.current != null)
            inputFile.current.click();
    }

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {

   
        if (event.currentTarget.value == "") {
            toast.error('No file Selected');
            return;
        }

        if (!board?.workSpace || event.target.files == null) {
            toast.error('No client Id available.');
            return;
        }

        //validation
        for (const file of event.target.files) {
            if (file.size > 100000000) {
                toast.error('File must not exceed 100 MB');
                return;
            }
        }

        let totalFileSize = 0;

        for (const file of event.target.files) {
            totalFileSize += file.size / (1024 * 1024);
        }

        let usedStorage = await getClientUsedStorage(user!.clientId);

        if (Math.round(parseInt(usedStorage!.totalSize)) + Math.round(totalFileSize) > 10000) {
            toast.error('You have reached your 10,000 MB storage capacity for attachment.')
            return;
        } 

        const response = uploadMultiple(
            [...Array.from(event.target.files)],
            cardId,
            board.workSpace.clientId!);

        toast.promise(response,
            {
                pending: `Uploading file`,
                success: `File uploaded!`,
                error: `Uploading error!`
            });

        if (event.currentTarget)
            event.currentTarget.value = "";
    }

    return (
        <>
            <input type='file' id='attachment' multiple ref={inputFile} onChange={handleFileChange} style={{ display: 'none' }} />
            <Menu icon='paperclip' labelPosition='left' content='Attachment' fluid
                onClick={handleFileUpload}
            />
        </>
    );
}

export default AttachmentMenu;