import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import agent from '../api/agents';
import { FilterValues } from '../models/common/filterValues';
import { WorkSpaceType, WorkSpaceTypeFormValues } from '../models/workSpaceType';
import { history } from '../../index';

export default class WorkSpaceTypeStore {
    workSpaceTypes: WorkSpaceType[] = [];
    workSpaceTypesTotal: number = 0;
    isWorkSpaceTypeListLoading: boolean = false;
    isLoading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    filterWorkSpaceTypes = async (filters: FilterValues) => {
        this.isWorkSpaceTypeListLoading = true;

        try {
            const { total, data } = await agent.WorkSpaceTypes.filter({
                id: filters.id,
                pageNumber: filters.pageNumber,
                pageSize: filters.pageSize,
            });
            runInAction(() => {
                this.workSpaceTypes = data;
                this.workSpaceTypesTotal = total;
                this.isWorkSpaceTypeListLoading = false;

            });
        } catch (e) {
            console.log(e);
            this.isWorkSpaceTypeListLoading = false;
        }
    }

    loadWorkSpaceType = async (id: string) => {
        this.isLoading = true;

        try {
            let workSpaceType = await agent.WorkSpaceTypes.get(id);
            this.isLoading = false;
            return workSpaceType;
        } catch (e) {
            throw e;
        }
    }

    getAllWorkSpaceTypes = async () => {
        this.isLoading = true;
        try {
            let workSpaceTypes = await agent.WorkSpaceTypes.getAll();
            this.isLoading = false;
            return workSpaceTypes;
        } catch (e) {
            this.isLoading = false;
            throw e;
        }
    }

    add = async (name: string) => {
        try {
            const workSpaceTypeId = await agent.WorkSpaceTypes.add({ name });
            runInAction(() => {
                if (workSpaceTypeId) {
                    toast.success('Workspace Type was created successfully.');
                    history.goBack();
                    return true;
                }
                return false;
            });
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    update = async (workSpaceType: WorkSpaceTypeFormValues) => {
        try {
            const workSpaceTypeId = await agent.WorkSpaceTypes.update(workSpaceType);
            runInAction(() => {
                if (workSpaceTypeId) {
                    toast.success('Workspace Type was updated successfully.');
                    history.goBack();
                    return true;
                }
                return false;
            });
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    deleteWorkSpaceType = async (id: string, filters: FilterValues) => {
        try {
            await agent.WorkSpaceTypes.delete(id);
            this.filterWorkSpaceTypes(filters);
            runInAction(() => {
                toast.success('Workspace Type was deleted successfully.');
            });
        } catch (e) {
            console.log(e);
        }
    }

}