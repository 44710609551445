import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';
import agent from '../../app/api/agents';
import Calendar from '../../app/common/calendar/Calendar';
import DateHelper from '../../app/helpers/dateHelper';
import { EventModel } from '../../app/models/Events';
import { useStore } from '../../app/stores/store';

// #region Styles

const dateHelper = new DateHelper();

const StyledCardContainer = styled.div`
        padding-top: 1rem;
    padding-bottom: 1rem;
        background-color: #fff;
        border-radius: 0 0 16px 16px;
`;

const Titlelabel = styled.label`
    font-size: 18px;
    font-weight: bold;
    color: #193b68;
    display: inline-block;
    margin-bottom: 0.5rem;
`;

// #endregion


const SHIFT_SCHEDULE_TYPE = "Shift Schedule";
const SHIFT_SCHEDULE_EVENT_CLASS = "shift-schedule-event";

const SCHEDULE_TYPE = "Schedule";
const SCHEDULE_EVENT_CLASS = "schedule-event";

const DAYOFF_TYPE = "Day Off";
const DAYOFF_EVENT_CLASS = "dayoff-event";

const REGULAR_HOLIDAY_EVENT_CLASS = "regular-holiday-event";
const SPECIAL_HOLIDAY_EVENT_CLASS = "special-holiday-event";

const LEAVE_REQUEST_TYPE = "LeaveRequest";
const LEAVE_REQUEST_EVENT_CLASS = "leave-request-event";

const sortOrder = {
    'Day Off': 0,
    'Shift Schedule': 1,
    'Schedule': 2,
    'Regular': 3,
    'Special': 4,
    'LeaveRequest': 5
};


const RosterSchedules = () => {
    const { id } = useParams<{ id: string }>();

    const [eventsIsLoading, setEventsIsLoading] = useState(false);
    const [events, setEvents] = useState<{ title: string, date: Date, extendedProps?: { className: string } }[]>([]);

    const { commonStore } = useStore();
    const { isCollapseSideBar, currentDate, setCurrentDate } = commonStore;

    const [calendarKey, setCalendarKey] = useState(1);

    useEffect(() => {
        setTimeout(() => {
            setCalendarKey(calendarKey + 1);
        }, 500)
    }, [isCollapseSideBar])


    const handleChangeDateEvent = (data) => {
        let endDate = new Date(data.view.currentEnd);
        endDate.setDate(endDate.getDate() - 1);
        let endDateString = endDate.toISOString();

        let start = data.view.currentStart.toISOString();
        getEvents(start, endDateString);

        // Update the currentDate state with the current date
        setCurrentDate(new Date(data.view.currentStart));
    }

    const getEvents = async (start, end) => {
        let options = {
            userId: id,
            startDate: start,
            endDate: end,
        };

        let data = await agent.ScheduleManagement.filter(options);

        renderEvents(data)
    }

    const renderEvents = (data: EventModel[]) => {
        let eventHolder = new Array<any>();

        for (let scheduleDate of data) {
            const momentDate = dateHelper.formatToMoment(scheduleDate.date);
            const startDate = dateHelper.getTimeString(scheduleDate.start ?? "00:00:00");
            const endDate = dateHelper.getTimeString(scheduleDate.end ?? "00:00:00");
            const combinedStartDateTime = momentDate.clone().set({ hour: 0, minute: 0, second: 0 }).add(moment.duration(startDate));
            const combinedEndDateTime = momentDate.clone().set({ hour: 0, minute: 0, second: 0 }).add(moment.duration(endDate));
            const displayDate = dateHelper.formatLocalDateTime(scheduleDate.date);

            let title = "",
                className = "",
                type = "";

            switch (scheduleDate.type) {
                case SHIFT_SCHEDULE_TYPE:
                    title = `${moment(combinedStartDateTime).format('hh:mm a')} - ${moment(combinedEndDateTime).format('hh:mm a')}`;
                    className = SHIFT_SCHEDULE_EVENT_CLASS;
                    type = SHIFT_SCHEDULE_TYPE;
                    break;
                case SCHEDULE_TYPE:
                    title = `${moment(combinedStartDateTime).format('hh:mm a')} - ${moment(combinedEndDateTime).format('hh:mm a')}`;
                    className = SCHEDULE_EVENT_CLASS;
                    type = SCHEDULE_TYPE;

                    break;
                case DAYOFF_TYPE:
                    title = DAYOFF_TYPE;
                    className = DAYOFF_EVENT_CLASS;
                    type = DAYOFF_TYPE;
                    break;
                case "Regular":
                    title = `${scheduleDate.name} (${scheduleDate.type})`;
                    className = REGULAR_HOLIDAY_EVENT_CLASS;
                    type = "Regular";

                    break;
                case "Special":
                    title = `${scheduleDate.name} (${scheduleDate.type})`;
                    className = SPECIAL_HOLIDAY_EVENT_CLASS;
                    type = "Special";

                    break;
                case LEAVE_REQUEST_TYPE:
                    title = `On Leave: ${scheduleDate.name}`;
                    className = LEAVE_REQUEST_EVENT_CLASS;
                    type = LEAVE_REQUEST_TYPE;
                    break;
                default:
            }

            eventHolder.push({
                title: title,
                date: new Date(displayDate),
                extendedProps: {
                    type: type,
                    className: className
                }
            })
        }

        setEvents(eventHolder);
    }

    return (
        <StyledCardContainer>
            <Header >Roster Schedule</Header>
            <Calendar
                key={calendarKey}
                initialDate={currentDate}
                events={events}
                loading={eventsIsLoading}
                onRender={handleChangeDateEvent}
                eventOrder={(a: any, b: any) => {
                    if (a.date === b.date) {
                        return sortOrder[a.extendedProps.type] - sortOrder[b.extendedProps.type];
                    }


                    return new Date(a.start).getTime() - new Date(b.start).getTime();
                }}
            />
        </StyledCardContainer>
    );
}

export default observer(RosterSchedules);