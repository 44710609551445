import { InputBase, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Board } from '../../../app/models/board';
import { useStore } from '../../../app/stores/store';
import CardListTitlePopup from '../card-lists/CardListTitlePopup'

//#region Styling

const useStyle = makeStyles((theme) => ({
    input: {
        fontSize: '16px',
        fontWeight: 600,
        flexGrow: 0,
        color: '#172b4d',
        minHeight: '20px',
        maxHeight: '256px',
        overflow: 'hidden auto',
        margin: '4px 8px !important',
        '&:focus': {
            backgroundColor: '#ddd'
        }
    },
    moreIcon: {
        fontSize: '20px',
        borderRadius: '4px',
        width: '25px',
        height: '25px',
        alignSelf: 'flex-start',
        padding: '4px',
        '&:hover': {
            backgroundColor: '#cbc3c3',
            cursor: 'pointer'
        }
    }
}));

const TitleContainer = styled.div`
     && {
        padding: 10px 8px 0 8px;
        display: flex;
        align-items: center;
     }
`;

const EditableTitle = styled(Typography)`
        && {
        font-size: 16px;
        font-weight: 600;
        color: #172b4d;
        padding: 4px 8px;
        word-wrap: break-word;
        overflow: hidden auto;
        min-height:20px;
        max-height:256px;
        line-height: normal;
    }
`;

const TitleWrapper = styled.div`
        && {
           height: auto;
            flex-grow: 1;
            overflow: auto;
            width:240px;
        }
`;

//#endregion

interface Props {
    title: string;
    listId: string;
    boardId: string;
}

const CardListTitle = ({ title: titleProps, listId, boardId }: Props) => {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState(titleProps); 
    const classes = useStyle();
    const { cardGroupStore, boardStore } = useStore();

    const { update } = cardGroupStore;

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    }

    const handleBlur = () => {
        if (title.trim().length > 0 && title.trim().length <= 512) {
            const clonedState = boardStore.board;
            const currentList = clonedState?.cardGroups.find(c => c.id === listId);
            const otherLists = clonedState?.cardGroups.filter(c => c.id !== listId);

            const newBoard = {
                ...clonedState,
                cardGroups: [...otherLists!, { ...currentList, title: title }]
            }

            boardStore.board = newBoard as Board;

            update({ id: listId, title: title, boardId: boardId });
            setOpen(false);
        } else {
            const currentList = boardStore.board?.cardGroups.find(c => c.id === listId);
            if (currentList)
                setTitle(currentList!.title);
            toast.error('List title should not be empty or exceed 512 characters.');
            setOpen(false);
        }
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleBlur();
        }
    }

    const handleOnFocus = (e) => {
        let val = e.target.value
        e.target.value = '';
        e.target.value = val;
    }
    useEffect(() => {
        setTitle(titleProps);
    }, [titleProps]);

    return (
        <div>
            <TitleContainer>
                {
                    open ? (
                        <InputBase value={title}
                            multiline
                            autoFocus
                            inputProps={{ className: classes.input }}
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleOnChange}
                            onKeyDown={handleKeyDown}
                            onFocus={handleOnFocus}
                        />
                    ) : (
                        <TitleWrapper>
                            <EditableTitle onClick={() => setOpen(!open)}>
                                {title}
                            </EditableTitle>
                        </TitleWrapper>
                    )}
                <CardListTitlePopup listId={listId} setOpen={setOpen} />
            </TitleContainer>
        </div>
    );
}

export default CardListTitle;