export interface CardChecklist {
    id: string;
    cardId: string;
    title: string;
    items?: CardChecklistItem[];
}

export class CardChecklistFormValues {
    id?: string = undefined;
    cardId: string = '';
    title: string = '';

    constructor(cardChecklist?: CardChecklist) {
        if (cardChecklist) {
            this.id = cardChecklist.id;
            this.title = cardChecklist.title;
            this.cardId = cardChecklist.cardId;
        }
    }
}

export interface CardChecklistItem {
    id: string;
    isCompleted: boolean;
    cardChecklistId: string;
    title: string;
}

export class CardChecklistItemFormValues {
    id?: string = undefined;
    cardChecklistId: string = '';
    title: string = '';
    isCompleted: boolean = false;
    cardId?: string = '';

    constructor(cardChecklistItem?: CardChecklistItem) {
        if (cardChecklistItem) {
            this.id = cardChecklistItem.id;
            this.title = cardChecklistItem.title;
            this.cardChecklistId = cardChecklistItem.cardChecklistId;
            this.isCompleted = cardChecklistItem.isCompleted;
        }
    }
}