import { useState } from "react";
import { toast } from "react-toastify";
import { Dropdown } from "semantic-ui-react";
import Swal from "sweetalert2";
import AvatarImage from "../../app/common/image/AvatarImage";
import DateHelper from "../../app/helpers/dateHelper";
import { Post as PostModel } from "../../app/models/post";
import { useStore } from "../../app/stores/store";
const dateHelper = new DateHelper();

interface Props {
    post: PostModel,
    onClickEdit?: any;
}

const PostHeader = (props: Props) => {
    const { postStore } = useStore();
    const [open, setOpen] = useState(false);

    const handleDelete = () => {
        Swal.fire({
            title: 'Delete Confirmation',
            text: 'Are you sure you want to delete this post?',
            icon: 'question',
            confirmButtonColor: '#1592ff',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            showCancelButton: true,
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                postStore.delete(props.post.id).then(() => {
                    toast.success('Post deleted successfully.')
                });
            }
        });

    }

    return (
        <div className='header'>
            <AvatarImage userId={props.post.user.id} hasPhoto={props.post.user.hasProfilePicture!} />
            <div>
                <label className='name'>{props.post.user.firstName} {props.post.user.lastName}</label>
                <label className='date'>{dateHelper.formatDateTime(props.post.date)}</label>
            </div>
            <div className='menu'>
                {props.post.owned &&
                    <Dropdown
                        onBlur={() => setOpen(false)}
                        onClick={() => setOpen(!open)}
                        open={open}
                        basic
                        pointing='top right'
                        floating
                        inline
                        icon='ellipsis horizontal'
                    >
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={props.onClickEdit} icon='edit' text='Edit Post' />
                            <Dropdown.Item onClick={handleDelete} icon='trash alternate' text='Delete Post' />
                        </Dropdown.Menu>
                    </Dropdown>
                }
            </div>
        </div>
    )
}

export default PostHeader;