import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Formik, useField } from 'formik';
import styled from 'styled-components';
import { Button, Divider, Header, Form as FormSemantic, Label } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import * as Yup from 'yup';
import TextAreaInput from '../card-menus/TextAreaInput';

//#region Styling
const Menu = styled(Button)`
    background:#091e4214 !important;
    margin-bottom: 8px !important;

    & i{
        background:none !important;
    }
`;

const BodyContainer = styled.div`
    padding: 0 12px 12px;
`;

const HeaderContainer = styled.div`
    height: 40px;
    margin-bottom: 8px;
    position: relative;
    text-align: center;
    padding:8px;
    & h4{
        margin:0 !important;
    }
`;

const ContentWrapper = styled.div`
        && {
        border-radius: 3px;
        box-shadow: 0 8px 16px -4px #091e4240, 0 0 0 1px #091e4214;
        width: 304px;
    }
`;

const StyledTextArea = styled(TextareaAutosize) <{ isResizable?: boolean }>`
        resize: ${p => p.isResizable ? `vertical` : `none`} !important;
        width:100% !important;
        overflow: hidden !important;
        overflow-wrap: break-word !important;
        background: #0000 !important;
        border-radius: 3px !important;
        line-height: 24px !important;
        min-height: 24px !important;
        padding: 4px 8px !important;
        background-color: #fff !important;
        box-shadow: inset 0 0 0 2px #0079bf !important;
        border: none !important;
        outline:none !important;
`;

//#endregion



interface Props {
    placeholder: string;
    label?: string;
    type?: string;
    rows: number;
    isResizable?: boolean; 
    handleSubmit: (values: { fileName: string }, FormikHelpers) => void;
    initialValue: string;
    name: string;
}

const AttachmentEditForm = (props: Props) => {
    const handleFocus = (e) => {
        let val = e.target.value
        e.target.value = '';
        e.target.value = val;
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{ fileName: props.initialValue }}
            onSubmit={props.handleSubmit}
            validationSchema={Yup.object({
                fileName: Yup.string().required('Filename is required.').max(512, 'Filename field must be at most 512 characters')
            })}>
            {
                ({ handleSubmit, isSubmitting, dirty, isValid, values }) => (
                    <ContentWrapper>
                        <HeaderContainer>
                            <Header>
                                Edit File Name
                            </Header>
                            <Divider />
                        </HeaderContainer>
                        <BodyContainer>
                            <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                <TextAreaInput
                                    placeholder={props.placeholder}
                                    rows={1}
                                    label={props.label}
                                    name={props.name}
                                    onFocus={handleFocus}
                                />
                                <Button
                                    type='submit'
                                    loading={isSubmitting}
                                    disabled={!isValid || isSubmitting || !dirty}
                                    size='small'
                                    compact
                                    color='blue'>Save</Button>
                            </Form>
                        </BodyContainer>
                    </ContentWrapper>
                )
            }
        </Formik>
    );
}

export default observer(AttachmentEditForm);