import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { useStore } from '../../stores/store';
import NotificationItem from './NotificationItem';
import NotificationPageItem from './NotificationPageItem';
import NotificationPlaceholder from './NotificationPlaceholder';


const NotificationContainer = styled.div`
    && {
        margin: 40px auto 10px auto;
        min-height: 402px;
        max-width: 800px;
        background-color: #fff;
        overflow: auto;
        display: grid;
        grid-template-rows: auto 1fr auto;
        box-shadow: 0px 1px 5px #b2b2b2;

    }
`;
const NotificationHeader = styled.div`
    && {
        background-color: #1692ff;
        height: 45px;
        display: flex;
        width: auto;
        justify-content: center;
        align-items: center;
    }
`;
const NotificationList = styled.div`
    && {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: auto;

        ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
            border-radius: 10px;
            background-color: #F5F5F5;
        }

        ::-webkit-scrollbar {
            width: 5px;
            background-color: #c6e3fa;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
            background-color: #c6e3fa;
        }
    }
`;

const NotificationFooter = styled.div`
    && {
        height: 49px;
        display: flex;
        width: auto;
        justify-content: center;
        align-items: center;
        margin-top: -1px;
    }
`;

const NotificationPage = () => {
    const history = useHistory();

    const { notificationStore, commonStore } = useStore();

    const { notifications, loadingNotifications, filter, isAllRendered} = notificationStore;
    const { setIsNotificationPage } = commonStore;

    const handleSeeMoreOnClick = () => {
        filter();
    }

    useEffect(() => {
        if (notifications.length === 0) {
            filter();
        }
    }, []);
    useEffect(() => {
        setIsNotificationPage(true);
        return () => {
            setIsNotificationPage(false);
        };
    }, []);


    return (
        <NotificationContainer>
            <NotificationHeader>
                <span style={{
                    color: 'white',
                    fontSize: '15px',
                    fontWeight: 600

                }}>Notifications</span>
            </NotificationHeader>
            <NotificationList>
                <>
                    {notifications.map(notification => {
                        return <NotificationPageItem key={notification.id} notification={notification} />
                    })}
                </>
                {loadingNotifications && <NotificationPlaceholder />}

            </NotificationList>
            <NotificationFooter>
                {(!isAllRendered && !loadingNotifications) && <a style={{
                    color: '#1592ff',
                    padding: '7px',
                    fontSize: '15px'

                }}
                    onClick={handleSeeMoreOnClick}
                    href="javascript:void(0)">See more</a>}
            </NotificationFooter>
        </NotificationContainer>
    );

}
export default observer(NotificationPage);