import { cl } from "@fullcalendar/core/internal-common";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Button, Dropdown, Header, Icon, Label, Modal } from "semantic-ui-react";
import styled from "styled-components";
import agent from "../../app/api/agents";
import { useStore } from "../../app/stores/store";

const StyledButton = styled(Button)`
    background-color: #1592ff !important;
    color: #ffffff !important;
`;

const StyledCancelButton = styled(Button)`
    background-color: #6c757d !important;
    color: #ffffff !important;
`;

const StyledLabel = styled(Label)`
    background-color:transparent !important;
    font-size: 12px;
`;

const TimeZoneModal = () => {
    const { timeZoneStore, userStore } = useStore();
    const { openModal, setModalOpen, timeZones, getAll: getAllTimeZones, isLoading, setIsLoading, incKeyId } = timeZoneStore;
    const { user, setTimeZone } = userStore;

    const [value, setValue] = useState<any>(user?.timeZone);
    const [isTimeZoneChange, setIsTimeZoneChange] = useState<boolean>(false);

    useEffect(() => {
        if (openModal === true && timeZones.length === 0)
            getAllTimeZones();

        setValue(user?.timeZone);
    }, [openModal]);


    const handleCancel = () => {
        setModalOpen(false);
        setIsTimeZoneChange(false);
    }

    const handleSave = async () => {
        setIsLoading(true);

        await agent.TimeZone.update(value);
        setTimeZone(value);
        incKeyId();
        setIsLoading(false);
        setModalOpen(false);
    }

    return <Modal
        open={openModal}
        trigger={<Button>Show Modal</Button>}
        onClose={() => {
            setModalOpen(false);
            setIsTimeZoneChange(false);
        }
        }
        onOpen={() => setModalOpen(true)}
    >
        <Header content='Update Time Zone' />
        <Modal.Content>
            <StyledLabel
            >
                Select Time Zone
            </StyledLabel>
            <Dropdown
                placeholder='Select Time Zone...'
                fluid
                selection
                search
                value={value}
                options={timeZones.map(t => {
                    return {
                        key: t.id,
                        value: t.id,
                        text: t.name
                    }
                })}
                onChange={(e, { name, value }) => {
                    setValue(value)
                    setIsTimeZoneChange(true)
                }
                }
                loading={isLoading}
                disabled={isLoading}
            />
        </Modal.Content>
        <Modal.Actions>
            <StyledCancelButton onClick={handleCancel} content='Cancel' />
            <StyledButton disabled={isLoading || !isTimeZoneChange} onClick={handleSave} content='Save' />
        </Modal.Actions>
    </Modal>;
}

export default observer(TimeZoneModal);