export default class CookieHelper {
    webUrl = process.env.REACT_APP_WEB_URL ? `domain=${process.env.REACT_APP_WEB_URL};` : '';

    get = (name: string) => {
        let cookies: any = document.cookie
            .split(';')
            .map(cookie => cookie.split('='))
            .reduce((accumulator, [key, value]) =>
                ({ ...accumulator, [key.trim()]: decodeURIComponent(value) }), {});

        return cookies[name];
    }

    set = (name: string, value: string) => {
        let cookie = `${name}=${value};`;
        let hostName = window.location.hostname.split('.'),
            domain = hostName[1] + '.' + hostName[2];

        if (hostName[1] && hostName[2])
            cookie += `domain=${domain};`
        else
            cookie += `domain=${window.location.hostname};`

        cookie += 'path=/';

        document.cookie = cookie;
    }

    delete = (name: string) => {
        let cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
        let hostName = window.location.hostname.split('.'),
            domain = hostName[1] + '.' + hostName[2];

        if (hostName[1] && hostName[2])
            cookie += `domain=${domain};`
        else
            cookie += `domain=${window.location.hostname};`

        cookie += 'path=/';

        document.cookie = cookie;
    }
}