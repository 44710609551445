import { IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { MdClear } from 'react-icons/md';
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { useStore } from '../../../app/stores/store';

//#region Styling

const ComponentWrapper = styled.div`
     && {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin: 0 0 0 32px !important;
     }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
`;

const StyledTextArea = styled(TextareaAutosize)`
    && {
        width:100%;
        overflow: hidden;
        overflow-wrap: break-word;
        background: #0000;
        border-radius: 3px;
        line-height: 24px;
        min-height: 24px;
        padding: 4px 8px;
        resize: none;
        background-color: #fff;
        box-shadow: inset 0 0 0 2px #0079bf;
        border: none;
        outline:none;
}
`;

//#endregion

interface Props {
    cardChecklistId: string
    cardId: string;
    activechecklistId?: string;
    onFocus?: (checkListId?: string) => void
}

const ItemTitleInput = ({ activechecklistId, onFocus, cardChecklistId, cardId }: Props) => {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const { cardChecklistStore } = useStore();
    const { addItem } = cardChecklistStore;

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTitle(event.target.value);
    }

    const handleOnSave = async () => {
        if (title && title.length > 0 && title.length <= 512) {
            addItem({
                title: title,
                cardChecklistId: cardChecklistId,
                isCompleted: false,
                cardId: cardId
            }).then(v => {
                setOpen(false);
                setTitle('');
            }, reason => console.log(reason));
        } else {
            toast.error('Item should not be empty or exceed 512 characters');
            setTitle('');
            setOpen(false);
        }
    }

    const handleClear = () => {
        setTitle('');
        handleFocusOut();
    }
    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            handleOnSave();
        }
    }


    const handleFocus = () => {
        if (onFocus) {
            onFocus(cardChecklistId)
        }
    }
    const handleFocusOut = () => {
        if (onFocus) {
            onFocus("")
        }
    }

    useEffect(() => {
        if (activechecklistId) {
            setTitle("");
        }
    }, [activechecklistId]);

    return (
        <ComponentWrapper>
            {activechecklistId === cardChecklistId ? (
                <>
                    <StyledTextArea
                        autoFocus
                        value={title}
                        onChange={handleOnChange}
                        minRows={2}
                        placeholder='Add an item'
                        onKeyDown={handleKeyDown}
                    />
                    <ButtonContainer>
                        <Button
                            compact
                            color='blue'
                            size='small'
                            onMouseDown={handleOnSave}>
                            Add
                        </Button>
                        <IconButton onClick={handleClear}>
                            <MdClear />
                        </IconButton>
                    </ButtonContainer>
                </>
            ) : (
                <Button
                    compact
                        size='small'
                        onClick={handleFocus}
                >Add an Item</Button>
            )}
        </ComponentWrapper>
    );
}

export default ItemTitleInput;