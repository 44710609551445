import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, ButtonProps, Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AvatarImage from '../../../app/common/image/AvatarImage';
import PaginatedTable from '../../../app/common/table/PaginatedTable';
import { FilterValues } from '../../../app/models/common/filterValues';
import { User } from '../../../app/models/user';
import { WorkSpaceUser } from '../../../app/models/workSpaceUser';
import { useStore } from '../../../app/stores/store';
import WorkSpaceMemberForm from './WorkSpaceMemberForm';

const swal = withReactContent(Swal);
const StyledHeader = styled(Header)`margin-bottom: 20px !important;`;

const StyledColumn = styled(Grid.Column)`
        display: flex !important;
        align-items: flex-end !important;
        justify-content: flex-end !important;
`;

const StyledContainer = styled.div`
        padding: ${props => props.theme.padding.featureContainer};
`;

const WorkSpaceMemberList = () => {
    const { workSpaceUserStore, popOverStore } = useStore();
    const { isListLoading, workSpaceUsers, workSpaceUsersTotal, filterWorkSpaceUsers, deleteWorkSpaceUser, reset, users } = workSpaceUserStore;
    const { id } = useParams<{ id: string }>();

    const [filterValues, setFilterValues] = useState<FilterValues>({
        pageSize: 10,
        pageNumber: 1,
        searchKeyword: "",
        id: id
    });

    const handleOnChangePage = (page: number) => {
        setFilterValues({ ...filterValues, pageNumber: page })
       
    }

    const BackButton = styled.div`
       color: rgb(0 0 0 / 87%);
        :hover {
             color: rgb(0 0 0 / 87%);
        }
`;
    const handleOnSearch = (searchText: string) => {
        setFilterValues({ ...filterValues, searchKeyword: searchText.trim() })

    }

    const refreshTable = () => {
        filterWorkSpaceUsers(filterValues);
    }

    const handleAddMembers = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
        popOverStore.openPopover((
            <WorkSpaceMemberForm workSpaceId={id} refreshMainTable={refreshTable} />
        ), event.currentTarget);
    }

    const handleRemove = (rowData: User) => {
        swal.fire({
            title: 'Remove?',
            text: "Are you sure you want to remove this member?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await deleteWorkSpaceUser(rowData.id,id, filterValues);
            }
        });
    }
    const handleSearch = (searchText: string) => {
        if (filterValues.searchKeyword != searchText) {
            setFilterValues({ ...filterValues, pageNumber: 1, searchKeyword: searchText });
        } else {
            setFilterValues({ ...filterValues, searchKeyword: searchText });
        }
    }

    useEffect(() => {
        filterWorkSpaceUsers(filterValues);
        return () => {
            reset();
        }
    }, [filterValues, filterWorkSpaceUsers])

    return (
        <>
            <StyledContainer>
                <StyledHeader>
                    <Grid>
                        <Grid.Column width={8}>
                            <BackButton as={Link} to={{
                                pathname: '/workspaces',
                            }}>
                                <i className="left chevron icon" style={{ marginLeft: '10px', marginRight: '10px', cursor: 'pointer' }} title='Back'></i>
                            </BackButton>
                            Members
                        </Grid.Column>
                        <StyledColumn width={8}>
                            <Button icon='add user' color='blue' onClick={handleAddMembers} content='Invite Workspace Member' />
                        </StyledColumn>
                    </Grid>
                </StyledHeader>
                <PaginatedTable<User>
                    columns={[
                        {
                            title: 'Member', field: 'name',
                            render: (data: User) => {
                                return (
                                    <>
                                        <AvatarImage userId={data.id} hasPhoto={data.hasProfilePicture!} circular style={{ marginRight: '1em' }} />
                                        <span>{data.name}</span>
                                    </>
                                );
                            }
                        },
                    ]}

                    actions={[rowData => ({
                        icon: () => <FaTrashAlt style={{ color: '#97a4b5', fontSize: '16px' }} />,
                        tooltip: 'Delete Member',
                        onClick: e => handleRemove(rowData),
                    })]}
                    total={workSpaceUsersTotal}
                    pageSize={filterValues.pageSize}
                    pageNumber={filterValues.pageNumber}
                    totalCount={workSpaceUsersTotal ?? 0}
                    sorting={false}
                    draggable={false}
                    onChangePage={handleOnChangePage}
                    onSearchChange={handleOnSearch}
                    data={users!}
                    isLoading={isListLoading}
                    toolbar={true}
                    search={true}
                />
            </StyledContainer>
        </>

    );
};

export default observer(WorkSpaceMemberList);