import { makeAutoObservable } from "mobx";
import { CardChecklistFormValues, CardChecklistItemFormValues } from "../models/cardChecklist";
import { store } from "./store";

export default class CardChecklistStore {
    isChecklistTitleChange: boolean = false;
    checkListTitlePreviousValue: string = "";


    isChecklistItemChange: boolean = false;
    checkListItemPreviousValue: string = "";
    constructor() {
        makeAutoObservable(this);
    }
    add = async (cardChecklist: CardChecklistFormValues) => {
        return store.boardStore.hubConnection?.invoke('SendChecklist', cardChecklist);
    }

    update = async (cardChecklist: CardChecklistFormValues) => {
        try {
            store.boardStore.hubConnection?.invoke('UpdateChecklist', cardChecklist);
        } catch (e) {
            console.log(e);
        }
    }

    deleteChecklist = async (checklistId: string) => {
        try {
            store.boardStore.hubConnection?.invoke('DeleteChecklist', checklistId);
        } catch (e) {
            console.log(e);
        }
    }

    addItem = async (cardChecklistItem: CardChecklistItemFormValues) => {
        return store.boardStore.hubConnection?.invoke('SendChecklistItem', cardChecklistItem);
    }

    updateItem = async (cardChecklistItem: CardChecklistItemFormValues) => {
        try {
            store.boardStore.hubConnection?.invoke('UpdateChecklistItem', cardChecklistItem);
        } catch (e) {
            console.log(e);
        }
    }

    deleteItem = async (itemId: string) => {
        try {
            store.boardStore.hubConnection?.invoke('DeleteChecklistItem', itemId);
        } catch (e) {
            console.log(e);
        }
    }

    setIsChecklistTitleChange = (value: boolean) => {
        this.isChecklistTitleChange = value;
    }
    setIsChecklistItemChange = (value: boolean) => {
        this.isChecklistItemChange = value;
    }
    setCheckListTitlePreviousValue = (value: string) => {
        this.checkListTitlePreviousValue = value;
    }
    setChecklistItemPreviousValue = (value: string) => {
        this.checkListItemPreviousValue = value;
    }
}
