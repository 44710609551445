import zIndex from '@material-ui/core/styles/zIndex';
import React, { useState } from 'react';
import { DropdownProps, Select } from 'semantic-ui-react';
import styled from 'styled-components';


const ContainerStyled = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: flex-start;
    justify-content: flex-start;
    ${({ customStyle }: { customStyle?: React.CSSProperties }) => customStyle && `
        align-items: ${customStyle.alignItems || 'flex-start'};
    `}
`;

interface Props {
    label?: string;
    onOptionFilter?: (value: string) => void | undefined;
    valueProps?: string;
    options: any;
    placeholdder: string;
    customStyle?: React.CSSProperties;
}

const OptionFilter = ({ label, onOptionFilter, valueProps, options, placeholdder, customStyle }: Props) => {

    const [value, setValue] = useState<string | undefined>(valueProps);

    const handleValuesChange = (event: React.SyntheticEvent<HTMLElement>, d: DropdownProps) => {
        if (onOptionFilter) {
            setValue(d.value as string);
            onOptionFilter(d.value as string);
        }
    };
    return (
        <ContainerStyled customStyle={customStyle}>
            {label && (<label style={{ textAlign: 'left', color: '#6a7e99', fontSize: '14px', marginBottom: '4px' }}>
                {label}
            </label>)}
            <Select
                options={options}
                clearable
                selectOnBlur={false}
                selection
                placeholder={placeholdder}
                value={value}
                onChange={handleValuesChange}
                style={{ zIndex: 11 }}
            />
        </ContainerStyled>
    );
}

export default OptionFilter;