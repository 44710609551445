import { Formik, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { Button, Form, Grid, Item, Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import FormTextArea from '../form/FormTextArea';
import FormTextInput from '../form/FormTextInput';
import * as Yup from 'yup';
import { useStore } from '../../stores/store';
interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    groupChatName: string;
}
interface Groupchat {
   name: string 
}
const ItemContent = styled(Item.Content)`
    &&{
        margin: 0 0 0 0em !important;
        max-width: fit-content;
        min-width: 100%;   
    }
`;

const data = {
    content: "",
    ids: Array<string>()
}
const files: File[] = [];
const EditGroupChatNameModal = ({ open, setOpen, groupChatName}: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { chatStore, employeeStore, messageStore, conversationStore, userStore } = useStore();
    const { rename } = chatStore;
    const { selectedConversation } = conversationStore;
    const { addMessageWithAttachment } = messageStore;
    const { user } = userStore;

    const handleSubmit = ({ name }: Groupchat) => {
       
        data.content = `${user?.firstName + " " + user?.lastName} has renamed the conversation to ${name}.[[SystemGenerated]]`;
        setIsLoading(true);

        rename(selectedConversation?.id!, name).then(request => {
            addMessageWithAttachment(
                {
                    content: data.content,
                    conversationId: selectedConversation?.id,
                    receiversId: [],
                    connectionId: chatStore.connectionId,
                    files: files
                }
            ).then(() => {
                chatStore.hubConnection?.invoke('OnUpdateConversationName', selectedConversation?.id, name)
               
                setIsLoading(false);
                setOpen(false)
            });

        })
    }

    const formikRef = useRef<FormikProps<Groupchat>>(null);
    return (
        <Modal
            size="tiny"
            open={open}
            onClose={() => setOpen(false)}
            closeOnDimmerClick={false}
        >
            <Modal.Header style={{ textAlign: 'center', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', padding: '1em 2em' }} >Edit group name</Modal.Header>
            <Formik
                enableReinitialize
                initialValues={{
                    name: groupChatName
                } as Groupchat}
                onSubmit={handleSubmit}
                innerRef={formikRef}
            >
                {
                    ({ handleSubmit, isSubmitting, isValid, setErrors, resetForm, dirty, values }) => {
                        return (
                            <>
                                <Modal.Content image scrolling style={{ overflowX: 'hidden' }} >
                                    <Modal.Description style={{ maxHeight: '20em' }} >

                                        <Grid style={{justifyContent: 'center'}} >
                                            <Grid.Column width={15} style={{ marginBottom: '1em' }} >
                                                <Item.Group>
                                                    <Item>
                                                        <ItemContent >
                                                            <Form onSubmit={handleSubmit} autoComplete='off'>
                                                                <FormTextArea
                                                                    name='name'
                                                                    placeholder='Group Name...'
                                                                    rows={2}
                                                                   
                                                                />
                                                            </Form>
                                                        </ItemContent>
                                                    </Item>
                                                </Item.Group>
                                       
                                            </Grid.Column>
                                        </Grid>
                                    </Modal.Description>
                                </Modal.Content>

                                <Modal.Actions>
                                    <Button
                                        content="Save"
                                        primary
                                        type="submit"
                                        loading={isSubmitting || isLoading}
                                        disabled={!dirty || !isValid || isSubmitting || isLoading || !values.name || values.name.length > 128}
                                        onClick={() => handleSubmit()}

                                    />
                                    <Button
                                        disabled={ isSubmitting || isLoading}
                                        onClick={() => setOpen(false)}>
                                        Cancel
                                    </Button>
                                </Modal.Actions>
                            </>
                        )
                    }
                }
                </Formik>
        </Modal>
    );
}

export default EditGroupChatNameModal;