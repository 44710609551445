import { Form, Formik, FormikHelpers } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button, ButtonProps, Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import * as Yup from 'yup';
import TextInput from '../../../app/common/form/TextInput';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { BoardFormValues } from '../../../app/models/board';
import { useStore } from '../../../app/stores/store';
import { history } from '../../../index';

const StyledColumn = styled(Grid.Column)`
        width: 436px !important;
        position: relative !important;
        top: 10em !important;
        padding: 54px 32px !important;
        box-shadow: 0px 3px 6px rgb(21 146 255 / 16%) !important;
        border-radius: 16px !important;
        background-color: #ffffff !important;
`;

interface Props {
    workSpaceId?: string;
}

const BoardForm = () => {
    const { boardStore } = useStore();
    const { id } = useParams<{ id: string }>();
    const { loadBoard, isLoading } = boardStore;
    let location = useLocation();
    const props = location.state as Props;

    const initialValues = props?.workSpaceId ? { ...new BoardFormValues, workSpaceId: props.workSpaceId } : new BoardFormValues;

    const [board, setBoard] = useState<BoardFormValues>(initialValues);

    useEffect(() => {
        if (id) loadBoard(id).then(boardRecord => {
            setBoard(new BoardFormValues(boardRecord));
        });
    }, [id, loadBoard]);

    const handleSubmit = (values: BoardFormValues, { resetForm, setSubmitting }: FormikHelpers<BoardFormValues>) => {
        if (!board.id) {
            boardStore.add(values).then(isSuccess => {
                if (isSuccess) resetForm();
                setSubmitting(false);
            })
        } else {
            boardStore.update(values).then(isSuccess => {
                if (isSuccess) resetForm();
                setSubmitting(false);
            })
        }
    }

    if (isLoading) return <LoadingComponent content="Loading..." />

    return (
        <Formik
            enableReinitialize
            initialValues={board}
            onSubmit={handleSubmit}
            validationSchema={Yup.object({
                name: Yup.string().trim().required('Project Name field is required.')
                    .max(64, 'Project Name field must be at most 64 characters')

            })}>
            {
                ({ handleSubmit, isSubmitting, dirty, isValid }) => (

                    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='top'>
                        <StyledColumn>
                            <Header as='h3' style={{ marginBottom: '32px', textAlign: 'left' }}>{board.id ? 'Edit' : 'Add'} Project</Header>
                            <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                <TextInput name='name' placeholder='Project Name' label='Project Name' type='text' />
                                <Grid columns='equal' style={{ marginTop: '30px', alignItems: 'center' }}>
                                    <Grid.Column style={{ textAlign: 'right' }}>
                                        <Button loading={isSubmitting}
                                            disabled={!isValid || isSubmitting || !dirty}
                                            color='blue' style={{ backgroundColor: '#1592ff', whiteSpace: 'nowrap' }}
                                            content='Save' type='submit' size='medium' />
                                        <Button color='grey' content='Cancel' onClick={
                                            (event: React.MouseEvent<HTMLButtonElement>) => {
                                                history.goBack();
                                                event.preventDefault();
                                        }} size='medium' />
                                    </Grid.Column>
                                </Grid>
                            </Form>
                        </StyledColumn>
                    </Grid>
                )
            }
        </Formik>
    );
}

export default observer(BoardForm);