import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Grid, Header, Modal, Tab } from 'semantic-ui-react';
import PaginatedTable from '../../../app/common/table/PaginatedTable';
import DateHelper from '../../../app/helpers/dateHelper';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { FilterValues } from '../../../app/models/common/filterValues';
import { DailyReport } from '../../../app/models/employeeDailyReport';
import { useStore } from '../../../app/stores/store';
import { FaRegEye } from 'react-icons/fa';

const dateHelper = new DateHelper();

interface Props {
    employeeId: string;
}

const EmployeeDailyReports = ({ employeeId }: Props) => {
    const { employeeStore, modalStore } = useStore();
    const { isDailyReportLoaded, dailyReports, dailyReportsTotal, loadEmployeeDailyReports } = employeeStore;

    const [filterValues, setFilterValues] = useState<FilterValues>({
        id: employeeId,
        pageSize: 20,
        pageNumber: 1,
        searchKeyword: ""
    });

    const handleOnChangePage = (page: number) => {
        setFilterValues({ ...filterValues, pageNumber: page })
    }

    useEffect(() => {
        loadEmployeeDailyReports(filterValues);
    }, [filterValues, loadEmployeeDailyReports])

    return (
        <Tab.Pane>
            <Grid divided='vertically'>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header>Daily Report</Header>
                        <PaginatedTable<DailyReport>
                            columns={[
                                { title: 'Date', render: (data: DailyReport) => dateHelper.formatShortDate(data.timeRecord.timeOutDate.toString()) },
                                { title: 'Summary', field: 'summary' }]}
                            data={dailyReports!}
                            total={dailyReportsTotal}
                            onChangePage={handleOnChangePage}
                            pageSize={20}
                            pageNumber={filterValues.pageNumber}
                            isLoading={!isDailyReportLoaded}
                            style={{ boxShadow: 'none', padding: 0, marginTop: '30px' }}
                            actions={[rowData => ({
                                icon: () => <FaRegEye style={{ color: '#97a4b5', fontSize: '18px' }} />,
                                tooltip: 'View Daily Report',
                                onClick: e => modalStore.openModal(
                                    <Modal.Description >
                                        <Header>Daily Report Summary</Header>
                                        <p>
                                            {rowData['summary']}
                                        </p>
                                    </Modal.Description>
                                ),
                            })]}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Tab.Pane>
    );
}

export default observer(EmployeeDailyReports);