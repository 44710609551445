import { observer } from 'mobx-react-lite';
import { FaCheck } from 'react-icons/fa';
import { Grid, Header, Tab, Table } from 'semantic-ui-react';
import DateHelper from '../../../app/helpers/dateHelper';
import { useStore } from '../../../app/stores/store';

const dateHelper = new DateHelper();

const EmployeeJobDetails = () => {
    const { employeeStore } = useStore();
    const { jobStatus } = employeeStore;

    return (
        <Tab.Pane>
            <Grid divided='vertically'>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header>Employee Job Status</Header>
                        <Table striped style={{ padding: "2em 0", border: 'none' }}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        Employee Status
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Date
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={{ textAlign: 'center' }} >
                                        Temporary Lay Off
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {jobStatus && jobStatus.length != 0 ?
                                    jobStatus.map(({ id, employeeStatus, date, temporaryLayOff }) => (
                                        <Table.Row key={id}>
                                            <Table.Cell >{employeeStatus.name}</Table.Cell>
                                            <Table.Cell  >{dateHelper.formatShortLocalDate(date.toString())}</Table.Cell>
                                            <Table.Cell style={{ textAlign: 'center' }} >{
                                                temporaryLayOff ? <FaCheck style={{ color: '#97a4b5', fontSize: '16px' }} /> : ""}
                                            </Table.Cell>
                                        </Table.Row>
                                    ))
                                    : (<Table.Row><Table.Cell colSpan='5'>
                                        <Header as='h4' style={{ textAlign: 'center', fontWeight: 600, padding: '1em' }}>
                                            No Records
                                        </Header>
                                    </Table.Cell></Table.Row>)
                                }
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>

            </Grid>
        </Tab.Pane>
    );
}

export default observer(EmployeeJobDetails);