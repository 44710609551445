import { makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TextArea } from 'semantic-ui-react';
import styled from 'styled-components';
import CookieHelper from '../../../app/helpers/cookieHelper';
import { Card as CardData } from '../../../app/models/card';
import { useStore } from '../../../app/stores/store';
import CardDetails from './CardDetails';

const useStyle = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(1, 1, 1, 2),
        margin: theme.spacing(1),
        '&:hover': {
            cursor: 'pointer'
        }
    }
}));


const CardTitle = styled(Typography)`
        && {
        color: #172b4d;
        word-wrap: break-word;
        overflow: hidden auto;
        min-height:20px;
        max-height:256px;
        line-height: normal;
        }
`;

const getPriorityColor = (id) => {
    let color = "";

    switch (id) {
        case "7162b690-70cc-4754-b776-058bb1841dd9": //Low Priority
            color = "#2ecc71";
            break;
        case "1a45f09e-153e-48ce-a1d2-7aca99fb3bf2"://Medium Priority
            color = "#f1c40f";
            break;
        case "09058161-6b3e-41b3-9430-e3026eef86b4":// High Priority
            color = "#d35400";
            break;
        case "ff116d8c-42a2-4e81-b402-f0cf9253c8b2"://Urgent/Critical
            color = "#c0392b";
            break;
        default:
    }


    return color ? {
        borderLeft: `8px solid ${color}`
    } : undefined;
}

interface Props {
    card: CardData;
    index: number;
    cardGroupName: string;
}

const Card = ({ card, index, cardGroupName }: Props) => {
    const classes = useStyle();
    const { modalStore, cardAttachmentStore } = useStore();
    const history = useHistory()
    const search = useLocation().search;
    const taskId = new URLSearchParams(search).get("task");


    const handleOnClickCard = () => {
        if (card.id) {
            cardAttachmentStore.setShowAllAttachments(false);
            modalStore.openModal(<CardDetails id={card.id} cardGroupName={cardGroupName} />)
            modalStore.setIsCard(true)
            let url = history.location.pathname;
            history.push(url + `?task=${card.id}`, { replace: true });
        } else {
            toast.error('Error in card loading.')
        }

    }

    if (taskId && card.id == taskId) {
        cardAttachmentStore.setShowAllAttachments(false);
        modalStore.openModal(<CardDetails id={card.id} cardGroupName={cardGroupName} />);
        modalStore.setIsCard(true)
    }

    return (
        <Draggable draggableId={card.id} index={index}>
            {(provided) => (
                <div ref={provided.innerRef}
                    {...provided.dragHandleProps}
                    {...provided.draggableProps}>
                    <Paper style={card.priority ? getPriorityColor(card.priority.id) : {}} className={classes.card} onClick={handleOnClickCard}>
                        <CardTitle>
                            {card.title}
                        </CardTitle>
                    </Paper>
                </div>
            )}
        </Draggable>
    );
}

export default Card;