import { AxiosRequestConfig, AxiosResponse } from 'axios';
import moment from 'moment';
import { AccessToken } from '../models/authorization';
import { Board, BoardFormValues } from '../models/board';
import { Card, CardFormValues } from '../models/card';
import { CardAttachment } from '../models/cardAttachment';
import { CardDate as CardSchedule } from '../models/cardDate';
import { CardGroup, CardGroupFormValues } from '../models/cardGroup';
import { ClientStorage } from '../models/clientStorage';
import { FilterValues, LeaveBalanceFilterValues, TimeRecordFilterValues, DailyReportFilterValues, EmployeeFilterValues, LeaveRequestExportFilterValues } from '../models/common/filterValues';
import { Conversation, PostConversation } from '../models/conversation';
import { Department, Education, Employee, EmployeeSchedule, Holiday, HolidaySchedule, JobStatus, LeaveBalance, Schedule, WorkExperience, EmployeeLeaveBalance } from '../models/employee';
import { DailyReport } from '../models/employeeDailyReport';
import { LeaveRequest } from '../models/leaveRequest';
import { Message, PostMessage, PostMessageAttachment, PostMessageResponce } from '../models/message';
import { Option } from '../models/option';
import { Overtime } from '../models/overtime';
import { Post, PostAttachment, PostForm, PostLikes as PostLikesModel, PostComment, EditPostForm } from '../models/post';
import { SubClient, SubClientFormValues } from '../models/subClient';
import { TimeRecord } from '../models/timeRecord';
import { Undertime } from '../models/undertime';
import { RefreshToken, ResetPasswordFormValues, User, UserFormValues } from '../models/user';
import { WorkSpace, WorkSpaceFormValues } from '../models/workSpace';
import { WorkSpaceType, WorkSpaceTypeFormValues } from '../models/workSpaceType';
import { WorkSpaceUser, WorkSpaceUserFormValues } from '../models/workSpaceUser';
import { Notification } from '../models/notification';
import axiosService from './axios-service';
import { Role } from '../models/role';
import { TimeAdjustmentModel } from '../models/timeAdjustment';
import { EventModel } from '../models/Events';
import { TimeZoneModel } from '../models/timeZone';

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T>(url: string, config?: AxiosRequestConfig) => axiosService.get<T>(url, config).then(responseBody),
    upload: <T>(url: string, formData: FormData, config?: AxiosRequestConfig) => axiosService.post<T>(url, formData, config).then(responseBody),
    uploadPut: <T>(url: string, formData: FormData, config?: AxiosRequestConfig) => axiosService.put<T>(url, formData, config).then(responseBody),
    post: <T>(url: string, body: {}) => axiosService.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axiosService.put<T>(url, body).then(responseBody),
    patch: <T>(url: string, body: {}) => axiosService.patch<T>(url, body).then(responseBody),
    delete: <T>(url: string) => axiosService.delete<T>(url).then(responseBody),
}

const Account = {
    login: (user: UserFormValues) => requests.post<AccessToken>('/authenticate/user', user),
    forgotPassword: (credentials: { username: string }) => requests.post('/accounts/clients/password-reset-email', credentials),
    resetPassword: (credentials: ResetPasswordFormValues) => requests.post<void>('/system/emails/reset-password', credentials),
    current: () => requests.get<User>('/accounts/clients/self'),
    getProfilePhoto: (id: string, config: AxiosRequestConfig) => requests.get<Blob>(`/clients/employees/${id}/profile-picture`, config),
    refreshToken: (oldToken: RefreshToken) => requests.post<User>('/authenticate/refresh-token', oldToken),
}

const Employees = {
    filter: (query: FilterValues) => requests.get<{ total: number, employees: Employee[] }>
        (`/clients/employees?search=${query.searchKeyword}`),
    getAll: () => requests.get<{ total: number, employees: Employee[] }>
        (`/clients/employees`),
    filterEmployeesSelectedDetails: (query: FilterValues) => requests.get<{ total: number, employees: Employee[] }>
        (`/clients/employees?${createUrlParam(query)}`),
    filterDailyReports: (query: FilterValues) => requests.get<{ total: number, data: DailyReport[] }>
        (`/clients/employees/${query.id}/daily-reports?${createUrlParam(query)}`),
    filterDTR: (query: FilterValues) => requests.get<{ total: number, data: TimeRecord[] }>
        (`/clients/employees/${query.id}/time-records?${createUrlParam(query)}`),
    loadPersonalDetails: (userId: string) => requests.get<Employee>(`/clients/employees/${userId}`),
    loadWorkExperiences: (userId: string) => requests.get<WorkExperience[]>(`/clients/employees/${userId}/work-experiences`),
    loadEducationRecords: (userId: string) => requests.get<Education[]>(`/clients/employees/${userId}/education-records`),
    loadSchedule: (userId: string) => requests.get<EmployeeSchedule>(`/clients/employees/${userId}/schedule`),
    filterByDepartment: (query: EmployeeFilterValues) => requests.get<{ total: number, employees: Employee[] }>
        (`/clients/employees?${createUrlParam(query)}`),
    getAllDepartment: () => requests.get<Department[]>
        (`/clients/departments`),
    getNonWorkspaceMembers: (workspaceId: string) => requests.get<{ total: number, employees: Employee[] }>
        (`/workspaces/${workspaceId}/non-members`),
    getUpComingBirthdays: (clientId: string) => requests.get<User[]>(`/employees/upcomming-birthdays?clientId=${clientId}`),
    getHolidaySchedule: (userId: string) => requests.get<HolidaySchedule>(`/clients/employees/${userId}/holiday-schedules`),
    getLeaveBalances: (userId: string) => requests.get<EmployeeLeaveBalance[]>(`/clients/employees/${userId}/leave-balances`),
    getPreviousLeaveBalanceHistory: (query: FilterValues, userId: string) => requests.get<{ total: number, leaveBalanceHistory: LeaveBalance[] }>
        (`/clients/employees/${userId}/previous-leave-balance-history?${createUrlParam(query)}`),
    loadHolidays: (query: FilterValues, holidayScheduleId: string) => requests.get<{ total, number, holidays: Holiday[] }>
        (`/clients/employees/holiday-schedules/${holidayScheduleId}/holidays?${createUrlParam(query)}`),
    loadLeaveBalance: (query: LeaveBalanceFilterValues, userId: string) => requests.get<{ total: number, leaveBalanceHistory: LeaveBalance[] }>
        (`/clients/employees/${userId}/leave-balance-history?${createUrlParam(query)}`),
    loadJobStatus: (userId: string) => requests.get<JobStatus[]>(`/clients/employees/${userId}/status-history`)
}

const Common = {
    loadGenders: () => requests.get<Option[]>('/genders'),
    loadDtrStatuses: () => requests.get<Option[]>('/dtr-statuses'),
    loadNationalities: () => requests.get<Option[]>('nationalities'),
    loadCivilStatuses: () => requests.get<Option[]>('civil-statuses'),
    loadDepartments: () => requests.get<Option[]>('departments'),
}

const Clients = {
    getUsedStorage: (id: string | undefined) => requests.get<ClientStorage>(`/clients/${id}/used-storage`),
    getProfilePhoto: (userId: string, clientId: string, config: AxiosRequestConfig) => requests.get<Blob>(`clients/attachments/profile-picture?userId=${userId}&clientId=${clientId}`, config),
    getExternalUser: (userId: string, clientId: string) => requests.get<Employee>(`clients/external-employees/${userId}?clientId=${clientId}`),

}

const LeaveRequests = {
    filter: (query: FilterValues) => requests.get<{ total: number, data: LeaveRequest[] }>
        (`/clients/employees/leave-requests?skip=${((query.pageNumber - 1) * query.pageSize)}&take=${query.pageSize}&search=${query.searchKeyword}`),
    approve: (id: string, agent: string) => requests.patch(`/leave-requests/${id}/client/approve?agent=${agent}`, {}),
    reject: (id: string, agent: string) => requests.patch(`/leave-requests/${id}/client/reject?agent=${agent}`, {}),
    get: (id: string) => requests.get<LeaveRequest>(`/clients/employees/leave-requests/${id}`),
    download: (query: LeaveRequestExportFilterValues, config: AxiosRequestConfig) => {
    
        const startDate = moment(query.startDate, "MM-DD-YYYY").toISOString();
        const endDate = moment(query.endDate, "MM-DD-YYYY").toISOString();
        return requests.get<Blob>(`/clients/leave-requests/download?startDate=${startDate}&endDate=${endDate}`, config)
    },
}

const OvertimeRequests = {
    filter: (query: FilterValues) => requests.get<{ total: number, data: Overtime[] }>
        (`/clients/employees/overtimes?skip=${((query.pageNumber - 1) * query.pageSize)}&take=${query.pageSize}&search=${query.searchKeyword}`),
    approve: (id: string, agent: string) => requests.patch(`/overtimes/${id}/client/approve?agent=${agent}`, {}),
    reject: (id: string, agent: string) => requests.patch(`/overtimes/${id}/client/reject?agent=${agent}`, {}),
    get: (id: string) => requests.get<Overtime>(`/clients/employees/overtimes/${id}`),
}

const UndertimeRequests = {
    filter: (query: FilterValues) => requests.get<{ total: number, data: Undertime[] }>
        (`/clients/employees/undertimes?skip=${((query.pageNumber - 1) * query.pageSize)}&take=${query.pageSize}&search=${query.searchKeyword}`),
    approve: (id: string, agent: string) => requests.patch(`/undertimes/${id}/client/approve?agent=${agent}`, {}),
    reject: (id: string, agent: string) => requests.patch(`/undertimes/${id}/client/reject?agent=${agent}`, {}),
    get: (id: string) => requests.get<Undertime>(`/clients/employees/undertimes/${id}`),
}

const DailyReports = {
    filter: (filters: DailyReportFilterValues) => requests.get<{ total: number, data: DailyReport[] }>
        (`/clients/employees/daily-reports?${createUrlParam(filters)}`),
}

const ScheduleManagement = {
    filter: (query: { userId: string, startDate: string, endDate: string }) => requests.get<EventModel[]>(`/clients/schedule-managements?${createUrlParam(query)}`),
}

const WorkSpaceTypes = {
    filter: (query: FilterValues) => requests.get<{ total: number, data: WorkSpaceType[] }>(`/workspace-types?${createUrlParam(query)}`),
    add: (workSpaceType: { name: string }) => requests.post<string>('/workspace-types', workSpaceType),
    update: (workSpaceType: WorkSpaceTypeFormValues) => requests.put(`/workspace-types/${workSpaceType.id}`, workSpaceType),
    get: (id: string) => requests.get<WorkSpaceType>(`/workspace-types/${id}`),
    getAll: () => requests.get<WorkSpaceType[]>(`/workspace-types`),
    delete: (id: string) => requests.delete(`/workspace-types/${id}`),
}

const WorkSpaces = {
    filter: (query: FilterValues) => requests.get<{ total: number, data: WorkSpace[] }>(`/workSpaces?${createUrlParam(query)}`),
    add: (workSpace: WorkSpaceFormValues) => requests.post<string>('/workspaces', workSpace),
    update: (workSpace: WorkSpaceFormValues) => requests.put(`/workspaces/${workSpace.id}`, workSpace),
    get: (id: string) => requests.get<WorkSpace>(`/workspaces/${id}`),
    delete: (id: string) => requests.delete(`/workspaces/${id}`),
}

const WorkSpaceUsers = {
    filter: (query: FilterValues) => requests.get<{ total: number, data: WorkSpaceUser[] }>(`/user-workspaces?${createUrlParam(query)}`),
    add: (workSpaceUser: WorkSpaceUserFormValues) => requests.post<string>('/user-workspaces', workSpaceUser),
    addMultiple: (workSpaceId: string, userIds: string[]) => requests.post<string>('/user-workspaces', { workSpaceId: workSpaceId, userIds: userIds }),
    update: (workSpaceUser: WorkSpaceUserFormValues) => requests.put(`/user-workspaces/${workSpaceUser.id}`, workSpaceUser),
    get: (id: string) => requests.get<WorkSpaceUser>(`/user-workspaces/${id}`),
    delete: (id: string) => requests.delete(`/user-workspaces/${id}`),
    deleteUserWorkSpace: (memberId: string, workSpaceId: string) => requests.delete(`/user-workspaces/${workSpaceId}/users/${memberId}/`),
}

const SubClients = {
    filter: (query: FilterValues) => requests.get<{ total: number, data: SubClient[] }>(`/clients/sub-clients?${createUrlParam(query)}`),
    add: (subClient: SubClientFormValues) => requests.post<string>('/workspaces', subClient),
    update: (subClient: SubClientFormValues) => requests.put(`/accounts/sub-clients/${subClient.id}`, subClient),
    get: (id: string) => requests.get<WorkSpace>(`/accounts/sub-clients/${id}`),
    delete: (id: string) => requests.delete(`/accounts/sub-clients/${id}`),
}

const Boards = {
    filter: (query: FilterValues) => requests.get<{ total: number, data: Board[] }>
        (`/boards?skip=${(query.pageNumber - 1) * query.pageSize}&take=${query.pageSize}&search=${query.searchKeyword}&workSpaceId=${query.id}`),
    add: (board: BoardFormValues) => requests.post<string>('/boards', board),
    update: (board: BoardFormValues) => requests.put(`/boards/${board.id}`, board),
    get: (id: string) => requests.get<Board>(`/boards/${id}`),
    getAll: (workSpaceId: string) => requests.get<Board[]>(`/boards?workSpaceId=${workSpaceId}`),
    delete: (id: string) => requests.delete(`/boards/${id}`),
    getBoardByCardId: cardId => requests.get<string>(`/boards/getBy/${cardId}`)
}

const DtrSummary = {
    filter: (query: TimeRecordFilterValues) => requests.get<{ total: number, data: TimeRecord[] }>
        (`/clients/employees/dtr?${createUrlParam(query)}`),
    download: (query: TimeRecordFilterValues, config: AxiosRequestConfig) => requests.get<Blob>(`/clients/time-records/download?${createUrlParam(query)}`, config),
}

const CardGroups = {
    add: (cardGroup: CardGroupFormValues) => requests.post<string>('/cards/groups', cardGroup),
    update: (cardGroup: CardGroupFormValues) => requests.put(`/cards/groups/${cardGroup.id}`, cardGroup),
    get: (id: string) => requests.get<CardGroup>(`/Authenticated/CardGroups/${id}`),
    getAll: (boardId: string) => requests.get<CardGroup[]>(`/Authenticated/CardGroups?boardId=${boardId}`),
    delete: (id: string) => requests.delete(`/Authenticated/CardGroups/${id}`),
}

const Cards = {
    add: (card: CardFormValues) => requests.post<string>('/cards', card),
    update: (card: CardFormValues) => requests.put(`/cards/${card.id}`, card),
    get: (id: string) => requests.get<Card>(`/cards/${id}`),
    getAll: (cardGroupId: string) => requests.get<Card[]>(`/cards?cardGroupId=${cardGroupId}`),
    delete: (id: string) => requests.delete(`/cards/${id}`),
}

const Conversations = {
    filter: (query: FilterValues) => requests.get<{ total: number, conversations: Conversation[] }>
        (`/conversations?${createUrlParam(query)}`),
    get: (id: string) => requests.get<Conversation>(`/conversations/${id}`),
    add: (coversation: PostConversation) => requests.post<string>('/conversations', coversation),
    updateIsRead: (id: string, isReadRequest: { isRead: boolean }) => requests.patch<string>(`/conversations/${id}/read`, isReadRequest),
    updateReadReceipt: (id: string) => requests.patch<string>(`/conversations/${id}/read-receipt`, {}),
    updateNotificationReceipt: (request: { ids: string[] }) => requests.put<string>(`/conversations/notification-receipts`, request),

    filterEmployeeByConversation: (conversataionId, query: FilterValues) => requests.get<{ total: number, employees: Employee[] }>
        (`/conversations/${conversataionId}/users/non-member?${createUrlParam(query)}`),
    getUsersByConverSation: (conversationId: string) => requests.get<Employee[]>(`/conversations/${conversationId}/users`),
    addUsersToConversation: (conversationId: string, users: string[]) => requests.post<any>('/conversations/users', { conversationId: conversationId, userIds: users }),
    deleteUser: (conversationId: string, userId: string) => requests.delete(`/conversations/${conversationId}/users/${userId}`),

    rename: (conversationId: string, conversationName: string) => requests.patch<any>(`/conversations/${conversationId}/Rename`, {
        conversationId: conversationId,
        conversationName: conversationName,
    }),
}

const Messages = {
    getAll: (id: string) => requests.get<Message[]>(`/messages?conversationId=${id}`),
    add: (message: PostMessage) => requests.post<PostMessageResponce>('/messages', message),
    addMessageAttachment: (content: PostMessageAttachment) => {
        let formData = new FormData();

        for (let i = 0; i < content.files.length; i++) {
            formData.append(`Files`, content.files[i])
        }
        for (let i = 0; i < content.receiversId.length; i++) {
            formData.append(`receiversId`, content.receiversId[i])
        }

        formData.append('Content', content.content);
        formData.append('LoadingId', content.loadingId!);

        if (content.conversationId)
            formData.append('ConversationId', content.conversationId);
        if (content.connectionId)
            formData.append('ConnectionId', content.connectionId);

        return requests.upload<PostMessageAttachment>('/messages/attachment', formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        });
    },
    updateMultipleReadMessages: Ids => requests.patch(`/messages/read`, {
        messageIds: Ids
    })
}

const Notifications = {
    get: (id: string) => requests.get<Notification>(`/notifications/${id}`),
    filter: (notifierId: string, roleId: string, skip?: number, take?: number) => requests.get<{ total: number, notifications: Notification[], totalUnread: number }>(`/notifications?${createUrlParam({ notifierId, roleId, skip, take })}`),
    register: (userId: string, roleId: string, connectionId: string) => requests.post('/notifications/register', { userId, roleId, connectionId }),
    unregister: (userId: string, roleId: string, connectionId: string) => requests.post('/notifications/unregister', { userId, roleId, connectionId }),
    getUnreadCount: (userId: string, roleId: string) => requests.get<number>(`/notifications/${userId}/unread-count?roleId=${roleId}`),
    markRead: (userId: string, roleId: string) => requests.patch(`notifications/${userId}/mark-read?roleId=${roleId}`, {}),
    markOpened: (notificationId: string) => requests.patch(`notifications/${notificationId}/mark-opened`, {}),
}

const CardAttachments = {
    upload: (file: Blob, cardId: string) => {
        let formData = new FormData();
        formData.append('File', file);
        formData.append('CardId', cardId);
        return requests.upload<CardAttachment>('/cards/attachments', formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        });
    },
    uploadMultiple: (files: File[], cardId: string, clientId: string) => {
        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append(`Files`, files[i])
        }
        formData.append('CardId', cardId);
        console.log(clientId);
        if (clientId) {
            formData.append('ClientId', clientId);
        }

        return requests.upload<string>('/cards/attachments/multiple', formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        });
    },
    getAttachment: (id: string, config: AxiosRequestConfig) => requests.get<Blob>(`cards/attachments/${id}`, config),
}

const CommentAttachments = {
    add: (cardId: string, files: File[], message: string, clientId: string) => {
        let formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append(`Files`, files[i])
        }

        formData.append('Message', message);
        formData.append('CardId', cardId);
        if (clientId) {
            formData.append('ClientId', clientId);
        }


        return requests.upload<string>('/cards/comments', formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        });
    },
    update: (cardId: string, commentId: string, files: File[], message: string, clientId: string) => {
        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append(`Files`, files[i])
        }

        formData.append('Message', message);
        formData.append('CardId', cardId);
        formData.append('CommentId', commentId);
        if (clientId) {
            formData.append('ClientId', clientId);
        }


        return requests.uploadPut<string>(`/cards/comments/${commentId}`, formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        });
    },
    getAttachment: (id: string, config: AxiosRequestConfig) => requests.get<Blob>(`cards/comments/attachments/${id}`, config),
}
const CardUser = {
    getAll: (cardId: string, workSpaceId: string, clientId: string, createdById?: string) => requests.post<User[]>(`cards/${cardId}/unassigned-users`,
        {
            id: cardId,
            workSpaceId,
            clientId: clientId,
            createdById: createdById
        }
    ),
    getAllCardMembers: (cardId: string) => requests.get<User[]>(`/cards/${cardId}/users`),// NOT USED
    addMultiple: (cardId: string, userIds: string[]) => requests.post<string>('/cards/users', { cardId: cardId, userIds: userIds }),
    delete: (cardId: string, userId: string) => requests.delete(`/cards/${cardId}/users/${userId}`),
}

const CardDate = {
    addSchedule: (request) => requests.post<any>('/cards/schedules', request),
    getSchedule: (id: string) => requests.get<CardSchedule>(`cards/schedules/${id}`),
    deleteSchedule: (id: string) => requests.delete(`cards/schedules/${id}`),
}

const ChatAttachments = {
    add: (files: File[], message: string) => {
        let formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append(`Files`, files[i])
        }

        formData.append('Message', message);

        return requests.upload<string>('/Authenticated/Message', formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        });
    },
    update: (cardId: string, commentId: string, files: File[], message: string, clientId: string) => {
        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append(`Files`, files[i])
        }

        formData.append('Message', message);
        formData.append('CardId', cardId);
        formData.append('CommentId', commentId);
        formData.append('ClientId', clientId);

        return requests.upload<string>('/Authenticated/CardComment/Update', formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        });
    },
    getAttachment: (id: string, config: AxiosRequestConfig) => requests.get<Blob>(`/messages/attachment/${id}`, config),
}

const Posts = {
    getById: (id: string) => requests.get<Post>(`posts/${id}`),
    filter: (pageNumber: number, pageSize: number, clientId?: string) => requests.get<Post[]>(`posts?${createUrlParam({ pageNumber, pageSize, clientId })}`),
    getAttachments: (id: string) => requests.get<PostAttachment[]>(`posts/${id}/attachments`),
    post: (data: PostForm) => requests.post<string>('posts/', data),
    update: (data: EditPostForm) => requests.put<string>('posts/' + data.id, data),
    getLikes: (postId: string) => requests.get<PostLikesModel>(`posts/${postId}/likes`),
    getComments: (postId: string) => requests.get<PostComment[]>(`posts/${postId}/comments`),
    delete: (postId: string) => requests.delete(`posts/${postId}`),
}

const Roles = {
    getClient: async () => {
        let { roles } = await requests.get<{ roles: Role[] }>(`roles?${createUrlParam({ take: 1, skip: 0, search: 'client' })}`);
        return roles[0];
    }
}

const PostAttachments = {
    get: (id: string) => requests.get<Blob>(`posts/attachments/${id}`, { responseType: 'blob' }),
    upload: (data: FormData) => requests.upload<string>('posts/attachments/', data, {
        headers: { 'Content-type': 'multipart/form-data' }
    }),
    delete: (id: string) => requests.delete(`posts/attachments/${id}`),
}

const PostLikes = {
    post: (postId: string) => requests.post<string>(`/posts/likes`, { postId }),
    delete: (postId: string) => requests.delete(`/posts/${postId}/likes`),
}

const PostComments = {
    post: (data: { postId: string, message: string }) => requests.post<string>(`/posts/comments`, data),
    update: (data: { id: string, message: string }) => requests.put(`/posts/comments/${data.id}`, data),
    delete: (id: string) => requests.delete(`/posts/comments/${id}`),
}

const Priorities = {
    getAll: () => requests.get<Option[]>(`/lookups/priorities`),
}

const RequestAttachment = {
    getAttachment: (id: string, config: AxiosRequestConfig) => requests.get<Blob>(`/clients/attachments/${id}/get-file`, config),
}

const TimeAdjustment = {
    getById: (id: string) => requests.get<TimeAdjustmentModel>(`/clients/time-adjustments/${id}`),
    filter: (search: string, pageNumber: number, pageSize: number) => requests.get<{ total: number, timeAdjustments: TimeAdjustmentModel[] }>(`/clients/time-adjustments?${createUrlParam({ search, pageNumber, pageSize })}`),
    approve: (id: string, agent: string) => requests.patch(`/clients/time-adjustments/${id}/approve?agent=${agent}`, {}),
    reject: (id: string, agent: string) => requests.patch(`/clients/time-adjustments/${id}/reject?agent=${agent}`, {}),
}

const TimeZone = {
    getAll: () => requests.get<TimeZoneModel[]>(`/time-zones`),
    update: (id: string) => requests.patch('users/time-zone', { timeZone: id })
}

const createUrlParam = (params) => {
    let str = "";

    for (var key in params) {
        if (!Array.isArray(params[key])) {
            if (typeof params[key] !== 'undefined') {
                if (str != "") {
                    str += "&";
                }

                if (params[key] instanceof Date) {
                    str += key + "=" + moment(params[key]).format('YYYY-MM-DD')
                }
                else if (key === 'searchKeyword') {
                    str += "search" + "=" + encodeURIComponent(params[key]);
                }
                else if (key === "pageSize") {
                    str += "take" + "=" + encodeURIComponent(params[key]);
                }
                else if (key === "pageNumber") {
                    str += "skip" + "=" + encodeURIComponent(((params[key] - 1) * params["pageSize"]));
                }
                //else if (params[key] === null) {
                //    str += key += "="
                //}
                else {
                    if (params[key] === null) {
                        str += key += "="
                    } else {
                        str += key + "=" + encodeURIComponent(params[key]);
                    }
                }
            }
        }
        else {
            for (let variable of params[key]) {
                if (variable) {
                    if (variable != "") {
                        str += "&";
                    }
                    str += key + "=" + encodeURIComponent(variable);
                }
            }
        }
    }

    return str;
}

const agent = {
    Account,
    Common,
    Employees,
    SubClients,
    LeaveRequests,
    OvertimeRequests,
    UndertimeRequests,
    DailyReports,
    WorkSpaceTypes,
    Clients,
    WorkSpaces,
    WorkSpaceUsers,
    Boards,
    DtrSummary,
    CardGroups,
    Cards,
    Conversations,
    Messages,
    Notifications,
    CardAttachments,
    CommentAttachments,
    ChatAttachments,
    CardUser,
    CardDate,
    ScheduleManagement,
    Roles,
    Posts,
    PostAttachments,
    PostLikes,
    PostComments,
    Priorities,
    RequestAttachment,
    TimeAdjustment,
    TimeZone,
}

export default agent;
