import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Dropdown, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { useStore } from "../../../app/stores/store";

const Menu = styled(Dropdown)`
    width: 100%;
    margin-bottom: 8px !important;
    color: rgba(0,0,0,.6) !important;
    background-color: #091e4214  !important;
    cursor: pointer !important;
    
    & input{
        cursor: pointer !important;
    }

     & div{
        cursor: pointer !important;
    }
`;

interface Props {
    cardId: string;
    priorityId: string;
}


const PriorityMenu = (props: Props) => {
    const { cardStore: { priorities, getPriorities, updatePriority } } = useStore();
    const [value, setValue] = useState(props.priorityId);

    useEffect(() => {
        getPriorities();
    }, []);


    let options = [
        {
            key: "0000",
            text: "Select Priority...",
            value: ""
        },
        ...priorities.map(p => {
            return {
                key: p.id,
                value: p.id,
                text: p.name
            }
        })
    ];

    const handleChange = (e, { value }) => {
        updatePriority(props.cardId, value ? value : null);
    };

    return <Menu
        button
        className='icon'
        floating
        labeled
        value={value}
        onChange={handleChange}
        icon={
            <Icon
                disabled name='tasks'
                style={{
                    color: 'rgba(0,0,0,.6)',
                    backgroundColor: 'transparent'
                }}
            />
        }
        options={options}
        search
        text='Priority'
    />;
}

export default observer(PriorityMenu);