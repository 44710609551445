import { observer } from 'mobx-react-lite';
import React from 'react';
import { Button, Item, Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useStore } from '../../stores/store';

const swal = withReactContent(Swal);
//#region Styling

const ItemContent = styled(Item.Content)`
    &&{
        margin: 0 0 0 0em !important;
        max-width: fit-content;
        min-width: 100%; 
  
  
    }
`;

const StyledItem = styled(Item)`
    &&{
        margin:5px 0 !important;
    }
`;
const WarningContainer = styled.div`
    &&{
       border: 3px solid #f8bb86;
        border-radius: 50%;
        width: 6em;
        height: 6em;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
const ItemContainer = styled.div`
    &&{
        width: 100%;
        padding: 15px;
        display: flex;
        justify-content: center;
        padding:.5em;
    }
`;
const CenterDiv = styled.div`
    &&{
    display: flex;
    justify-content: center;
    flex-flow: wrap;
    }
`;
const selectStyles = {
    container: (provided, state) => ({
        ...provided,
        margin: '5px',
        width: '97%',
        zIndex: '9999'
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        overflowY: 'auto',
        maxHeight: '100px'
    }),
    menuList: (provided, state) => ({
        ...provided,
        overflowY: 'auto',
        maxHeight: '190px'
    }),
}
//#endregion

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    conversationdId: string;
    userId: string;
    name: string;
}
interface Groupchat {
    userIds: { value: string, label: string }[]
}

const files: File[] = [];
const data = {
    content: "",
    ids: Array<string>()
}

const DeleteMemberModal = ({ open, setOpen, conversationdId, userId, name }: Props) => {
    const { chatStore, messageStore, conversationStore, userStore } = useStore();
    const {  employeesIncludedOnConversation, deleteMember } = chatStore;
    const { addMessageWithAttachment } = messageStore;
    const { user } = userStore;
    const { selectedConversation } = conversationStore;
    const handleDeleteMember = (conversationdId: string, userId: string, name: string) => {
        let usersToUpdate = employeesIncludedOnConversation.filter(user => {
            if (user.id != userId) {
                return user;
            }
        }).map(u => u.id);
        setOpen(false);
        deleteMember(conversationdId, userId).then(
            () => {
                data.content = `${user?.firstName + " " + user?.lastName} has removed ${name} from this conversation.[[SystemGenerated]]`;
                addMessageWithAttachment(
                    {
                        content: data.content,
                        conversationId: selectedConversation?.id,
                        receiversId: [],
                        connectionId: chatStore.connectionId,
                        files: files
                    }
                ).then(() => {
                    chatStore.hubConnection?.invoke('OnRemoveUserToTheConversation', userId, selectedConversation?.id);
                    chatStore.hubConnection?.invoke('OnUpdateNumberOfUsersToTheConversation', usersToUpdate, selectedConversation?.id);
                    data.ids = [];
                   
                });
            })

        
    }

    const handleOnCLose = () => {
        setOpen(false);

    }

    return (
        <Modal
            style={{ maxWidth:'37em' }}
            open={open}
            onClose={() => handleOnCLose}
            closeOnDimmerClick={false}
        >
            <Modal.Content image scrolling style={{ overflowX: 'hidden' }} >
                <Modal.Description style={{ maxHeight: '20em', flex: 'auto' }} >
                    <CenterDiv>
                        <WarningContainer>
                            <span style={{ color: "#f8bb86", fontSize:'3em' }} >!</span>
                        </WarningContainer>
                        <ItemContainer>
                            <h2>Delete?</h2>
                        </ItemContainer>
                        <ItemContainer>
                            <span style={{ fontSize: "1.2em" }}>Are you sure you want to remove this Member?</span>
                        </ItemContainer>
                        <ItemContainer>
                            <Button
                                content="Yes"
                                primary
                                type="submit"
                                onClick={() => handleDeleteMember(conversationdId, userId, name)}

                            />
                            <Button color="red" onClick={() => setOpen(false)}>
                                No
                            </Button>
                        </ItemContainer>
                    </CenterDiv>
                </Modal.Description>
             
            </Modal.Content>

        
        </Modal>
    );
}

export default observer(DeleteMemberModal);