import { makeStyles, Paper } from '@material-ui/core';
import MaterialTable, { Action, Column, MaterialTableProps, Query, QueryResult } from 'material-table';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties, useEffect, useRef } from 'react';
import { Header } from 'semantic-ui-react';
import SearchToolbar from './SearchToolbar';
import DateRangeFilter from './DateRangeFilter';
import UserFilter from './UserFilter';
import OptionFilter from './OptionFilter';
import { OptionSelect } from '../../models/option';

interface Props<T extends object> extends MaterialTableProps<T> {
    columns: Column<T>[];
    total: number;
    data: T[] | ((q: Query<T>) => Promise<QueryResult<T>>);
    onChangePage: (page: number) => void;
    pageSize: number;
    pageNumber: number;
    isLoading?: boolean;
    actions?: ((rowData: T) => Action<object>)[];
    onSearchChange?: ((searchText: string) => void) | undefined;
    search?: boolean;
    toolbar?: boolean;
    searchPlaceholder?: string;
    searchLabel?: string;
    style?: CSSProperties;
    sorting?: boolean;
    draggable?: boolean;
    dateFilterLabel?: string;
    onDateRangeFilter?: (startDate?: Date, endDate?: Date) => void | undefined;
    dateRangeValues?: Date[];
    dateRangeFilter?: boolean;
    customOptionFilters?: OptionFilter[]
    userFilterLabel?: string;
    onUserFilter?: (userIds: string[]) => void | undefined;
    userValues?: OptionSelect[];
    userFilter?: boolean;
    userFilterPlaceholder?: string;
    onRowClick?: (event, rowdata) => void | undefined;
    paging?: boolean;
}

interface options {
    value: string,
    text: string,
}

interface OptionFilter {
    onChange: (value: any) => void | undefined;
    options: options[];
    value?: string;
    label?: string;
    placeholder: string;
    customStyle?: React.CSSProperties;
}

function PaginatedTable<T extends object>(props: Props<T>) {

    const useStyles = makeStyles({
        tableTheme: {
            boxShadow: 'none',
            '& > div:nth-child(1)': {
                borderRadius: props.toolbar ? '0' : '16px',
                '& > div': {
                    padding: props.toolbar ? 0 : '0 8px',
                }
            },
            '& > div:nth-child(2)': {
                '& > div': {
                    padding: props.toolbar ? '0 8px' : 0,
                }
            },
            '& .MuiTablePagination-root': {
                borderBottom: 'none'
            },
            '& .MuiTablePagination-toolbar': {
                minHeight: '60px',
            },
            '& .MuiTablePagination-toolbar .MuiButton-label': {
                fontSize: '1rem'
            },
            '& .MuiTablePagination-toolbar .MuiIconButton-label > span': {
                fontSize: '1.6rem'
            }
        },
        tableBody: {
            backgroundColor: '#555'
        }
    });

    const classes = useStyles();

    const addCellStyleInColumns = () => {
        return props.columns.map((c, i) => {
            if (i == 0) {
                if (c.cellStyle) {
                    return {
                        ...c,
                        cellStyle: { ...c.cellStyle, borderBottom: 'none', paddingLeft: '30px' },
                        headerStyle: { ...c.headerStyle, paddingLeft: '30px' }
                    }
                }
                else {
                    return {
                        ...c,
                        cellStyle: { borderBottom: 'none', paddingLeft: '30px' },
                        headerStyle: { paddingLeft: '30px' }
                    }
                }
            }
            else if (!props.actions && i + 1 == props.columns.length) {
                if (c.cellStyle) {
                    return {
                        ...c,
                        cellStyle: { ...c.cellStyle, borderBottom: 'none', paddingRight: '30px' },
                        headerStyle: { ...c.headerStyle, paddingRight: '30px' }
                    }
                }
                else
                    return {
                        ...c,
                        cellStyle: { borderBottom: 'none', paddingRight: '30px' },
                        headerStyle: { paddingRight: '30px' }
                    }
            }
            else {
                if (c.cellStyle) {
                    return {
                        ...c,
                        cellStyle: { ...c.cellStyle, borderBottom: 'none' },
                        headerStyle: { ...c.headerStyle }
                    }
                }
                else
                    return {
                        ...c,
                        cellStyle: { borderBottom: 'none' },
                    }
            }
        });
    }

    return (
        <MaterialTable<T>
            onRowClick={props.onRowClick}
            columns={addCellStyleInColumns()}
            actions={props.actions}
            page={props.pageNumber > 0 ? props.pageNumber - 1 : props.pageNumber}
            totalCount={props.total ?? 0}
            localization={{
                body: {
                    emptyDataSourceMessage: <Header as='h4' style={{ textAlign: 'center', fontWeight: 600, padding: '1em' }}>No Records</Header>,
                },
                toolbar: { searchPlaceholder: props.searchPlaceholder ?? 'Search name...' }
            }}
            onChangePage={(p) => props.onChangePage(p + 1)}
            onSearchChange={props.search ? props.onSearchChange : undefined}
            components={{
                Toolbar: (toolbarProps) => (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "left",
                            width: "100%",
                            padding: '16px 16px',
                            borderBottom: '2px solid #d7e8f9'
                        }}>
                        {props.search &&
                            (<SearchToolbar
                                toolbarProps={toolbarProps}
                                searchLabel={props.searchLabel} />)}
                        {props.customOptionFilters && props.customOptionFilters.map((optionFilter) => {
                            return (<OptionFilter label={optionFilter.label}
                                valueProps={optionFilter.value}
                                options={optionFilter.options}
                                onOptionFilter={optionFilter.onChange}
                                placeholdder={optionFilter.placeholder}
                                customStyle={optionFilter.customStyle}
                            />);
                        })}
                        {props.userFilter &&
                            (<UserFilter
                            toolbarProps={toolbarProps}
                            userFilterLabel={props.userFilterLabel}
                            onUserFilter={props.onUserFilter}
                            userValues={props.userValues}
                            userFilterPlaceholder={props.userFilterPlaceholder}
                        />)
                        }
                        {props.dateRangeFilter &&
                            (<DateRangeFilter
                                toolbarProps={toolbarProps}
                                dateFilterLabel={props.dateFilterLabel}
                                onDateRangeFilter={props.onDateRangeFilter}
                                dateRangeValues={props.dateRangeValues} />)}
                    
                    </div>
                ),
                Container: (props) => <Paper className={classes.tableTheme} {...props} />,
            }}
            data={props.data}
            isLoading={props.isLoading ?? false}
            style={{ borderRadius: '16px', ...props.style }}
            options={{
                paging: props.paging != undefined ? props.paging : true,
                paginationType: 'stepped',
                actionsColumnIndex: props.actions ? - 1 : undefined,
                showTitle: false,
                pageSize: props.pageSize,
                pageSizeOptions: [props.pageSize],
                loadingType: 'linear',
                debounceInterval: 500,
                tableLayout: 'auto',
                headerStyle: {
                    fontWeight: 600, fontSize: '1.1em',
                    borderBottom: '1px solid rgb(224 224 224)',
                },
                searchFieldAlignment: 'left',
                searchFieldVariant: 'standard',
                searchFieldStyle: { padding: '10px', width: '100%' },
                toolbarButtonAlignment: undefined,
                search: props.search || false,
                toolbar: props.toolbar || false,
                maxBodyHeight: 'fit-content',
                overflowY: 'hidden',
                padding: 'default',
                emptyRowsWhenPaging: false,
                actionsCellStyle: {
                    display: 'flex',
                    justifyContent: 'center',
                    padding: `${props.actions?.length ? 10 / props.actions.length : 0}px 20px`,
                    width: '100%',
                    marginBottom: '-1px',
                    borderBottom: 'none',
                },
                rowStyle: (_data: any, index: number, _level: number) => {
                    return index % 2 ? { backgroundColor: '#f3f9ff', padding: '0 20px' } : { padding: '0 20px' };
                },
                filterCellStyle: { border: 'none' },
                sorting: props.sorting,
                draggable: props.draggable,
            }}
        />
    );
}

export default observer(PaginatedTable);