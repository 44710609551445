import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useStore } from '../../stores/store';
import AvatarImage from '../image/AvatarImage';
import { SidebarData } from './SidebarData';
import SubMenu from './SubMenu';

//#region Styling

const StyledSidebar = styled.div<{ isCollapse: boolean }>`
    z-index: 10 !important;
    position: fixed;
    left: 0;
    bottom: 0;
    top: 50px;
    width: ${p => (p.isCollapse ? '80px' : '237px')};
    background: ${props => props.theme.palette.common.white};
    border-bottom-left-radius: 20px;
    transition: all 0.5s ease;
    overflow: auto;

    & ul {
        padding: 0 10px !important;
        margin-top: 10px !important;
    }

    ${p => (p.isCollapse && `

        & ul li a {
            display: flex !important;
            flex-direction: column !important;
            align-content: center !important;
            justify-content: center !important;
            height: 50px !important;
            margin-bottom: 15px !important;


            & span.icon {
                margin: 0 !important;
                text-align: center !important;
                font-size: 1.7rem !important;
            }

            & span.title {
                font-size: 0.6rem !important;
                text-align: center !important;
                display: none  !important;
            }
        }
    `)};
`;

const StyledProfile = styled.div<{ isCollapse: boolean }>`

        & .user-profile {
            cursor: 'default'
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            color: var(--primary-text-color);
            font-size: 1rem;
            padding: 1rem 0 1rem 1.5rem;
            font-weight: 600;
            align-content: flex-start;
            justify-content: center;
            margin-top: 40px;

            & .user-details {
                margin-top: 10px;

                & label {
                    font-size: 1.2em !important;
                }
            }

          ${p => (p.isCollapse && `
                padding: .4em;

                & .user-details {
                    font-size: 0.6rem;
                    text-align: center;
                    display: none;
                }
            
            `)};
        }
`;

const BottomNavigationMenu = styled.div<{ isCollapse: boolean }>`
    display: flex;
    position: fixed;
    bottom: 10px;
    align-items: flex-end;

    ${p => (p.isCollapse && `
                display: none !important;
            `)};
`;

const MenuWrapper = styled.div`
    padding: 1rem 0 1rem 1.5rem;
`;

//#endregion "Styling"

function useOuterClick(callback: any) {
    const callbackRef = useRef<any>(); // initialize mutable ref, which stores callback
    const innerRef = useRef<any>(); // returned to client, who marks "border" element

    // update cb on each render, so second useEffect has access to current value 
    useEffect(() => { callbackRef.current = callback; });

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick);

        function handleClick(ev: MouseEvent | TouchEvent) {
            if (innerRef.current && callbackRef.current &&
                !innerRef!.current!.contains!(ev.target)
            ) callbackRef!.current!(ev);

            return false;
        }
    }, []); // no dependencies -> stable click listener

    return innerRef; // convenience for client (doesn't need to init ref himself) 
}

const SidebarMenu = () => {
    const { commonStore: { isCollapseSideBar, setSubNav }, userStore: { displayName, user } } = useStore();

    const innerRef = useOuterClick(
        (ev: any) => {
            setSubNav(0);
        }
    );

    return (
        <StyledSidebar
            isCollapse={isCollapseSideBar}
            ref={innerRef}>
            <StyledProfile key={displayName} isCollapse={isCollapseSideBar}>
                <div className='user-profile' >
                    <div className='user-photo'>
                        <AvatarImage userId={user?.id!} hasPhoto={user?.hasProfilePicture!} style={{ width: '72px', height: '72px' }} size='tiny' avatar circular />
                    </div>
                    <div className='user-details'>
                        <label className="user-name">{displayName}</label>
                    </div>
                </div>
            </StyledProfile>
            <ul>
                {SidebarData.map((item, index) => {
                    return <SubMenu item={item} key={index} />;
                })}
            </ul>
        </StyledSidebar>
    );
}

export default observer(SidebarMenu);