import PostForm from "./PostForm";
import PostList from "./PostList";

const MainPanel = () => {
    return (
        <div className='main-panel'>
            <PostForm />
            <PostList />
        </div>
    )
}

export default MainPanel;
