import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Divider, Form, Grid, Header, Label, Tab } from 'semantic-ui-react';
import styled from 'styled-components';
import AvatarImage from '../../../app/common/image/AvatarImage';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { Employee } from '../../../app/models/employee';
import { useStore } from '../../../app/stores/store';

const StyledDisabledSelect = styled(Form.Select)`   
    opacity:1 !important;

    label{
        opacity:1 !important;
    }

    [role=listbox]{
       opacity:1 !important;

        .dropdown.icon{
            display: none;
        }
    }
`;


interface Props {
    employee: Employee;
}

const EmployeeGeneralDetails = ({ employee }: Props) => {
    const { commonStore } = useStore();
    const { loadGenders, getGenders, genders,
        loadCivilStatuses, getCivilStatuses, civilStatuses,
        loadNationalities, getNationalities, nationalities,
    } = commonStore;

    useEffect(() => {
        if (genders.length < 1)
            loadGenders();
        if (civilStatuses.length < 1)
            loadCivilStatuses();
        if (nationalities.length < 1)
            loadNationalities();
    }, [genders, civilStatuses, nationalities]);


    return (
        <Tab.Pane>
            <Form>
                <Grid stackable divided='vertically'>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <Header >Personal Information</Header>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Grid.Row style={{ display: 'flex', margin: '2em 0', justifyContent: 'left' }}>
                                <AvatarImage style={{ height: 80 }} userId={employee.id} hasPhoto={employee.hasProfilePicture} size='tiny' />
                            </Grid.Row>
                            <Form.Group>
                                <Form.Input value={employee?.firstName} readOnly name='firstName' label='First name' width={5} />
                                <Form.Input value={employee?.middleName} readOnly name='middleName' label='Middle Name' width={6} />
                                <Form.Input value={employee?.lastName} readOnly name='lastName' label='Last Name' width={5} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Input value={employee?.email} readOnly name='emailAddress' label='Email Address' width={8} />
                                <Form.Input value={employee?.mobileNumber} readOnly name='mobileNumber' label='Mobile No.' width={8} />
                            </Form.Group>
                            <Form.Group>
                                <StyledDisabledSelect options={getGenders} value={employee?.genderId} name='genderId' width={8} disabled label='Gender' />
                                <Form.Input type="date" value={employee.dateOfBirth != undefined ? moment(employee.dateOfBirth).format("YYYY-MM-DD") : ""} readOnly name='dateOfBirth' label='Date of Birth' width={8} />
                            </Form.Group>
                            <Form.Group>
                                <StyledDisabledSelect options={getNationalities} value={employee?.nationalityId} name='nationalityId' width={8} disabled label='Nationality' />
                                <StyledDisabledSelect options={getCivilStatuses} value={employee?.civilStatusId} name='civilStatusId' disabled label='Civil Status' width={8} />
                            </Form.Group>
                        </Grid.Column>
                        <Grid.Column width={3}>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        </Tab.Pane>
    );
}
export default observer(EmployeeGeneralDetails);