const FileHelper = class {
    fileToDataUri = (file: Blob) => new Promise<string | ArrayBuffer | null>((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            resolve(event.target!.result)
        };

        reader.onerror = () => {
            reject(null);
        };
        try {
            reader.readAsDataURL(file);

        } catch (e) {
            console.log(file.type);
        }
    })

    saveFile = (file: Blob, fileName: string) => {
        var a = document.createElement("a");
        document.body.appendChild(a);
        let url = window.URL.createObjectURL(file);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }

}

export default new FileHelper();