import { useStore } from "../../app/stores/store";
import FileHelper from '../../../src/app/helpers/fileHelper';
import { AiFillCloseSquare } from "react-icons/ai";

interface Props {
    id: string
    fileName: string,
    onClose?: (id: string, filename: string) => void;
}

const FileAttachment = (props: Props) => {
    const { mediaStore } = useStore();

    const handleClick = () => {
        mediaStore.getPostAttachment(props.id)
            .then(media => {
                if (media) {
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.href = media.url;
                    a.download = props.fileName;
                    a.click();
                }
            });
    }

    return (
        <div id={props.id} onClick={handleClick} className='file-attachment'>
            {props.onClose &&
                <AiFillCloseSquare
                    className='close-icon'
                    onClick={(e) => {
                        e.stopPropagation();
                        if (props.onClose) props.onClose(props.id, props.fileName);
                    }}
                />}
            <a>{props.fileName}</a>
        </div>
    );
}

export default FileAttachment;