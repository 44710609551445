import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Loader } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import Post from "./Post";

const PostList = () => {
    const { postStore, } = useStore();
    const { get, posts, isLoadingPosts } = postStore;

    useEffect(() => {
        if (posts.length === 0)
            get();

        const element = document.getElementById('app-wrapper');
        const eventHandler = e => {
            const bottom = element!.scrollHeight - element!.scrollTop === element!.clientHeight;

            if (bottom) {
                if (!isLoadingPosts) {
                    get();
                }
            }
        }

        element!.addEventListener('scroll', eventHandler)

        return () => {
            if (element) element.removeEventListener('scroll', eventHandler);
        };
    }, []);


    return (
        <div className='post-list'>
            {posts.map(post => {
                return (<Post key={post.id} post={post} />)
            })}
            {/*<Loader active={isLoadingPosts} inline='centered' />*/}
        </div>
    )
}

export default observer(PostList);