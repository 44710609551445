import { observer } from 'mobx-react-lite';
import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import styled from 'styled-components';

const StyledDiv = styled.div`
    width: 45px;
    height: 45px;
    display: flex;
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: white;

    animation: zoomInOverlay 0.6s forwards;

    @keyframes zoomInOverlay {
        from { opacity: 0; }
        to { opacity: 1; }
    }
        
`;

const AttachmentLoading = () => (
    <StyledDiv>
        <Loader active size='large'>Loading</Loader>
    </StyledDiv>
)

export default observer(AttachmentLoading);