import { observer } from 'mobx-react-lite';
import { MdOutlineClose } from 'react-icons/md';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { useStore } from '../../../app/stores/store';

const CloseIcon = styled(MdOutlineClose)`
    border-radius: 4px;
    color: #42526e;
    height: 32px;
    margin: 4px;
    overflow: hidden;
    padding: 4px;
    position: absolute;
    right: 0;
    top: 0;
    transition: background-color 85ms,color 85ms;
    width: 32px;
    z-index: 2;
    cursor:pointer;

    &:hover {
        background-color: #091e4214;
        color: #42526e;
    }
`;

const StyledModalContent = styled(Modal.Content)`
    && {
        border-radius: 16px;
        background-color: #f4f5f7 !important;
    }
`;

function DateModalContainer() {
    const { dateModalStore } = useStore()

    const handleCloseIconClick = () => {
        dateModalStore.closeModal();
    }

    return (
        <Modal
            open={dateModalStore.modal.open}
            onClose={dateModalStore.closeModal}
            centered={false}
            size='tiny'
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <CloseIcon onClick={handleCloseIconClick} />
            <StyledModalContent>
                {dateModalStore.modal.body}
            </StyledModalContent>
        </Modal>
    );
}

export default observer(DateModalContainer);