import { Popover } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Divider, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { CardChecklistFormValues } from '../../../app/models/cardChecklist';
import { useStore } from '../../../app/stores/store';
import Select, { MultiValue } from 'react-select';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';
import { observer } from 'mobx-react-lite';

//#region Styling
const Menu = styled(Button)`
    background:#091e4214 !important;
    margin-bottom: 8px !important;

    & i{
        background:none !important;
    }
`;

const BodyContainer = styled.div`
    padding: 12px 12px 12px 12px;
    display: flex;
    min-height: 340px;
    max-height: 700px;
    flex-direction: column;
    justify-content: space-between;
`;

const HeaderContainer = styled.div`
    height: 40px;
    margin-bottom: 8px;
    position: relative;
    text-align: center;
    padding:8px;
    & h4{
        margin:0 !important;
    }
`;

const ContentWrapper = styled.div`
        && {
        border-radius: 3px;
        box-shadow: 0 8px 16px -4px #091e4240, 0 0 0 1px #091e4214;
        width: 304px;
    }
`;
const PopupLable = styled.div`
    height: 40px;
    margin-top: 8px;
    position: relative;
    text-align: center;
    padding:8px;
    & h4{
        margin:0 !important;
    }
`;




//#endregion

interface Props {
    cardId: string;
    workspaceId: string
}

const selectStyles = {
    valueContainer: (provided, state) => ({
        ...provided,
        overflowY: 'auto',
        maxHeight: '92px',
        width: "300px",

    }),
 
    menuList: (provided, state) => ({
        ...provided,
        overflowY: 'auto',
        maxHeight: '180px'
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        display: 'none'
    }),

}

const MembersMenu = ({ cardId, workspaceId }: Props) => {
    const [selectedOption, setSelectedOption] = useState<MultiValue<{ value: string }>>([]);
    const { cardMemberStore, popOverStore } = useStore();
    const { memberOptionValues, addMultiple, isLoading, isOpenPopOver, setIsOpenPopOver } = cardMemberStore;
    const buttonRef = useRef(null);

    const handleClick = (e: MouseEvent) => {
        setIsOpenPopOver(true);
    
    };
    const handleClose = () => {
        setIsOpenPopOver(false); setSelectedOption([]) };

    const handleSubmit = async (values: MultiValue<{ value: string; }>, { setErrors, resetForm, setSubmitting }) => {
        let userIds = values.map(v => v.value);
        await addMultiple(cardId, userIds)
            .then(() => {
                popOverStore.closePopover();
                toast.success('Member/s was added successfully.');
                handleClose();
            }, reason => {
                console.log(reason);
            });
        setSubmitting(false);
    }
    const ButtonContainer = styled.div`
    && {
       
        flex-direction: row;
        justify-content: flex-start;
    }
`;

    useEffect(() => {
        cardMemberStore.getAllWorkSpaceUsers(cardId, workspaceId);
    }, [cardId, workspaceId, isOpenPopOver])

    return (
        <>
            <Menu
                icon='user outline'
                labelPosition='left'
                content='Members'
                onClick={handleClick}
                ref={buttonRef}
                fluid />
            <Popover
                id='popover-members'
                open={isOpenPopOver}
                anchorEl={() => {
                    let element = ReactDOM.findDOMNode(buttonRef.current);
                    return element as Element;
                }}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                elevation={1}
                PaperProps={{
                    style: { width: '18%' },
                }}
            >
                <PopupLable><h4>Add Members</h4></PopupLable>
                <Formik
                    enableReinitialize
                    initialValues={selectedOption}
                    onSubmit={handleSubmit}
                    validationSchema={Yup.object({
                        userIds: Yup.array().min(1, 'Please select atleast one.').required()
                    })}>
                    {
                        ({ handleSubmit, isSubmitting, dirty, isValid, values }) => (
                            <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                <BodyContainer>
                              
                                    <Select
                                        styles={selectStyles}
                                        name='userIds'
                                        defaultValue={selectedOption}
                                        onChange={setSelectedOption}
                                        options={memberOptionValues}
                                        isMulti={true}
                                        menuIsOpen={true}
                                        placeholder='Select Members...'
                                        noOptionsMessage={() => 'No user available'}
                                        isLoading={isLoading}
                                    />

                                    <ButtonContainer>
                                        <Button
                                            size='small'
                                            compact
                                            color='grey'
                                            onClick={() => handleClose()}
                                            style={{ float: 'right'}}
                                        >Cancel</Button>
                                        <Button
                                            type='submit'
                                            loading={isSubmitting}
                                            disabled={!isValid || isSubmitting || !selectedOption.length}
                                            size='small'
                                            compact
                                            color='blue'
                                            style={{ float: 'right' }}
                                        >Save</Button>
                                    </ButtonContainer>
                                </BodyContainer>
                            </Form>
                        )
                    }
                </Formik>
            </Popover>

        </>
    );
}

export default observer(MembersMenu);