import React, { useEffect, useState } from 'react';
import { Image, ImageProps } from 'semantic-ui-react';
import { useStore } from '../../stores/store';

interface Props extends ImageProps {
    userId: string;
    hasPhoto: boolean;
    clientId?: string;
}

const AvatarImage = ({ userId, hasPhoto, clientId, ...rest }: Props) => {
    const { mediaStore } = useStore();
    const [dataUri, setDataUri] = useState('');
    useEffect(() => {
        let unmounted = false;

        if (hasPhoto) {
            try {
                if (clientId) {
                    mediaStore.getClientProfilePicture(userId, clientId).then(v => {
                        if (v != undefined && !unmounted)
                            setDataUri(v.url)
                    });
                }
                else {
                    mediaStore.getProfilePicture(userId).then(v => {
                        if (v != undefined && !unmounted)
                            setDataUri(v.url)
                    });
                }

            } catch (e) {
                if (!unmounted)
                    setDataUri('/assets/default-profile-picture.svg');
            }
        }

        return () => { unmounted = true };
    }, [])

    return (
        <Image
            {...rest}
            src={dataUri || '/assets/default-profile-picture.svg'}
            avatar />
    );
}

export default AvatarImage;