import { runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agents";
import { ChatAttachmentFormValues } from "../models/chatAttachment";
import { store } from "./store";

export default class ChatAttachmentStore {
    uploading: boolean = false;
    selecting: boolean = false;
    IsAttachmentClicked: boolean = false;

    add = async (file: File[], message: string) => {
        this.uploading = true;
        try {
            const commentId = await agent.ChatAttachments.add(file, message);
            if (commentId) {
                //store.boardStore.hubConnection!.invoke('LoadComments', cardId);
                runInAction(() => this.uploading = false);
                return commentId
            } else {
                return Promise.reject();
            }
        } catch (error) {
            console.log(error);
            runInAction(() => this.uploading = false);
            return Promise.reject();
        }
    }

    updateComment = async (cardId: string, commentId: string, file: File[], message: string, clientId: string) => {
        this.uploading = true;
        try {
            const response = await agent.ChatAttachments.update(cardId, commentId, file, message, clientId);
            if (response) {
                store.boardStore.hubConnection!.invoke('LoadComments', cardId);
                runInAction(() => this.uploading = false);
                return response
            } else {
                return Promise.reject();
            }
        } catch (error) {
            console.log(error);
            runInAction(() => this.uploading = false);
            return Promise.reject();
        }
    }

    getBlob = async (attachmentId: string) => {
        if (this.IsAttachmentClicked) {
            try {
                return await agent.ChatAttachments.getAttachment(attachmentId, {
                    responseType: 'blob'
                });
            } catch (e) {
                console.log(e);
            }
        }
        
    }
    getImageBlob = async (attachmentId: string) => {
            try {
                return await agent.ChatAttachments.getAttachment(attachmentId, {
                    responseType: 'blob'
                });
            } catch (e) {
                console.log(e);
            }
    }

    updateFileName = async (fileName: string, id: string) => {
        try {
            store.boardStore.hubConnection?.invoke('UpdateChatAttachmentFileName', { fileName: fileName, id: id });
        } catch (e) {
            console.log(e);
        }
    }

    deleteAttachment = async (attachmentId: string) => {
        try {
            store.boardStore.hubConnection?.invoke('DeleteChatAttachment', attachmentId);
            runInAction(() => toast.success('Attachment was deleted successfully.'));
        } catch (e) {
            console.log(e);
        }
    }

    setIsAttachmentClick = (val: boolean) => {
        this.IsAttachmentClicked = val;
    }
}
