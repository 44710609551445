import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Comment as SemanticComment, Dropdown } from 'semantic-ui-react';
import Swal from 'sweetalert2';
import { PostComment } from '../../app/models/post';
import { useStore } from '../../app/stores/store';
import CommentForm from './CommentForm';

interface Props {
    comment: PostComment,
    onSubmit?: (id: string, message: string) => void;
    onDelete?: (id: string) => void;
    isEditing?: boolean
    onEditing?: (isEditing: boolean) => void
}

const Comment = (props: Props) => {
    const { mediaStore } = useStore();
    const [uri, setUri] = useState('/assets/default-profile-picture.svg');
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        mediaStore.getProfilePicture(props.comment.user.id).then(media => {
            if (media) {
                setUri(media.url);
            }
        })
    }, []);

    const HandleClickEdit = (state: boolean) => {
        if (props.onEditing) props.onEditing(state);
    }

    const handleSubmit = (message) => {
        setLoading(true);

        if (message) {
            if (props.onSubmit) {
                props.onSubmit(
                    props.comment.id,
                    message
                );

                setLoading(false);
            }
        }
        else {
            toast.error('Comment is required.');
            setLoading(false);
        }
    }

    const handleDelete = () => {
        Swal.fire({
            title: 'Delete Confirmation',
            text: 'Are you sure you want to delete this comment?',
            icon: 'question',
            confirmButtonColor: '#1592ff',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            showCancelButton: true,
        }).then(({ isConfirmed }) => {
            if (isConfirmed && props.onDelete) {
                props.onDelete(props.comment.id);
            }
        });
    }

    const parseMessage = (message) => {
        let parsed = parse(message);

        return (
            (typeof parsed === "string") ?
                <div dangerouslySetInnerHTML={{
                    __html: parsed as string
                }}>
                </div> :
                <div>
                    {parsed}
                </div>
        )
    }

    return (
        <SemanticComment.Group>
            <SemanticComment>
                <SemanticComment.Avatar as='a' src={uri} />
                <SemanticComment.Content>
                    <SemanticComment.Author>{props.comment.user.name}</SemanticComment.Author>
                    {!props.isEditing ?
                        <>
                            <SemanticComment.Text>
                                {parseMessage(props.comment.message)}
                            </SemanticComment.Text>
                            {props.comment.owned &&
                                <div className='comment-menu'>
                                    <Dropdown
                                        onBlur={() => setOpen(false)}
                                        onClick={() => setOpen(!open)}
                                        open={open}
                                        basic
                                        pointing='top right'
                                        floating
                                        inline
                                        icon='ellipsis horizontal'
                                    >
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => HandleClickEdit(true)} icon='edit' text='Edit Post' />
                                            <Dropdown.Item onClick={handleDelete} icon='trash alternate' text='Delete Post' />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            }
                        </> :
                        <CommentForm
                            message={props.comment.message}
                            isAvatarHidden={true}
                            onClickClose={() => HandleClickEdit(false)}
                            onSubmit={handleSubmit}
                            loading={loading}
                        />
                    }
                </SemanticComment.Content>
            </SemanticComment>
        </SemanticComment.Group>
    )
}

export default Comment;