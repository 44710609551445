import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Grid, Header, Modal, Tab } from 'semantic-ui-react';
import styled from 'styled-components';
import PaginatedTable from '../../../app/common/table/PaginatedTable';
import DateHelper from '../../../app/helpers/dateHelper';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { FilterValues } from '../../../app/models/common/filterValues';
import { DailyReport } from '../../../app/models/employeeDailyReport';
import { TimeRecord } from '../../../app/models/timeRecord';
import { useStore } from '../../../app/stores/store';

const dateHelper = new DateHelper();

const StyledTable = styled(PaginatedTable)`

`

interface Props {
    employeeId: string;
}

const EmployeeDtr = ({ employeeId }: Props) => {
    const { employeeStore, modalStore } = useStore();
    const { isTimeRecordsLoaded, timeRecords, timeRecordTotal, loadEmployeeDTR } = employeeStore;

    const [filterValues, setFilterValues] = useState<FilterValues>({
        id: employeeId,
        pageSize: 20,
        pageNumber: 1,
        searchKeyword: ""
    });

    const handleOnChangePage = (page: number) => {
        setFilterValues({ ...filterValues, pageNumber: page })
    }

    useEffect(() => {
        loadEmployeeDTR(filterValues);
    }, [filterValues, loadEmployeeDTR])

    return (
        <Tab.Pane>
            <Grid divided='vertically'>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header>Daily Time Record</Header>
                        <PaginatedTable<TimeRecord>
                            columns={[
                                { title: 'Time In', render: (data: TimeRecord) => data.timeInDate ? dateHelper.formatShortMonthDateTime(data.timeInDate.toString()) : '' },
                                { title: 'Time Out', render: (data: TimeRecord) => data.timeOutDate ? dateHelper.formatShortMonthDateTime(data.timeOutDate.toString()) : '' },
                                {
                                    title: 'Total Time',
                                    render: (data: TimeRecord) => {
                                        if (data.timeInDate && data.timeOutDate) {
                                            let timeIn = moment(data.timeInDate);
                                            let timeOut = moment(data.timeOutDate);
                                            let difference = timeOut.diff(timeIn);
                                            let duration = moment.duration(difference);
                                            let hourDuration = Math.floor(duration.asHours());
                                            let minSecDuration = moment.utc(difference).format('mm:ss');

                                            return `${hourDuration}:${minSecDuration}`;
                                        }
                                        else {
                                            return '';
                                        }
                                    }
                                }]}
                            data={timeRecords!}
                            total={timeRecordTotal}
                            onChangePage={handleOnChangePage}
                            pageSize={20}
                            pageNumber={filterValues.pageNumber}
                            isLoading={!isTimeRecordsLoaded}
                            style={{ boxShadow: 'none', padding:0, marginTop:'30px' }}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Tab.Pane>
    );
}


export default observer(EmployeeDtr);