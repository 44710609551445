import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { useStore } from '../../stores/store';
import { useHistory, useLocation } from 'react-router-dom';

const CloseIcon = styled(MdOutlineClose)`
    border-radius: 4px;
    color: #42526e;
    height: 32px;
    margin: 4px;
    overflow: hidden;
    padding: 4px;
    position: absolute;
    right: 0;
    top: 0;
    transition: background-color 85ms,color 85ms;
    width: 32px;
    z-index: 2;
    cursor:pointer;

    &:hover {
        background-color: #091e4214;
        color: #42526e;
    }
`;

const StyledModalContent = styled(Modal.Content)`
    && {
        border-radius: 16px;
        background-color: #f4f5f7 !important;
    }
`;

function ModalContainer() {
    const { modalStore } = useStore();
    const history = useHistory()

    const handleClick = () => {
        if (modalStore.modal.isCard) {
            let url = history.location.pathname
            history.goBack();
        }
        modalStore.closeModal();
    }

    return (
        <Modal
            open={modalStore.modal.open}
            onClose={modalStore.closeModal}
            centered={false}
            size='small'
            closeOnEscape={modalStore.modal.closeOnEscape}
            closeOnDimmerClick={modalStore.modal.closeOnDimmerClick}
        >
            <CloseIcon onClick={handleClick} />
            <StyledModalContent>
                {modalStore.modal.body}
            </StyledModalContent>
        </Modal>
    );
}

export default observer(ModalContainer);