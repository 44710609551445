import { makeAutoObservable, reaction, runInAction } from 'mobx';

interface PopOver {
    body: JSX.Element | null;
    anchorEl: any;
}

export default class PopoverStore {
    popOver: PopOver = {
        body: null,
        anchorEl: null
    }

    isOpen: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    openPopover = (content: JSX.Element, target: EventTarget) => {
        this.popOver.anchorEl = target;
        this.popOver.body = content;
        runInAction(() => this.isOpen = Boolean(target))
    }

    closePopover = () => {
        this.popOver.anchorEl = null;
        this.popOver.body = null;
        runInAction(() => this.isOpen = false)
    }
}