import { WorkSpaceType } from "./workSpaceType";

export interface WorkSpace {
    id: string;
    name: string;
    description: string;
    clientId?: string;
}

export class WorkSpaceFormValues {
    id?: string = undefined;
    name: string = '';
    description: string = '';

    constructor(workSpace?: WorkSpace) {
        if (workSpace) {
            this.id = workSpace.id;
            this.name = workSpace.name;
            this.description = workSpace.description;
        }
    }
}