
import { FormikHelpers } from 'formik';
import moment from 'moment';
import React, { MouseEvent } from 'react';
import { useLightbox } from 'simple-react-lightbox';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AttachmentThumbnail from '../../../app/common/image/AttachmentThumbnail';
import { CardAttachment } from '../../../app/models/cardAttachment';
import { useStore } from '../../../app/stores/store';
import AttachmentEditForm from './AttachmentEditForm';


//#region Styling

const ComponentWrapper = styled.div`
    && {
        margin:0 0 24px 32px;
        display:flex
    }
    &:hover{
        background-color: #eee;
        cursor: pointer;
    }
`;

const ActionWrapper = styled.div`
    && {
        padding: 5px 10px;
        width: 100%;
        overflow: hidden;
    }
`;

const ExtensionThumbnail = styled.span`
    && {
    color: #5e6c84;
    display: block;
    font-size: 18px;
    font-weight: 700;
    line-height: 80px;
    text-align: center;
    text-decoration: none;
    background-color: #091e420a;
    border-radius: 6px;
    height: 80px;
    width:120px;
    }
`;

const FileName = styled.p`
    && {
        margin:0;
        font-weight:600;
        white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
    }
`;

const ActionLink = styled.span`
    &:hover {
        text-decoration: underline;
    }
`;
//#endregion

const _swal = withReactContent(Swal);

const isImage = (fileName) => {
    return fileName.match(/.(jpg|jpeg|png)$/i);
}

const getName = (fileName) => {
    let lastIndex = fileName.lastIndexOf(".");
    return fileName.substring(0, lastIndex);
}

interface Props {
    attachment: CardAttachment;
    index?: number;
}

//TODO: NEED OTHER WAY TO DOWNLOAD
const saveFile = (data, fileName) => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = fileName;
    a.click();
    setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }, 0)
}

const Attachment = ({ attachment, index }: Props) => {
    const { cardAttachmentStore, popOverStore } = useStore();
    const { deleteAttachment, updateFileName } = cardAttachmentStore;

    const { openLightbox, closeLightbox } = useLightbox();

    const getFileExtension = () => {
        let lastIndex = attachment.fileName.lastIndexOf(".");
        return attachment.fileName.substring(lastIndex + 1);
    }

    const handleDelete = (attachmentId: string) => {
        _swal.fire({
            title: 'Delete?',
            text: "Are you sure you want to delete this Attachment?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                _swal.disableButtons();
                deleteAttachment(attachment.id);
            }
        });
    }

    const handleAttachmentUpdateSubmit = (values: { fileName: string },
        { resetForm, setSubmitting }: FormikHelpers<{ fileName: string }>) => {
        updateFileName(values.fileName + '.' + attachment.fileName.split('.').pop(), attachment.id)
            .then(
                result => {
                    setSubmitting(false);
                    popOverStore.closePopover();
                },
                reason => {
                    console.log(reason)
                    setSubmitting(false);
                }
            );
    }

    const handleEdit = (e: MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation();
        popOverStore.openPopover((
            <AttachmentEditForm
                placeholder='Enter File Name'
                rows={2}
                label='File Name'
                isResizable={true}
                initialValue={getName(attachment.fileName)}
                handleSubmit={handleAttachmentUpdateSubmit}
                name='fileName'
        />), e.currentTarget);
    }

    const handleOnClickAttachment = (event: MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) return;

        if (isImage(attachment.fileName)) {
            openLightbox(index!)
        }
        else {
            cardAttachmentStore.getBlob(attachment.id).then(v => {
                if (v != undefined) {
                    saveFile(v, attachment.fileName);
                }
            })
        }
    }

    return (
        <ComponentWrapper onClick={handleOnClickAttachment}>
            <div>
                {isImage(attachment.fileName) ?
                    (<AttachmentThumbnail attachmentId={attachment.id} />) :
                    (<ExtensionThumbnail>{getFileExtension()}</ExtensionThumbnail>)
                }
            </div>
            <ActionWrapper>
                <FileName>{attachment.fileName}</FileName>
                <p style={{ margin: '0 0 10px' }}>{'Added ' + moment.utc(attachment.date).fromNow()}</p>
                <div>
                    <ActionLink onClick={(e) => { e.stopPropagation(); handleDelete(attachment.id); }}>Delete</ActionLink> - <ActionLink onClick={handleEdit}>Edit</ActionLink>
                </div>
            </ActionWrapper>
        </ComponentWrapper>
    );
}

export default Attachment;