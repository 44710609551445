import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agents";
import { FilterValues } from "../models/common/filterValues";
import { TimeAdjustmentModel } from "../models/timeAdjustment";
import { store } from "./store";
import { toast } from 'react-toastify';
import saveAs from "file-saver";

export default class TimeAdjustmentStore {
    isInitialLoading: boolean = false;
    timeAdjustments: TimeAdjustmentModel[] = [];
    timeAdjustmentTotal = 0;
    isTimeAdjustmentListLoading: boolean = false;
    isTimeAdjustmentDetailsLoading: boolean = false;

    timeAdjustment: TimeAdjustmentModel | undefined;

    constructor() {
        makeAutoObservable(this);
    }

    filterTimeAdjustment = async (filters: FilterValues, isInitial?: boolean) => {
        if (isInitial) this.isInitialLoading = true;
        this.isTimeAdjustmentListLoading = true;

        try {
            const { total, timeAdjustments } = await agent.TimeAdjustment.filter(
                filters.searchKeyword ?? "",
                filters.pageNumber,
                filters.pageSize,
            );

            runInAction(() => {
                this.timeAdjustments = timeAdjustments;
                this.timeAdjustmentTotal = total;
                this.isTimeAdjustmentListLoading = false;
                this.isInitialLoading = false;

            });
        } catch (e) {
            console.log(e);
            this.isTimeAdjustmentListLoading = false;
            this.isInitialLoading = false;
        }
    }

    loadTimeAdjustment = async (id: string) => {
        this.isTimeAdjustmentDetailsLoading = true;

        try {
            let timeAdjustment = this.timeAdjustments.find(t => t.id = id);

            if (!timeAdjustment) 
                timeAdjustment = await agent.TimeAdjustment.getById(id)
            

            runInAction(() => {
                this.timeAdjustment = timeAdjustment;
                this.isTimeAdjustmentDetailsLoading = false;

            })
        } catch (e) {
            console.log(e);
            this.isTimeAdjustmentDetailsLoading = false;
        }
    }

    approve = async (id: string, filters: FilterValues) => {
        this.isTimeAdjustmentListLoading = true;

        try {
            await agent.TimeAdjustment.approve(id, store.userStore.displayName);
            await this.filterTimeAdjustment(filters);
            toast.success('Time Adjustment approved.');
            this.isTimeAdjustmentListLoading = false;

        } catch (e) {
            console.log(e);
            this.isTimeAdjustmentListLoading = false;

        }
    }

    reject = async (id: string, filters: FilterValues) => {
        this.isTimeAdjustmentListLoading = true;

        try {
            await agent.TimeAdjustment.reject(id, store.userStore.displayName);
            await this.filterTimeAdjustment(filters);
            toast.success('Time Adjustment rejected.');
            this.isTimeAdjustmentListLoading = false;

        } catch (e) {
            console.log(e);
            this.isTimeAdjustmentListLoading = false;

        }
    }
}