import React, { ChangeEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Header, Icon, Input } from 'semantic-ui-react';
import styled from 'styled-components';
import { history } from '../../../index';


const ContainerStyled = styled.div<{ searchLabel?: string }>`
    width:100%;
    display: flex;
    flex-direction: ${p => p.searchLabel ? 'column' : 'row'};
    align-content: center;
    align-items: flex-start;
    justify-content: flex-start;
`;

const InputContainer = styled.div`
    width:100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: baseline;
    justify-content: flex-start;
`;

const SearchStyled = styled(Input)`
    flex-grow:1;
    input {
             border:none !important;
        }
`;

const StyleIcon = styled(Icon)`
    font-size: 1.3em !important;
    color: #8d9eb4 !important;
`;


interface Props {
    toolbarProps: any,
    searchLabel?: string;
}

const SearchToolbar = ({ toolbarProps, searchLabel }: Props) => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const search = params.get("search");

    const [query, setQuery] = useState(search ?? toolbarProps.searchText);

    const setSearchParams = (searchText: string) => {
        params.set('search', searchText);

        history.replace({
            search: params.toString(),
        });
    }

    const handleClear = () => {
        setSearchParams('');
        toolbarProps.dataManager.searchText = '';
        setQuery('');
        toolbarProps.onSearchChanged('');
    }

    const handleChange = (e: ChangeEvent) => {
        let element = e.target as HTMLInputElement;
        const value = element.value ?? '';
        setQuery(value);
    }

    return (
        <ContainerStyled searchLabel={searchLabel}>
            {searchLabel && (<label style={{ textAlign: 'left', color: '#6a7e99', fontSize: '14px', marginBottom: '4px' }}>
                {searchLabel}
            </label>)}
            <InputContainer>
                <StyleIcon name='search' />
                <SearchStyled
                    value={query}
                    onChange={handleChange}
                    onKeyPress={(ev: React.KeyboardEvent) => {
                        if (ev.key === 'Enter') {
                            let element = ev.target as HTMLInputElement;
                            const value = element.value ?? '';
                            toolbarProps.dataManager.searchText = value;
                            toolbarProps.onSearchChanged(value ? value : '');
                            setSearchParams(value);
                            ev.preventDefault();
                        }
                    }}

                    icon={<Icon name='close' link onClick={handleClear} style={{ display: query.length ? 'inline-block' : 'none' }} />}
                    placeholder='Search name...'
                />
            </InputContainer>
        </ContainerStyled>

    );
}

export default SearchToolbar;