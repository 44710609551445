import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import agent from '../api/agents';
import { Option } from '../models/option'
import { store } from './store';
import { ScheduleNotification } from '../models/scheduleNotification';

export default class CommonStore {
    hubConnection: HubConnection | null = null;
    scheduleURL = process.env.REACT_APP_SCHEDULE_URL;
    connectionId = "";
    appLoaded = false;
    isCollapseSideBar = false;
    subNavId: number | undefined = 0;
    genders: Option[] = [];
    DtrStatuses: Option[] = [];
    nationalities: Option[] = [];
    civilStatuses: Option[] = [];
    departments: Option[] = [];
    notification: ScheduleNotification | undefined;
    isNotificationPage = false;
    currentDate = new Date();

    constructor() {
        makeAutoObservable(this);
    }

    get getGenders() {
        if (this.genders.length != 0)
            return this.genders.map(g => { return { text: g.name, value: g.id } })
        return this.genders;
    }

    get getDtrStatuses() {
        return this.DtrStatuses.map(g => { return { text: g.name, value: g.id  } })
    }

    get getNationalities() {
        if (this.nationalities.length != 0)
            return this.nationalities.map(g => { return { text: g.name, value: g.id } })
        return this.nationalities;
    }

    get getCivilStatuses() {
        if (this.civilStatuses.length != 0)
            return this.civilStatuses.map(g => { return { text: g.name, value: g.id } })
        return this.civilStatuses;
    }

    get getDepartments() {
        if (this.departments.length > 0)
            return this.departments.map(g => { return { text: g.name, value: g.id } })
        return [];
    }

    setCurrentDate = (date: Date) => {
        this.currentDate = date;
    }

    createHubConnection = () => {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(this.scheduleURL + '', {
                accessTokenFactory: () => store.authStore.token!,
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.None) //change the log level for debugging
            .build();

        this.hubConnection?.start().catch(e => console.log(e));

        this.hubConnection.on('ReceiveNotification', (notification) => {
            runInAction(() => {
                this.notification = notification;
            })
        })
    }

    stopHubConnection = () => {
        this.hubConnection?.stop().catch((error) => console.log("Error stopping connection: ", error));
    };

    loadGenders = async () => {
        try {
            const genders = await agent.Common.loadGenders();
            runInAction(() => {
                this.genders = genders;
            })
        } catch (e) {
            console.log(e);
        }
    }

    loadDtrStatuses = async () => {
        try {
            const dtrStatuses = await agent.Common.loadDtrStatuses();
            runInAction(() => {
                this.DtrStatuses = dtrStatuses;
            })
        } catch (e) {
            console.log(e);
        }
    }

    loadNationalities = async () => {
        try {
            const nationalities = await agent.Common.loadNationalities();
            runInAction(() => {
                this.nationalities = nationalities;
            })
        } catch (e) {
            console.log(e);
        }
    }

    loadCivilStatuses = async () => {
        try {
            const civilStatuses = await agent.Common.loadCivilStatuses();
            runInAction(() => {
                this.civilStatuses = civilStatuses;
            })
        } catch (e) {
            console.log(e);
        }
    }

    loadDepartments = async () => {
        try {
            const departments = await agent.Common.loadDepartments();
            runInAction(() => {
                this.departments = departments;
            })
        } catch (e) {
            console.log(e);
        }
    }


    setAppLoaded = () => {
        this.appLoaded = true;
    }
    setIsNotificationPage = (value) => {
        this.isNotificationPage = value;
    }

    toggleSidebar = () => {
        this.isCollapseSideBar = !this.isCollapseSideBar;
    }

    setSideBar = (state: boolean) => this.isCollapseSideBar = state;

    setSubNav = (id?: number) => {
        if (id == this.subNavId || id === 0)
            this.subNavId = 0;
        else
            this.subNavId = id;
    };
}