import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Grid, Icon, Item, Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useStore } from '../../stores/store';
import ReceiverMultiSelect from './ReceiverMultiSelect';

const swal = withReactContent(Swal);
//#region Styling

const ItemContent = styled(Item.Content)`
    &&{
        margin: 0 0 0 0em !important;
        max-width: fit-content;
        min-width: 100%; 
  
  
    }
`;

const StyledItem = styled(Item)`
    &&{
        margin:5px 0 !important;
    }
`;
const WarningContainer = styled.div`
    &&{
       border: 3px solid #f8bb86;
        border-radius: 50%;
        width: 6em;
        height: 6em;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
const ItemContainer = styled.div`
    &&{
        width: 100%;
        padding: 15px;
        display: flex;
        justify-content: center;
        padding:.5em;
    }
`;
const CenterDiv = styled.div`
    &&{
    display: flex;
    justify-content: center;
    flex-flow: wrap;
    }
`;
const selectStyles = {
    container: (provided, state) => ({
        ...provided,
        margin: '5px',
        width: '97%',
        zIndex: '9999'
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        overflowY: 'auto',
        maxHeight: '100px'
    }),
    menuList: (provided, state) => ({
        ...provided,
        overflowY: 'auto',
        maxHeight: '190px'
    }),
}
//#endregion

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
interface Groupchat {
    userIds: { value: string, label: string }[]
}

const files: File[] = [];
const data = {
    content: "",
    ids: Array<string>()
}

const LeaveGroupModal = ({ open, setOpen }: Props) => {
    const { chatStore, employeeStore, messageStore, conversationStore, userStore } = useStore();
    const { isEmployeesByConversationLoading, loadEmployeesByConversationId, employeessOptionValues,
        getUsersByConverSation, employeesIncludedOnConversation, addUsersToConversation, deleteMember } = chatStore;
    const { addMessageWithAttachment } = messageStore;
    const { user } = userStore;
    const { selectedConversation } = conversationStore;
    const formikRef = useRef<FormikProps<Groupchat>>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const handleSubmit = () => {
        setIsSaving(true)
        let usersToUpdate = employeesIncludedOnConversation.filter(u => {
            if (u.id != user?.id!) {
                return u;
            }
        }).map(u => u.id);


        deleteMember(selectedConversation?.id!, user?.id!).then(
            () => {
                data.content = `${user?.firstName + " " + user?.lastName} has left this conversation.[[SystemGenerated]]`;
                addMessageWithAttachment(
                    {
                        content: data.content,
                        conversationId: selectedConversation?.id!,
                        receiversId: [],
                        connectionId: chatStore.connectionId,
                        files: files
                    }
                ).then(() => {
                    chatStore.hubConnection?.invoke('OnRemoveUserToTheConversation', user?.id!, selectedConversation?.id!);
                    chatStore.hubConnection?.invoke('OnUpdateNumberOfUsersToTheConversation', usersToUpdate, selectedConversation?.id);

                });
                setOpen(false)
                setIsSaving(false)
            })

    }

    const handleOnCLose = () => {
        setOpen(false);

    }

    return (
        <Modal
            size="tiny"
            open={open}
            onClose={() => handleOnCLose}
            closeOnDimmerClick={false}
        >
            <Modal.Content image scrolling style={{ overflowX: 'hidden' }} >
                <Modal.Description style={{ maxHeight: '20em', flex: 'auto' }} >
                    <CenterDiv>
                        <WarningContainer>
                            <span style={{ color: "#f8bb86", fontSize: '3em' }} >!</span>
                        </WarningContainer>
                        <ItemContainer>
                            <h2>Leave group?</h2>
                        </ItemContainer>
                        <ItemContainer>
                            <span style={{ fontSize: "1.3em" }}>Are you sure you want to leave this conversation?</span>
                        </ItemContainer>
                        <ItemContainer>
                            <Button
                                disabled={isSaving} 
                                content="Confirm"
                                primary
                                type="submit"
                                onClick={ handleSubmit }

                            />
                            <Button
                                disabled={isSaving}
                                color="red" onClick={() => setOpen(false)}>
                                Cancel
                            </Button>
                        </ItemContainer>
                    </CenterDiv>
                </Modal.Description>
             
            </Modal.Content>

        
        </Modal>
    );
}

export default observer(LeaveGroupModal);