import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agents';
import { FilterValues } from '../models/common/filterValues';
import { TimeRecord } from '../models/timeRecord';

export default class TimeRecordStore {
    timeRecords: TimeRecord[] = [];
    timeRecordsTotal: number = 0;
    isTimeRecordsLoading: boolean = false;
    isInitialLoading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    filterTimeRecords = async (filters: FilterValues, isInitial?: boolean) => {
        if (isInitial) this.isInitialLoading = true;
        this.isTimeRecordsLoading = true;

        try {
            const { total, data } = await agent.DtrSummary.filter(filters);
            runInAction(() => {
                this.timeRecords = data;
                this.timeRecordsTotal = total;
                this.isTimeRecordsLoading = false;
                this.isInitialLoading = false;

            });
        } catch (e) {
            console.log(e);
            this.isTimeRecordsLoading = false;
            this.isInitialLoading = false;
        }
    }
}