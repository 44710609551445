import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import agent from '../api/agents';
import { FilterValues } from '../models/common/filterValues';
import { WorkSpaceUser, WorkSpaceUserFormValues } from '../models/workSpaceUser';
import { history } from '../../index';
import { Employee } from '../models/employee';
import { OptionSelect } from '../models/option';
import { User } from '../models/user';
import hi from 'date-fns/esm/locale/hi/index';

export default class WorkSpaceUserStore {
    workSpaceUsers: WorkSpaceUser[] = [];
    users: User[] = [];
    workSpaceUsersTotal: number = 0;
    isListLoading: boolean = false;
    isLoading: boolean = false;

    clientEmployees: Employee[] = [];
    nonMembers: Employee[] = [];
    isLoadingClientEmployees: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    get memberOptionValues(): OptionSelect[] {
        if (this.nonMembers.length) {
            return this.nonMembers
                .map(e => { return { value: e.id, label: e.name } as OptionSelect });
        } else {
            return [];
        }
    }

    filterWorkSpaceUsers = async (filters: FilterValues) => {
        this.setLoading(true);

        try {
            const { total, data } = await agent.WorkSpaceUsers.filter(filters);
            runInAction(() => {
                this.users = data.map(w => {return w.user});
                this.workSpaceUsersTotal = total;
            });
            this.setLoading(false);
        
        } catch (e) {
            console.log(e);
            this.setLoading(false);
        }
    }

    loadWorkSpaceUser = async (id: string) => {
        this.isLoading = true;

        try {
            let workSpaceUser = await agent.WorkSpaceUsers.get(id);
            this.isLoading = false;
            return workSpaceUser;
        } catch (e) {
            throw e;
        }
    }

    add = async (workSpaceUser: WorkSpaceUserFormValues) => {
        try {
            const workSpaceUserId = await agent.WorkSpaceUsers.add(workSpaceUser);
            runInAction(() => {
                if (workSpaceUserId) {
                    toast.success('Workspace was created successfully.');

                    return true;
                }
                return false;
            });
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    addMultiple = async (workSpaceId: string, userIds: string[]) => {
        try {
            await agent.WorkSpaceUsers.addMultiple(workSpaceId, userIds);
            runInAction(() => {
                return Promise.resolve;
            });
        } catch (e) {
            return Promise.reject(e);
        }
    }

    update = async (workSpaceUser: WorkSpaceUserFormValues) => {
        try {
            const workSpaceUserId = await agent.WorkSpaceUsers.update(workSpaceUser);
            runInAction(() => {
                if (workSpaceUserId) {
                    toast.success('Workspace was updated successfully.');
                    history.goBack();
                    return true;
                }
                return false;
            });
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    deleteWorkSpaceUser = async (memberId: string, workSpaceId: string, filters: FilterValues) => {
        try {
            await agent.WorkSpaceUsers.deleteUserWorkSpace(memberId, workSpaceId);
            this.filterWorkSpaceUsers(filters);
            runInAction(() => {
                toast.success('Member was removed successfully.');
            });
        } catch (e) {
            console.log(e);
        }
    }

    loadMembers = async (search: string) => {
        this.isLoadingClientEmployees = true;

        try {
            const { total, employees } = await agent.Employees.filterEmployeesSelectedDetails({
                searchKeyword: search,
                pageSize: 0,
                pageNumber: 0
            });
       
            runInAction(() => {
                employees.map(e => {
                    if (!this.clientEmployees.find(s => s.id == e.id)) {
                        this.clientEmployees.push(e);
                    }
                });
                this.isLoadingClientEmployees = false;
            });
        } catch (error) {
            this.isLoadingClientEmployees = false;
        }
    }
    loadNonMembers = async (workspaceId: string) => {
        this.isLoadingClientEmployees = true;

        try {
            const { total, employees } = await agent.Employees.getNonWorkspaceMembers(workspaceId);
            runInAction(() => {
                this.nonMembers=  employees;
                this.isLoadingClientEmployees = false;
            });
        } catch (error) {
            this.isLoadingClientEmployees = false;
        }
    }

    reset = () => {
        this.workSpaceUsers = [];
        this.workSpaceUsersTotal = 0;
        this.clientEmployees = [];
        this.isLoadingClientEmployees = false;
    }
    setLoading = (state: boolean) => {
        this.isListLoading = state;
    }
}