import { useEffect, useState } from 'react';
import { AiFillCloseSquare } from 'react-icons/ai';
import FileHelper from '../../../src/app/helpers/fileHelper';
import { useStore } from '../../app/stores/store';
import LoadingAttachment from './LoadingAttachment';

interface Props {
    id: string
    blob?: Blob
    fileName?: string,
    onClose?: (id: string, fileName: string) => void;
}

const VideoAttachment = ({ id, blob, onClose, fileName }: Props) => {
    const { mediaStore } = useStore();
    const [data, setData] = useState({
        uri: '',
        type: ''
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!blob) {
            mediaStore.getPostAttachment(id)
                .then(media => {
                    if (media) {
                        setData({
                            uri: media.url,
                            type: media.type ? media.type: ''
                        });
                        setLoading(false);
                    }
                });
        }
        else {
            FileHelper.fileToDataUri(blob).then(base64 => {
                setLoading(false);
                setData({
                    uri: base64 as string,
                    type: blob.type
                });
            })
        }
    }, [])

    if (loading) return <LoadingAttachment />

    return (
        <div className='post-attachment-image'>
            <video controls  >
                <source src={data.uri} type={data.type} />
            </video>
            {onClose &&
                <AiFillCloseSquare
                    className='close-icon'
                    onClick={() => {
                        if (onClose) onClose(id, fileName || "");
                    }}
                />}
        </div>
    )
}

export default VideoAttachment;