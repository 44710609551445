import { DatesSetArg, EventContentArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import './calendar.css';


interface Props {
    key: number;
    loading?: boolean;
    events: {
        title: string,
        date: Date,
        extendedProps?: {
            className: string
        }
    }[],
    onRender: (arg: DatesSetArg) => void,
    eventOrder?: any,
    initialDate?: Date;
}

const Calendar = (props: Props) => {

    return (
        <div className={`calendar ${props.loading ? "disabled" : ""}`}>
            <FullCalendar
                key={props.key}
                eventOrder={props.eventOrder}
                plugins={[dayGridPlugin]}
                initialView='dayGridMonth'
                events={props.events}
                datesSet={props.onRender}
                eventContent={renderEventContent}
                initialDate={props.initialDate}
            />
        </div>
    )
}

export default Calendar;

// a custom render function
const renderEventContent = (eventInfo: EventContentArg) => {
    const className = eventInfo.event.extendedProps.className;

    return (
        <div className={`custom-event ${className} no-click`}>
            {eventInfo.event.title}
        </div>
    )
}