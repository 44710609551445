import { makeAutoObservable, reaction, runInAction } from 'mobx';
import agent from '../api/agents';
import { FilterValues } from '../models/common/filterValues';
import { Conversation, PostConversation } from '../models/conversation';
import { store } from './store';

export default class ConversationStore {
    total: number = 0;
    conversations: Conversation[] = [];
    selectedConversation: Conversation | undefined;
    isLoadingConversation: boolean = false;
    searchQuery: string = '';

    pendingNotificationConversationIds: string[] = [];

    constructor() {
        makeAutoObservable(this);
        reaction(
            () => this.conversations,
            conversations => {
                if (conversations.length) {
                    let currentConversationId = store.messageStore.messages.length ? store.messageStore.messages[0].conversationId : '';

                    let unreadConversations = conversations.filter(c => (c.readReceiptUserIds && c.readReceiptUserIds.indexOf(store.userStore.user!.id) === -1) &&
                        c.id != currentConversationId);
                    if (unreadConversations) {
                        store.chatStore.unReadConversationIds = unreadConversations.map(c => c.id);
                    }
                    else {
                        store.chatStore.unReadConversationIds = [];
                    }

                    let pendingNotificationConversation = conversations.filter(c => (c.notifiedReceiptUserIds &&
                                                                                    c.notifiedReceiptUserIds.indexOf(store.userStore.user!.id) === -1) &&
                                                                                    c.id != currentConversationId);

                    if (pendingNotificationConversation) {

                        let ids = pendingNotificationConversation.map(c => c.id);
                        this.setPendingNotificationConversationIds(ids);

                    }
                    else {
                        this.setPendingNotificationConversationIds([]);
                    }
                }
                else {
                    store.chatStore.unReadConversationIds = [];
                    this.setPendingNotificationConversationIds([]);
                }
            }
        );
    }

    setPendingNotificationConversationIds = (ids: string[]) => {
        this.pendingNotificationConversationIds = ids;
    }

    getCoversationById = async (id: string) => {
        this.isLoadingConversation = true;
        try {
            let conversation = this.conversations.find(c => c.id === id);
            if (!conversation) {
                conversation = await agent.Conversations.get(id);
                if (conversation) this.conversations = [conversation, ...this.conversations];
                runInAction(() => this.isLoadingConversation = false);
                return conversation;
            } else {
                runInAction(() => this.isLoadingConversation = false);
                return conversation;
            }

        } catch (error) {
            runInAction(() => this.isLoadingConversation = false);
            return Promise.reject(error);
        }
    }

    postConversation = async (conversation: PostConversation) => {
        try {
            var conversationId = await agent.Conversations.add(conversation);
            return Promise.resolve(conversationId);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    loadMoreConversation = async (filters: FilterValues) => {
        try {
            const { total, conversations } = await agent.Conversations.filter(filters);
            runInAction(() => {
                this.conversations = conversations
                this.total = total;
            });
        } catch (error) {
            console.error(error);
        }
    }

    setSelectedConversation = (conversation: Conversation) => {
        this.selectedConversation = conversation;
    };

    updateNotificationReceipt = async (ids: string[], userId: string) => {
        try {
            await agent.Conversations.updateNotificationReceipt({ ids: ids });
            store.conversationStore.conversations = [
                ...store.conversationStore.conversations.map(c => {
                    if (ids.indexOf(c.id) != -1)
                        return {
                            ...c,
                            notifiedReceiptUserIds: [...c.notifiedReceiptUserIds, userId]
                        }
                    return c;
                })
            ];
        } catch (error) {
            console.error(error);
        }
    }

    setSearchQuery = (value: string) => {
        this.searchQuery = value;
    }

    getSearchQuery = () => {
        return this.searchQuery;
    }

    reset = () => {
        this.setPendingNotificationConversationIds([]);
        this.total= 0;
        this.conversations = [];
        this.isLoadingConversation= false;
        this.searchQuery= '';
    }
}