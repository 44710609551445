import { CardGroup } from "./cardGroup";
import { WorkSpace } from "./workSpace";

export interface Board {
    id: string;
    name: string;
    workSpaceId: string;
    workSpace?: WorkSpace;
    order: string[];
    cardGroups: CardGroup[];
}

export class BoardFormValues {
    id?: string = undefined;
    name: string = '';
    workSpaceId: string = '';
    order:string[] = []

    constructor(board?: Board) {
        if (board) {
            this.id = board.id;
            this.name = board.name;
            this.workSpaceId = board.workSpaceId;
            this.order = board.order;
        }
    }
}


