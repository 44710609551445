import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Grid, Header, Tab, Table } from 'semantic-ui-react';
import PaginatedTable from '../../../app/common/table/PaginatedTable';
import DateHelper from '../../../app/helpers/dateHelper';
import { LeaveBalanceFilterValues } from '../../../app/models/common/filterValues';
import { LeaveBalance } from '../../../app/models/employee';
import { useStore } from '../../../app/stores/store';

const dateHelper = new DateHelper();

interface Props {
    employeeId: string;
}

const EmployeeLeaveBalance = ({ employeeId}: Props) => {
    const { employeeStore } = useStore();
    const { isLeaveBalanceLoaded, leaveBalance, leaveBalanceTotal, loadEmployeeLeaveBalance, employeeLeaveBalances, getLeaveBalances } = employeeStore;

    const [filterValues, setFilterValues] = useState<LeaveBalanceFilterValues>({
        pageSize: 20,
        pageNumber: 1
    });

    const handleOnChangePage = (page: number) => {
        setFilterValues({ ...filterValues, pageNumber: page })
    }

    useEffect(() => {
        loadEmployeeLeaveBalance(filterValues, employeeId);
    }, [filterValues, loadEmployeeLeaveBalance])

    useEffect(() => {
        getLeaveBalances(employeeId);
    }, [])

    const onLeaveTypeOptionsChanged = leaveTypeId => {
        setFilterValues({ ...filterValues, leaveTypeId: leaveTypeId, pageNumber: 1 })
    };

    const leaveTypeOptions = () => {
        return {
            options: employeeLeaveBalances.map((lt) => ({
                value: lt.leaveTypeId,
                text: `${lt.type} (${lt.balance})`
            })),
            placeholder: "Select Leave Type",
            onChange: onLeaveTypeOptionsChanged,
            value: filterValues.leaveTypeId,
            customStyle: {alignItems: 'flex-end'}
        }
    };

    return (
        <Tab.Pane>
            <Grid divided='vertically'>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header>Leave Balance</Header>
                        <PaginatedTable<LeaveBalance>
                            columns={[
                                { title: 'Date', render: (data: LeaveBalance) => dateHelper.formatShortLocalDate(data.date.toString()) },
                                {
                                    title: 'Leave Type', render: (data: LeaveBalance) => {
                                        let leaveType: string = "";

                                        if (data.leaveRequest && data.leaveRequest.leaveType)
                                            leaveType = data.leaveRequest.leaveType.type;
                                        else if (data.leaveAdjustment && data.leaveAdjustment.leaveType)
                                            leaveType = data.leaveAdjustment.leaveType.type

                                        return leaveType;
                                    }
                                },
                                {
                                    title: 'From', render: (data: LeaveBalance) => {
                                        return data.leaveRequest != null ? dateHelper.formatShortLocalDate(data.leaveRequest.from.toString()) : " ";
                                    },
                                },
                                {
                                    title: 'To', render: (data: LeaveBalance) => {
                                        return data.leaveRequest != null ? dateHelper.formatShortLocalDate(data.leaveRequest.to.toString()) : " ";
                                    },
                                },
                                { title: 'Description', field: 'description' },
                                { title: 'Leave Request', field: 'numberOfLeaveRequest' },
                                { title: 'Leave Adjustment', field: 'leaveCredit' },
                                { title: 'Leave Balance', field: 'leaveBalance' },
                            ]}
                            data={leaveBalance!}
                            total={leaveBalanceTotal}
                            onChangePage={handleOnChangePage}
                            pageSize={filterValues.pageSize}
                            pageNumber={filterValues.pageNumber}
                            isLoading={!isLeaveBalanceLoaded}
                            style={{ boxShadow: 'none', padding: 0 }}
                            toolbar={true}
                            customOptionFilters={[leaveTypeOptions()]}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Tab.Pane>
    )
}
export default observer(EmployeeLeaveBalance);
