import { useEffect, useState } from "react";
import agent from "../../app/api/agents";
import { PostComment } from "../../app/models/post";
import CommentForm from "./CommentForm";
import Comment from "./Comment";
import { useStore } from "../../app/stores/store";
import { toast } from "react-toastify";

interface Props {
    postId: string
    isCommentActive?: boolean
    onClickClose?: any
    commentCount: number
    onCommentChanged: (number: number) => void
}

const CommentSection = ({ isCommentActive, onClickClose, commentCount, postId, onCommentChanged }: Props) => {
    const { userStore: { user } } = useStore();
    const [comments, setComments] = useState<PostComment[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        agent.Posts.getComments(postId).then(comments => {
            setComments(comments)
        });
    }, []);

    const handleSubmit = (message) => {
        setLoading(true);

        if (message && message.trim() != '<p></p>') {
            agent.PostComments.post({ message, postId }).then(commentId => {
                setComments(prev => {
                    return (
                        [
                            ...prev,
                            {
                                id: commentId,
                                postId,
                                message,
                                user: {
                                    ...user!,
                                    name: `${user?.firstName} ${user?.lastName}`
                                },
                                date: Date.now().toString(),
                                owned: true
                            }
                        ])
                });
                onCommentChanged(commentCount + 1)
                onClickClose();
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                const { response } = e;
                if (response.data.errors && response.data.status === 400) {
                    toast.error('Comment exceeded the maximum limit.');
                }
            })
        }
        else {
            toast.error('Comment is required.');
            setLoading(false);
        }
    }

    const handleUpdateComment = (id, message) => {
        setLoading(true);
        if (message && message.trim() != '<p></p>') {
            agent.PostComments.update({ id: id, message }).then(() => {
                setComments(prev => {
                    return prev.map(c => {
                        if (c.id === id) {
                            return {
                                ...c,
                                message: message,
                                isEditing: false
                            }
                        }

                        return c;
                    })
                });
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                const { response } = e;
                if (response.data.errors && response.data.status === 400) {
                    toast.error('Comment exceeded the maximum limit.');
                }
            });
        }
        else {
            toast.error('Comment is required.');
            setLoading(false);
        }
    }

    const handleDeleteComment = (id) => {
        agent.PostComments.delete(id).then(() => {
            setComments(prev => prev.filter(c => c.id !== id));
            onCommentChanged(commentCount - 1);
        });
    }

    const handleOnEditing = (id: string, state: boolean) => {
        setComments(prev => {
            return prev.map(c => {
                if (c.id === id) {
                    return {
                        ...c,
                        isEditing: state
                    }
                }

                return c;
            })
        });
    }

    return (
        <div className='comment-section'>
            {isCommentActive && <CommentForm loading={loading} onSubmit={handleSubmit} onClickClose={onClickClose} />}
            <div className='comment-list'>
                {comments.map(comment => {
                    return (
                        <Comment
                            key={comment.id}
                            isEditing={comment.isEditing}
                            onEditing={(isEditng) => handleOnEditing(comment.id, isEditng)}
                            onSubmit={handleUpdateComment}
                            onDelete={handleDeleteComment}
                            comment={comment}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default CommentSection;