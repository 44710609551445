import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useRef } from 'react';
import { BsPaperclip } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { Button, Header, Item } from 'semantic-ui-react';
import { SRLWrapper } from 'simple-react-lightbox';
import styled from 'styled-components';
import { CardAttachment } from '../../../app/models/cardAttachment';
import { useStore } from '../../../app/stores/store';
import Attachment from './Attachment';

//#region Styling
const ComponentWrapper = styled.div`
    && {
        margin-bottom: 24px;
        display:block
    }
`;

const ItemContent = styled(Item.Content)`
    &&{
        margin: 0 !important;
        width:100%;
    }
`;

const StyledItem = styled(Item)`
    &&{
        margin:16px 0 8px 0 !important;
        width:100%;
        display:flex;
    }
`;

const AttachmentIcon = styled(BsPaperclip)`
    margin: -2px 16px 0px 0px;
    font-size: 23px;
    color:#42526e;
`;

const AddAttachmentWrapper = styled.div`
    && {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin: 0 0 0 32px !important;
     }
`;

//#endregion

interface Props {
    attachments: CardAttachment[];
    cardId: string;
}

const attachLightBoxIndex = (showAll: boolean, attachments: CardAttachment[]) => {
    let splicedAttachments = showAll || attachments.length < 5 ? attachments : attachments.filter((a, i) => i < 5);
    let count = 0;
    return splicedAttachments.map(a => {
        if (a.fileName.match(/.(jpg|jpeg|png)$/i)) {
            let attachment = {
                ...a,
                index: count
            }
            count++;
            return attachment;
        } else {
            return a;
        }
    })
}

const AttachmentList = ({ attachments, cardId }: Props) => {
    const { cardAttachmentStore, boardStore, userStore } = useStore();
    const { user, getClientUsedStorage } = userStore;
    const { uploadMultiple, showAllAttachments, setShowAllAttachments } = cardAttachmentStore;
    const { board } = boardStore;


    const inputFile = useRef<HTMLInputElement>(null);

    const handleFileUpload = () => {
        if (inputFile != null && inputFile.current != null)
            inputFile.current.click();
    }

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.value == "") {
            toast.error('No file Selected');
            return;
        }

        if (!board?.workSpace || event.target.files == null) {
            toast.error('No client Id available.');
            return;
        }

        //validation
        for (const file of event.target.files) {
            if (file.size > 100000000) {
                toast.error('File must not exceed 100 MB');
                return;
            }
        }

        let totalFileSize = 0;

        for (const file of event.target.files) {
            totalFileSize += file.size / (1024 * 1024);
        }

        let usedStorage = await getClientUsedStorage(user!.clientId);

        if (Math.round(parseInt(usedStorage!.totalSize)) + Math.round(totalFileSize) > 10000) {
            toast.error('You have reached your 10,000 MB storage capacity for attachment.')
            return;
        } 

        const response = uploadMultiple(
            [...Array.from(event.target.files)],
            cardId,
            board.workSpace.clientId!);

        toast.promise(response,
            {
                pending: `Uploading file`,
                success: `File uploaded!`,
                error: `Uploading error!`
            });

        if (event.currentTarget)
            event.currentTarget.value = ""
    }

    return (
        <ComponentWrapper>
            <StyledItem>
                <AttachmentIcon />
                <ItemContent>
                    <Header
                        as='h4'
                        style={{ whiteSpace: 'pre-wrap', flexGrow: 1 }}
                    >Attachments</Header>
                </ItemContent>
            </StyledItem>
            <SRLWrapper>
                {attachLightBoxIndex(showAllAttachments, attachments).map((a, i) => <Attachment key={a.id} attachment={a} index={a.index} />)}
            </SRLWrapper>
            <AddAttachmentWrapper>
                {attachments.length > 5 && (<Button fluid compact onClick={() => setShowAllAttachments(!showAllAttachments)} size='tiny' style={{ marginBottom: '2em' }}>
                    {showAllAttachments ?
                        'Show fewer attachments' :
                        'View all attachments'}
                </Button>)}
                <input type='file' id='attachment' multiple ref={inputFile} onChange={handleFileChange} style={{ display: 'none' }} />
                <Button compact
                    size='small'
                    onClick={handleFileUpload}
                >Add an Attachment</Button>
            </AddAttachmentWrapper>
        </ComponentWrapper>
    );
}

export default observer(AttachmentList);