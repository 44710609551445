import React from 'react';
import { Button, Header } from 'semantic-ui-react';
import styled from 'styled-components';

const ComponentWrapper = styled.div`
     && {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
     }
`;

const CardActivityList = () => {
    return (
        <ComponentWrapper>
            <Header as='h4'>
                Activities
            </Header>
            <Button compact size='small'>
                Show Details
            </Button>
        </ComponentWrapper>
    );
}

export default CardActivityList;