import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Grid, Header, Tab, Table } from 'semantic-ui-react';
import PaginatedTable from '../../../app/common/table/PaginatedTable';
import DateHelper from '../../../app/helpers/dateHelper';
import { FilterValues } from '../../../app/models/common/filterValues';
import { LeaveBalance } from '../../../app/models/employee';
import { useStore } from '../../../app/stores/store';

const dateHelper = new DateHelper();

interface Props {
    employeeId: string;
}

const PreviousLeaveBalanceHistory = ({ employeeId }: Props) => {
    const { employeeStore } = useStore();
    const { isPreviousLeaveBalanceLoaded, previousLeaveBalance, previousLeaveBalanceTotal, loadPreviousLeaveBalance } = employeeStore;

    const [filterValues, setFilterValues] = useState<FilterValues>({
        pageSize: 20,
        pageNumber: 1
    });

    const handleOnChangePage = (page: number) => {
        setFilterValues({ ...filterValues, pageNumber: page })
    }

    useEffect(() => {
        loadPreviousLeaveBalance(filterValues, employeeId);
    }, [filterValues, loadPreviousLeaveBalance])


    return (
        <Tab.Pane>
            <Grid divided='vertically'>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header>Leave Balance History</Header>
                        <PaginatedTable<LeaveBalance>
                            columns={[
                                { title: 'Date', render: (data: LeaveBalance) => dateHelper.formatShortLocalDate(data.date.toString()) },
                                {
                                    title: 'Leave Type', render: (data: LeaveBalance) => {
                                        let leaveType: string = "";

                                        if (data.leaveRequest && data.leaveRequest.leaveType)
                                            leaveType = data.leaveRequest.leaveType.type;
                                        else if (data.leaveAdjustment && data.leaveAdjustment.leaveType)
                                            leaveType = data.leaveAdjustment.leaveType.type

                                        return leaveType;
                                    }
                                },
                                {
                                    title: 'From', render: (data: LeaveBalance) => {
                                        return data.leaveRequest != null ? dateHelper.formatShortLocalDate(data.leaveRequest.from.toString()) : " ";
                                    },
                                },
                                {
                                    title: 'To', render: (data: LeaveBalance) => {
                                        return data.leaveRequest != null ? dateHelper.formatShortLocalDate(data.leaveRequest.to.toString()) : " ";
                                    },
                                },
                                { title: 'Description', field: 'description' },
                                { title: 'Leave Request', field: 'numberOfLeaveRequest' },
                                { title: 'Leave Adjustment', field: 'leaveCredit' },
                                { title: 'Leave Balance', field: 'leaveBalance' },
                            ]}
                            data={previousLeaveBalance!}
                            total={previousLeaveBalanceTotal}
                            onChangePage={handleOnChangePage}
                            pageSize={filterValues.pageSize}
                            pageNumber={filterValues.pageNumber}
                            isLoading={!isPreviousLeaveBalanceLoaded}
                            style={{ boxShadow: 'none', padding: 0, marginTop: '30px' }}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Tab.Pane>
    )
}
export default observer(PreviousLeaveBalanceHistory);
