import { observer } from 'mobx-react-lite';
import React from 'react';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { Card } from '../../../app/models/card';
import { useStore } from '../../../app/stores/store';
import CommentInput from './CommentInput';
import CommentEditInput from './CommentEditInput';

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

interface Props {
    card: Card
}

const CommentList = ({ card: cardProps }: Props) => {
    const { cardStore } = useStore();
    const { card } = cardStore;

    return (
        <>
            <CommentInput comment='' cardId={cardProps.id} />
            <ListContainer>
                {card?.comments && card.comments.map(c => {
                    return (
                        <CommentEditInput comment={c} key={c.id} />
                    )
                })}
            </ListContainer>
        </>
    );
}

export default observer(CommentList);