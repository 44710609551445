import { observer } from 'mobx-react-lite';
import React from 'react';
import { Grid, Header, Item } from 'semantic-ui-react';
import styled from 'styled-components';
import DateHelper from '../../app/helpers/dateHelper';
import { DailyReport } from '../../app/models/employeeDailyReport';

const dateHelper = new DateHelper();
//#region Styling

const ItemContent = styled(Item.Content)`
    &&{
        margin: 0 0 0 0 !important;
    }
`;

const StyledItem = styled(Item)`
    &&{
        margin:16px 0 !important;
    }
`;

//#endregion

interface Props {
    dailyReport: DailyReport;
}

const DailyReportDetails = ({ dailyReport }: Props) => {

    return (
        <>
            <Grid>
                <Grid.Column width={15}>
                    <Item.Group>
                        <Item>
                            <ItemContent>
                                <Header as='h4' style={{display:'block'}}>
                                    {dailyReport.user.name}
                                </Header>
                                <small>
                                    {dateHelper.customFormatDate(dailyReport.timeRecord.timeOutDate.toString(), 'MMMM D, YYYY (ddd)')}
                                </small>
                            </ItemContent>
                        </Item>
                    </Item.Group>
                </Grid.Column>
                <Grid.Column style={{ paddingTop: 0 }} width={16}>
                    <Grid.Row>
                        <Item.Group>
                            <StyledItem>
                                {dailyReport.summary}
                            </StyledItem>
                        </Item.Group>
                    </Grid.Row>
                </Grid.Column>
            </Grid>
        </>
    );
}

export default observer(DailyReportDetails);