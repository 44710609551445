import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { history } from "../..";
import agent from '../api/agents';
import { ResetPasswordFormValues, User } from "../models/user";


export default class UserStore {
    user: User | null = null;
    displayName: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    get isLoggedIn() {
        return !!this.user;
    }

    setDisplayName = (user: User) => {
        if (this.user != null)
            this.displayName = `${user?.firstName} ${user?.lastName}`
    }

    forgotPassword = async (creds: { username: string }) => {
        try {
            await agent.Account.forgotPassword(creds);
            toast.success("Check your email for instructions on how to reset your password.");
            return true;
        } catch (e) {
            console.error(e);
        }
    }

    resetPassword = async (credentials: ResetPasswordFormValues) => {
        try {
            await agent.Account.resetPassword(credentials);
            toast.success("You have successfully reset your password.");
            history.push('/login');
        } catch (e) {
            console.error(e);
        }
    }

    getUser = async () => {
        const user = await agent.Account.current();

        runInAction(() => {
            this.user = user
            this.setDisplayName(user);
        });
    }

    getClientUsedStorage = async (id: string | undefined) => {
        try {
            return await agent.Clients.getUsedStorage(id);
        } catch (e) {
            console.log(e);
        }
    }

    getProfilePicture = async (id: string) => {
        try {
            return await agent.Account.getProfilePhoto(id, {
                responseType: 'blob'
            });
        } catch (e) {
            console.log(e);
        }
    }

    setTimeZone = (timeZone: string) => {
        if (this.user)
            this.user = {
                ...this.user,
                timeZone: timeZone
            }
    }
}