import { observer } from "mobx-react-lite";
import NewMessage from "../chat/NewMessage";
import ConversationList from "../chat/ConversationList";
import MessageContainer from "../chat/MessageContainer";
import NewGroupMessage from "../chat/NewGroupMessage";
import { useStore } from "../../stores/store";
import { useEffect } from "react";

function Chat() {
    const { chatStore: { createHubConnection, newGroupMessageOpen } } = useStore();

    useEffect(() => {
        createHubConnection();
    }, [createHubConnection]);

    return (
        <>
            <MessageContainer />
            <NewMessage />
            {newGroupMessageOpen  && <NewGroupMessage />}
            <ConversationList />
        </>
    )
}

export default observer(Chat);