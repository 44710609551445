import saveAs from "file-saver";
import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agents";
import { Media } from "../models/common/media";

interface Attachment {
    id: string;
    blob: Blob | null;
}

export default class mediaStore {
    media: Media[] = [];
    requestAttachment: Attachment = { id: "", blob: null }
    constructor() {
        makeAutoObservable(this);
    }

    getPostAttachment = async (id: string) => {
        let media = this.getById(id);

        if (!media) {
            this.media = [...this.media, {
                id: id,
                url: '',
            }]

            let blob = await agent.PostAttachments.get(id);

            media = this.getById(id);
            if (media) {
                var urlCreator = window.URL || window.webkitURL;
                var url = urlCreator.createObjectURL(blob);
                media.url = url;
                media.type = blob.type;
            };

        }

        return this.getById(id);
    }

    getProfilePicture = async (id: string) => {
        try {
            let media = this.getById(id);

            if (!media) {
                let blob = await agent.Account.getProfilePhoto(id, {
                    responseType: 'blob'
                });

                var urlCreator = window.URL || window.webkitURL;
                var url = urlCreator.createObjectURL(blob);

                media = {
                    id: id,
                    url
                }

                this.media.push(media)
            }

            return media
        } catch (e) {
            console.log(e);
        }
    }

    getClientProfilePicture = async (id: string, clientId: string) => {
        try {
            let media = this.getById(id);

            if (!media) {
                let blob = await agent.Clients.getProfilePhoto(id, clientId, {
                    responseType: 'blob'
                });

                var urlCreator = window.URL || window.webkitURL;
                var url = urlCreator.createObjectURL(blob);

                media = {
                    id: id,
                    url
                }

                this.media.push(media)
            }

            return media
        } catch (e) {
            console.log(e);
        }
    }

    getRequestAttachment = async (id: string) => {
        try {
            let media = this.getById(id);

            if (!media) {
                let blob = await this.getBlobById(id);

                if (blob) {
                    this.requestAttachment.id = id;
                    this.requestAttachment.blob = blob;

                    var urlCreator = window.URL || window.webkitURL;
                    var url = urlCreator.createObjectURL(blob);

                    media = {
                        id: id,
                        url
                    }

                    this.media.push(media)
                }
            }
            return media;
        } catch (e) {
            this.requestAttachment.id = "";
            this.requestAttachment.blob = null;
        }
    }

    downloadAttachment = async (id: string, fileName: string) => {
        if (this.requestAttachment.id === id && this.requestAttachment.blob) {
            toast.success(' File Downloaded Successfully');

            saveAs(this.requestAttachment.blob, fileName);
        }
        else {
            let blob = await this.getBlobById(id);
            if (blob) {
                this.requestAttachment.id = id;
                this.requestAttachment.blob = blob;
                saveAs(blob, fileName)
                toast.success(' File Downloaded Successfully');
            }
            else {
                toast.error('An error has occurred');
            }
        }
    }

    private getById = (id: string) => {
        return this.media.find(m => m.id === id);
    }

    private getBlobById = async (id: string) => {
        if (this.requestAttachment.id === id) {
            return this.requestAttachment.blob
        }

        try {
            let blob = await agent.RequestAttachment.getAttachment(id, { responseType: 'blob' });
            return blob;
        } catch (e) {
            console.error(e);
            toast.error('An error has occurred');
        }
    }
}