import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { Button, Form, Grid, Header, Item, Loader, Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { useStore } from '../../stores/store';
import AvatarImage from '../image/AvatarImage';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import LoadingComponent from '../../layout/LoadingComponent';
import { MdOutlineClose } from 'react-icons/md';
import { observer } from 'mobx-react-lite';
import DeleteMemberModal from './DeleteMemberModal';
const swal = withReactContent(Swal);

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const ItemContent = styled(Item.Content)`
    &&{
        margin: 0 0 0 1em !important;
    }
`;
const CloseIcon = styled(MdOutlineClose)`
    border-radius: 4px;
    color: #42526e;
    height: 32px;
    margin: 4px;
    overflow: hidden;
    padding: 4px;
    position: absolute;
    right: 0;
    top: 0;
    transition: background-color 85ms,color 85ms;
    width: 32px;
    z-index: 2;
    cursor:pointer;

    &:hover {
        background-color: #091e4214;
        color: #42526e;
    }
`;

const LoaderContainer = styled.div`
    &&{
        margin: 2em 0 2em 0em !important;
    }
`;
const files: File[] = [];
const data = {
    content: "",
    ids: Array<string>()
}
const ViewMembersModal = ({ open, setOpen }: Props) => {

    const { chatStore, employeeStore, messageStore, conversationStore, userStore } = useStore();
    const { getUsersByConverSation, employeesIncludedOnConversation, isDeletingMember, messageBoxShow } = chatStore;
    const { user } = userStore;
    const { selectedConversation } = conversationStore;
    const [isLoading, setIsLoading] = useState(false);
    const [modalState, setmodalState] = useState(false);
    const [conversationdId, setConversationdId] = useState("");
    const [userId, setUserId] = useState("");
    const [name, setName] = useState("");
    const handleOnCLose = () => {
        setOpen(false);

    }
    useEffect(() => {
        getUsersByConverSation(selectedConversation?.id);
        setIsLoading(false);
    }, [open, isDeletingMember, getUsersByConverSation]);
    useEffect(() => {
        setmodalState(false)
    }, [messageBoxShow]);
    return (
        <Modal
            size="tiny"
            open={open}
            onClose={() => setOpen(false)}
            closeOnDimmerClick={false}
        >
            <CloseIcon onClick={handleOnCLose} />
            <Modal.Header style={{ textAlign: 'center', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', padding: '1em 2em' }} >{selectedConversation?.name}</Modal.Header>
            <Modal.Content image scrolling style={{ overflowX: 'hidden' }} >
                <Modal.Description style={{ maxHeight: '20em' }} >
                    <Grid>
                      
                       
                        {isDeletingMember ? <LoaderContainer> <LoadingComponent content="Removing member..." /></LoaderContainer> : <Grid.Column width={15} style={{ marginBottom: '5em' }}>
                            <Item.Group >
                                <Item>
                                    <ItemContent>
                                        <Header as='h4' style={{ display: 'block', marginLeft: "1em" }}>
                                            <span>
                                                {employeesIncludedOnConversation.length} Members
                                            </span>
                                        </Header>
                                    </ItemContent>
                                </Item>
                            </Item.Group>
                            {employeesIncludedOnConversation.map(employee => {

                                return <Item.Group key={employee.id}  >

                                    <Item>
                                        <ItemContent>
                                            <Grid className={employee.hasBg ? "member-bg-color " : ""} >
                                                <Grid.Column width={12}>
                                                    <Header as='h4' style={{ display: 'flex', marginLeft: "1em", alignItems: "center" }}>
                                                        <AvatarImage size='mini' userId={employee.id} hasPhoto={employee.hasPhoto!} style={{ boxShadow: '1px 2px 3px #ccc', padding: '2px', marginRight: '0px', height: '4em', width: '4em' }} />
                                                        <span style={{ marginLeft: "1em" }} >
                                                            {employee.name}
                                                        </span>

                                                    </Header>
                                                </Grid.Column>
                                                <Grid.Column width={4}>

                                                    {user?.id != employee.id ?
                                                        <Header as='h4' style={{ display: 'block', marginRight: "1.5em", marginTop: "0.75em", float: 'right' }}>
                                                            <FaTrashAlt title="Remove Member" style={{ color: '#97a4b5', fontSize: '16px', cursor: "pointer" }} onClick={() => { setmodalState(true); setConversationdId(selectedConversation?.id!); setUserId(employee.id); setName(employee.name!); }} />
                                                        </Header>
                                                        :
                                                        null
                                                    }


                                                </Grid.Column>
                                            </Grid>
                                        </ItemContent>
                                    </Item>
                                </Item.Group>

                            })}
                        </Grid.Column>}
                        
                     
                    </Grid>
                </Modal.Description>
            </Modal.Content>
            <DeleteMemberModal conversationdId={conversationdId} userId={userId} name={name} open={modalState} setOpen={setmodalState} />
            
        </Modal>

    );
}

export default observer(ViewMembersModal);