import React from 'react';
import { Grid, Header, Tab, Table } from 'semantic-ui-react';
import { useStore } from '../../../app/stores/store';

const EmployeeSchedule = () => {
    const { employeeStore } = useStore();
    const { pivotSchedule, schedule } = employeeStore;

    return (
        <Tab.Pane>
            <Grid divided='vertically'>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header>Schedule</Header>
                        <Table striped style={{ padding: "2em 0", border: 'none' }}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        Day
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Start Time
                                    </Table.HeaderCell>
                                    <Table.HeaderCell >
                                        End Time
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {pivotSchedule && pivotSchedule.length != 0 ?
                                    pivotSchedule.map(({ day, startTime, endTime }) => (
                                        <Table.Row key={day}>
                                            <Table.Cell>{day}</Table.Cell>
                                            <Table.Cell>{startTime}</Table.Cell>
                                            <Table.Cell>{endTime}</Table.Cell>
                                        </Table.Row>
                                    ))
                                    : (<Table.Row><Table.Cell colSpan='5'>
                                        <Header as='h4' style={{ textAlign: 'center', fontWeight: 600, padding: '1em' }}>
                                            No Records
                                        </Header>
                                    </Table.Cell></Table.Row>)
                                }
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Tab.Pane>
    );
}

export default EmployeeSchedule;