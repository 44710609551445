import { observer } from "mobx-react-lite";
import styled from "styled-components";

const TitleContainer = styled.div`
    position: absolute;
    padding: 10px 15px 10px 15px;
    margin: 0;
      top: 4%;
    left: 50%;
    -ms-transfor
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
        background-color: rgba(0,0,0,.6);
    color: white;
    border-radius: 10px;
`;

interface Props {
    fileName: string
}

const AttachmentFileName = ({ fileName }: Props) => {
    return <TitleContainer>{fileName}</TitleContainer>
}

export default observer(AttachmentFileName);