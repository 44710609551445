import { makeAutoObservable, runInAction } from 'mobx';
import { v4 as uuid } from 'uuid';
import agent from '../api/agents';
import { Board } from '../models/board';
import { CardGroup, CardGroupFormValues, UpdateCardGroupOrderValues } from '../models/cardGroup';
import { store } from './store';

export default class CardGroupStore {
    cardGroups: CardGroup[] = [];
    cardGroupsTotal: number = 0;
    isCardGroupListLoading: boolean = false;
    isLoading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    loadCardGroup = async (id: string) => {
        this.isLoading = true;

        try {
            let cardGroup = await agent.CardGroups.get(id);
            this.isLoading = false;
            return cardGroup;
        } catch (e) {
            throw e;
        }
    }

    getAllCardGroups = async (boardId: string) => {
        alert('getall')
        this.isLoading = true;
        try {
            let cardGroups = await agent.CardGroups.getAll(boardId);
            this.isLoading = false;
            return cardGroups;
        } catch (e) {
            this.isLoading = false;
            throw e;
        }
    }

    add = async (cardGroup: CardGroupFormValues) => {
        try {
            cardGroup.id = uuid();
            cardGroup.cards = [];
            store.boardStore.hubConnection?.invoke('SendCardGroup', cardGroup);

            const board = store.boardStore.board;
            const newBoard = {
                ...board,
                order: board!.order ? [...board!.order, cardGroup.id] : [cardGroup.id],
                cardGroups: board?.cardGroups ? [...board?.cardGroups, cardGroup] : [cardGroup]
            }

            store.boardStore.board = newBoard as Board;
        } catch (e) {
            console.log(e);
        }
    }

    update = async (cardGroup: CardGroupFormValues) => {
        try {
            store.boardStore.hubConnection?.invoke("UpdateCardGroup", cardGroup);
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    updateCardGroupOrder = async (values: UpdateCardGroupOrderValues) => {
        try {
            store.boardStore.hubConnection?.invoke("UpdateCardGroupOrder", values);
        } catch (e) {
            console.log(e);
        }
    }

    deleteCardGroup = async (id: string) => {
        try {
            store.boardStore.hubConnection?.invoke('DeleteCardGroup', id);
            const board = store.boardStore.board;
            const cardGroups = store.boardStore.board!.cardGroups.filter(cg => cg.id != id);
            const newBoard = {
                ...board,
                cardGroups: [...cardGroups],
                order: [...store.boardStore.board!.order.filter(orderId => orderId != id)]
            }

            store.boardStore.board = newBoard as Board;
        } catch (e) {
            console.log(e);
        }
    }

}