import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';


interface Props {
    classes: any;
    style: any;
    name: any;
    value: any;
    onChange: any;
    error: any;
    helperText: any;
}

const TimePickers = (props: Props) => {
    return (
        <TextField
            id="time"
            type="time"
            value={ props.value }
            name={props.name}
            className={props.classes.name}
            onChange={props.onChange}
            error={props.error}
            style={ props.style }
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    style: { fontSize: 12, padding: 6 },
                }}
                variant="outlined"
                size="small"
                margin="none"
            />
    );
}

TimePickers.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default TimePickers;