import { useEffect, useState } from "react";
import { Header, Comment } from "semantic-ui-react";
import agent from "../../app/api/agents";
import { User } from "../../app/models/user";
import { useStore } from "../../app/stores/store";
import BirthdayLink from "./BirthdayLink";

const Birthday = () => {
    const { userStore } = useStore();
    const [users, setUsers] = useState<User[]>();
    useEffect(() => {
        agent.Employees
            .getUpComingBirthdays(userStore.user?.clientId!).then(users => {
                setUsers(users)
            });
    }, []);

    return (
        <Comment.Group className='birthdays'>
            <Header as='h3' dividing>
                Birthdays
            </Header>
            {users?.map((user) => {
                return <BirthdayLink key={`b-day-${user.id}`} user={user} />
            })}
        </Comment.Group>

    );
}


export default Birthday;