import React, { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { useStore } from '../../stores/store';

interface Props {
    attachmentId: string;
}

const fileToDataUri = (file: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
        resolve(event.target!.result)
    };
    reader.readAsDataURL(file);
})

const ChatAttachmentImage = ({ attachmentId }: Props) => {
    const { chatAttachmentStore, chatStore } = useStore();
    const [dataUri, setDataUri] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let unmounted = false;
        try {
            setLoading(true);
            chatStore.setIsImageAttachmentLoading(true);
            chatAttachmentStore.getImageBlob(attachmentId).then(v => {
                if (v != undefined && !unmounted)
                    fileToDataUri(v).then(dataUri => {
                        setDataUri(dataUri as string)
                        setLoading(false);
                        chatStore.setIsImageAttachmentLoading(false);
                    });
            });
        } catch (e) {
            if (!unmounted) {
                setDataUri('/assets/default-profile-picture.svg');
                setLoading(false);
            }
        }

        return () => { unmounted = true };
    }, [attachmentId, chatAttachmentStore])

    return (
        loading ?
            <div style={{ width: '220px', height: '220px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Icon loading size='big' name='spinner' /> </div>
            :
            <img src={dataUri || '/assets/default-profile-picture.svg'} style={{ width: '220px', height: '220px', objectFit: 'cover' }} />
       
    );
}

export default ChatAttachmentImage;