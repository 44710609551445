import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import NotificationDetails from '../../features/date-notifications/NotificationDetails';
import NotificationModalContainer from '../../features/date-notifications/NotificationModalContainer';
import ModalContainer from '../common/modal/ModalContainer';
import PopoverContainer from '../common/popover/PopoverContainer';
import { ScheduleNotification } from '../models/scheduleNotification';
import ApplicationRouter from '../router/ApplicationRouter';
import { useStore } from '../stores/store';
import LoadingComponent from './LoadingComponent';
import notificationSound from '../../assets/audio/notification-sound.mp3';


let notifications: ScheduleNotification[] = [];

function App() {
    const {
        notificationStore: { createAppHubConnection, disconnectAppHubConnection, getUnreadCount, pushPermission },
        notificationModalStore,
        commonStore, userStore,
        authStore,
        commonStore: { createHubConnection }
    } = useStore();
    const { notification } = commonStore;

    useEffect(() => {
        if (notification) {
            if (notificationModalStore.modal.open) {
                if (!(notifications.find(x => x.cardId === notification.cardId))) {
                    notifications = [notification, ...notifications];
                    playNotificationSound();
                }
            } else {
                notifications = [notification];
                playNotificationSound();
            }

            notificationModalStore.openModal(<NotificationDetails notifications={notifications} />);
        }
    }, [notification]);

    useEffect(() => {
        createHubConnection();
    }, [createHubConnection]);

    useEffect(() => {
        if (authStore.token) {
            userStore.getUser()
                .then(() => {
                    createAppHubConnection();
                    pushPermission();
                    getUnreadCount();
                }).finally(() => {
                    commonStore.setAppLoaded();
                    authStore.startRefreshTokenTimer();
                }).catch(() => {
                    disconnectAppHubConnection();
                });
        } else {
            commonStore.setAppLoaded();
        }
    }, [authStore.token]);

    const playNotificationSound = () => {
        let audio = new Audio(notificationSound);
        audio.play();
    }

    if (!commonStore.appLoaded) return <LoadingComponent content='Loading Kasina...' />

    return (
        <>
            <ToastContainer position='top-right' hideProgressBar theme='colored' />
            <ModalContainer />
            <NotificationModalContainer />
            <PopoverContainer />
            <ApplicationRouter />
        </>
    );
}



export default observer(App);
