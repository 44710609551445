import { observer } from "mobx-react-lite";
import styled from "styled-components";

const Image = styled.img`
        height: 100%;
        width: 100%;
`;

interface Props {
    imageUri: string
}

const ImageAttachment = ({ imageUri }: Props) => {
    return <Image src={imageUri} />;
}

export default observer(ImageAttachment);