import { Popup } from "semantic-ui-react";
import TextOverflow from "../text/TextOverflow";


interface Props {
    content: string;
    columnContent?: string
    width?: number;
}

const TableColumnPopover = (props: Props) => {
    let columnContent = props.columnContent ? props.columnContent : props.content;
    return (
        <Popup
            trigger={<label>{<TextOverflow style={props.width ? { width: props.width } : {}}>{columnContent}</TextOverflow>}</ label>}
            content={props.content}
            hoverable
            position='bottom center'
            wide
            size='small'
            className="custom-popup"
        />
    )
};

export default TableColumnPopover;