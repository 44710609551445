import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Grid, Item, Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { OptionSelect } from '../../models/option';
import { useStore } from '../../stores/store';
import ReceiverMultiSelect from './ReceiverMultiSelect';

const swal = withReactContent(Swal);
//#region Styling

const ItemContent = styled(Item.Content)`
    &&{
        margin: 0 0 0 0em !important;
        max-width: fit-content;
        min-width: 100%;   
    }
`;

const StyledItem = styled(Item)`
    &&{
        margin:5px 0 !important;
    }
`;
const selectStyles = {
    container: (provided, state) => ({
        ...provided,
        margin: '5px',
        width: '97%',
        zIndex: '9999'
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        overflowY: 'auto',
        maxHeight: '100px'
    }),
    menuList: (provided, state) => ({
        ...provided,
        overflowY: 'auto',
        maxHeight: '190px'
    }),
}
//#endregion

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
interface Groupchat {
    userIds: {
        value: string,
        id: string,
        label: string
    }[]
}

const files: File[] = [];
const data = {
    content: "",
    ids: Array<string>()
}

const AddMemberModal = ({ open, setOpen }: Props) => {
    const { chatStore, employeeStore, messageStore, conversationStore, userStore } = useStore();
    const { isEmployeesByConversationLoading, loadEmployeesByConversationId, employeesByConversation,
        getUsersByConverSation, employeesIncludedOnConversation, addUsersToConversation, deleteMember } = chatStore;
    const { addMessageWithAttachment } = messageStore;
    const { user } = userStore;
    const { selectedConversation } = conversationStore;
    const formikRef = useRef<FormikProps<Groupchat>>(null);
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = ({ userIds }: Groupchat) => {
        let usersToUpdate = employeesIncludedOnConversation.map(u => u.id);

        data.ids = [];
        userIds.forEach(userId => {
            data.ids.push(userId.id);
        })

        userIds.length > 1 ? data.content = userIds.length == 2 ? `${user?.firstName + " " + user?.lastName} added ${userIds[0].label} and ${userIds[1].label} to this conversation.[[SystemGenerated]]` : `${user?.firstName + " " + user?.lastName} added ${userIds[0].label} and ${userIds.length - 1} others to this conversation.[[SystemGenerated]]`
            : data.content = `${user?.firstName + " " + user?.lastName} added ${userIds[0].label} to this conversation.[[SystemGenerated]]`;

        setIsLoading(true);

        addUsersToConversation(selectedConversation?.id!, data.ids).then(request => {
            addMessageWithAttachment(
                {
                    content: data.content,
                    conversationId: selectedConversation?.id,
                    receiversId: [],
                    connectionId: chatStore.connectionId,
                    files: files
                }
            ).then(() => {
                chatStore.hubConnection?.invoke('OnUpdateNumberOfUsersToTheConversation', usersToUpdate, selectedConversation?.id);
                request.userIds.forEach(id => {
                    chatStore.hubConnection?.invoke('AddUserToConveration', id, selectedConversation?.id,)
                })

                data.ids = [];
                setIsLoading(false);
                setOpen(false)

            });

        })
    }

    const handleOnCLose = () => {
        setOpen(false);

    }
    useEffect(() => {
        loadEmployeesByConversationId(selectedConversation?.id);

    }, [loadEmployeesByConversationId, selectedConversation, open]);

    useEffect(() => {
        getUsersByConverSation(selectedConversation?.id);

    }, [getUsersByConverSation, selectedConversation, open]);

    return (
        <Modal
            size="tiny"
            open={open}
            onClose={() => handleOnCLose}
            closeOnDimmerClick={false}
        >
            <Modal.Header style={{ textAlign: 'center' }} >Add Member</Modal.Header>
            <Formik
                enableReinitialize
                initialValues={{
                    userIds: []
                } as Groupchat}
                onSubmit={handleSubmit}
                innerRef={formikRef}
            >
                {
                    ({ handleSubmit, isSubmitting, isValid, setErrors, resetForm, dirty }) => {
                        return (
                            <>
                                <Modal.Content image scrolling style={{ overflowX: 'hidden' }} >
                                    <Modal.Description style={{ maxHeight: '20em' }} >

                                        <Grid style={{ justifyContent: 'center' }} >
                                            <Grid.Column width={15} style={{ marginBottom: '1em' }}>
                                                <Item.Group>
                                                    <Item >
                                                        <ItemContent>
                                                            <Form onSubmit={handleSubmit} autoComplete='off' >
                                                                <ReceiverMultiSelect
                                                                    menuPortalTarget={document.body}
                                                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                                    name='userIds'
                                                                    options={employeesByConversation.map(e => {
                                                                        return { id: e.id, value: e.name, label: e.name } as OptionSelect;
                                                                    }
                                                                    )}
                                                                    isLoading={isEmployeesByConversationLoading}
                                                                    isMulti={true}
                                                                    placeholder='Select Member...'
                                                                    noOptionsMessage={() => 'No user available'}
                                                                />
                                                            </Form>
                                                        </ItemContent>
                                                    </Item>
                                                </Item.Group>
                                            </Grid.Column>
                                        </Grid>
                                    </Modal.Description>
                                </Modal.Content>

                                <Modal.Actions>
                                    <Button
                                        content="Save"
                                        primary
                                        type="submit"
                                        loading={isSubmitting || isLoading}
                                        disabled={!dirty || !isValid || isSubmitting || isLoading}
                                        onClick={() => handleSubmit()}

                                    />
                                    <Button onClick={() => setOpen(false)}>
                                        Cancel
                                    </Button>
                                </Modal.Actions>
                            </>
                        )
                    }
                }
            </Formik>
        </Modal>
    );
}

export default observer(AddMemberModal);