import React, { useState } from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { SemanticDatepickerProps } from 'react-semantic-ui-datepickers/dist/types';
import Select, { MultiValue, Props as SelectProps } from 'react-select';
import styled from 'styled-components';
import { OptionSelect } from '../../models/option';
import { useStore } from '../../stores/store';
import { observer } from 'mobx-react-lite';


const ContainerStyled = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: flex-start;
    justify-content: flex-start;

    & .field input {
        width: 245px !important;
    }
`;

const selectStyles = {
    container: (provided, state) => ({
        ...provided,
        minWidth: '245px',
        maxWidth: '600px'
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        overflowY: 'auto',
        maxHeight: '60px'
    }),
    menuList: (provided, state) => ({
        ...provided,
        overflowY: 'auto',
        maxHeight: '190px'
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex: 11
    }),
}

interface Props {
    toolbarProps: any,
    userFilterLabel?: string;
    onUserFilter?: (userIds: string[]) => void | undefined;
    userValues?: OptionSelect[];
    userFilterPlaceholder?: string;
}

const UserFilter = ({ toolbarProps, userFilterLabel, onUserFilter, userValues, userFilterPlaceholder }: Props) => {
    const { dailyReportStore } = useStore();
    const { currentUserIds, setCurrentUserIds, isLoadingUserEmployees } = dailyReportStore;

    const handleUserIdsChange = (newValue: MultiValue<{ value: string }>) => {
        setCurrentUserIds(newValue);
        if (newValue.length) {
            let userIds = newValue.map(v => v.value);
            onUserFilter!(userIds);
        } else {
            onUserFilter!([]);
        }
    };
    return (
        <ContainerStyled>
            {userFilterLabel && (<label style={{
                textAlign: 'left',
                color: '#6a7e99', fontSize: '14px', marginBottom: '4px'
            }}>
                {userFilterLabel}
            </label>)}
            <Select
                isLoading={isLoadingUserEmployees}
                value={currentUserIds}
                styles={selectStyles}
                onChange={handleUserIdsChange}
                options={userValues}
                isMulti
                placeholder={userFilterPlaceholder}
            />
        </ContainerStyled>
    );
}

export default observer(UserFilter);