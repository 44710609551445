import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useEffect, useRef, useState } from 'react';
import "draft-js/dist/Draft.css";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

interface Props {
    className?: string
    placeholder?: string
    value: string
    onChange?: (value: string) => void
}


const TextEditor = ({ className, placeholder, value, onChange }: Props) => {
    const editor = useRef<any>(null);


    const [editorState, setEditorState] = useState(() => {
        if (value) {
            let convertedToHTML = decodeURIComponent(value);
            const blocksFromHtml = htmlToDraft(convertedToHTML);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(
                contentBlocks,
                entityMap
            );
            return EditorState.createWithContent(contentState);
        }
        return EditorState.createEmpty()
    });

    useEffect(() => {
        if (!value) {
            setEditorState(EditorState.createEmpty());
        }
    }, [value])

    const handleChange = (editorState: EditorState) => {
        if (editorState.getCurrentContent().hasText()) {
            if (onChange) onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        }
        else {
            if (onChange) onChange("");
        }

        setEditorState(editorState);
    }

    const focusEditor = () => {
        editor.current.focusEditor();
    };

    return (
        <>
            <div onClick={focusEditor} className={className}>
                <Editor
                    ref={editor}
                    placeholder={placeholder}
                    editorState={editorState}
                    onEditorStateChange={handleChange}
                />
            </div>
        </>
    );
}

export default TextEditor;