import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import agent from '../api/agents';
import { Board } from '../models/board';
import { Card, CardFormValues } from '../models/card';
import { CardAttachment } from '../models/cardAttachment';
import { CardChecklist, CardChecklistItem } from '../models/cardChecklist';
import { CardComment } from '../models/cardComment';
import { CommentAttachment } from '../models/commentAttachment';
import { Option } from '../models/option';
import { store } from './store';

export default class CardStore {
    cards: Card[] = [];
    cardsTotal: number = 0;
    isCardListLoading: boolean = false;
    isLoading: boolean = true;
    card: Card | undefined;
    priorities: Option[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    getPriorities = async () => {
        if (this.priorities.length === 0) {
            let data = await agent.Priorities.getAll();

            if (data) {
                this.priorities = data;
            }
        }
    }

    attachHubEvents = () => {
        if (store.boardStore.hubConnection) {
            store.boardStore.hubConnection!.on("DeleteCard", (card: { cardId: string, cardGroupId: string }) => {
                const activeBoard = store.boardStore.board;

                if (!activeBoard) {
                    toast.error('Current project not found.');
                    return;
                }

                const currentCardGroup = activeBoard.cardGroups.find(c => c.id === card.cardGroupId);

                if (!currentCardGroup) {
                    toast.error('Current task list not found.');
                    return;
                }
                const otherLists = activeBoard.cardGroups.filter(c => c.id !== card.cardGroupId);

                const filteredCards = currentCardGroup.cards.filter(c => c.id !== card.cardId);

                const newBoard = {
                    ...activeBoard,
                    cardGroups: [
                        ...otherLists,
                        {
                            ...currentCardGroup,
                            cards: [
                                ...filteredCards
                            ],
                        }]
                };

                store.boardStore.board = newBoard as Board;
            });

            store.boardStore.hubConnection!.on("ReceiveNewCard", (receivedCard: any) => {
                runInAction(() => {
                    this.isLoading = false;

                    const board = store.boardStore.board;
                    const currentCardGroup = store.boardStore.board?.cardGroups.find(cg => cg.id == receivedCard.cardGroupId);
                    const cardGroups = store.boardStore.board!.cardGroups.filter(cg => cg.id != receivedCard.cardGroupId);

                    const newBoard = {
                        ...board,
                        cardGroups: [...cardGroups,
                        {
                            ...currentCardGroup,
                            order: currentCardGroup!.order ? [...currentCardGroup!.order, receivedCard.id] : [receivedCard.id],
                            cards: currentCardGroup!.cards ? [...currentCardGroup!.cards, receivedCard] : [receivedCard]
                        }]
                    }
                    store.boardStore.board = newBoard as Board;
                });
            });

            store.boardStore.hubConnection!.on("ReceiveCard", (card: any) => {
                runInAction(() => {
                    this.card = card;
                    this.isLoading = false;
                });
            });

            store.boardStore.hubConnection!.on("ReceiveCardUpdate", (card: Card) => {
                runInAction(() => {
                    this.card = card;
                });
            });

            store.boardStore.hubConnection!.on("ReceiveCardComments", (cardComments: CardComment[]) => {
                runInAction(() => {
                    this.card = {
                        ...this.card,
                        comments: cardComments
                    } as Card;
                });
            });

            store.boardStore.hubConnection!.on("ReceiveCardComment", (cardComment: CardComment) => {
                runInAction(() => {
                    const comments = this.card?.comments ? this.card.comments : [];
                    this.card = {
                        ...this.card,
                        comments: [cardComment, ...comments]
                    } as Card;
                });
            });

            store.boardStore.hubConnection!.on("ReceiveUpdatedComment", (comment: CardComment) => {
                runInAction(() => {
                    let currentComment = this.card?.comments.find(c => c.id === comment.id);
                    this.card = {
                        ...this.card,
                        comments: this.card?.comments.map(co => {
                            if (co.id === comment.id)
                                return {
                                    ...currentComment,
                                    message: comment.message,
                                    dateUpdated: comment.dateUpdated
                                }
                            else
                                return co
                        })
                    } as Card;
                });
            });

            store.boardStore.hubConnection!.on("DeleteComment", (commentId: string) => {
                runInAction(() => {
                    this.card = {
                        ...this.card,
                        comments: [...this.card?.comments.filter(c => c.id !== commentId)!]
                    } as Card
                });
            });

            store.boardStore.hubConnection!.on("ReceiveCardChecklists", (checklists: CardChecklist[]) => {
                runInAction(() => {
                    this.card = {
                        ...this.card,
                        checklists: checklists
                    } as Card;
                });
            });

            store.boardStore.hubConnection!.on("ReceiveUpdatedChecklist", (checklist: CardChecklist) => {
                runInAction(() => {
                    let currentChecklist = this.card?.checklists.find(c => c.id === checklist.id);
                    this.card = {
                        ...this.card,
                        checklists: this.card?.checklists.map(ck => {
                            if (ck.id === checklist.id)
                                return {
                                    ...currentChecklist,
                                    title: checklist.title
                                }
                            else
                                return ck;
                        })
                    } as Card;
                });
            });

            store.boardStore.hubConnection!.on("DeleteChecklist", (checklistId: string) => {
                runInAction(() => {
                    this.card = {
                        ...this.card,
                        checklists: [...this.card?.checklists.filter(c => c.id !== checklistId)!]
                    } as Card
                });
            });

            store.boardStore.hubConnection!.on("ReceiveCardChecklistItems", (checklistItems: CardChecklistItem[]) => {
                if (checklistItems.length) {
                    runInAction(() => {
                        this.card = {
                            ...this.card,
                            checklists: this.card?.checklists.map(cl => {
                                if (cl.id === checklistItems[0].cardChecklistId)
                                    return { ...cl, items: checklistItems }
                                return cl;
                            }),
                        } as Card;
                    });
                }
            });

            store.boardStore.hubConnection!.on("ReceiveUpdatedChecklistItem", (checklistItem: CardChecklistItem) => {
                runInAction(() => {
                    this.card = {
                        ...this.card,
                        checklists: [
                            ...this.card?.checklists.map(cc => {
                                if (cc.id === checklistItem.cardChecklistId)
                                    return {
                                        ...cc,
                                        items: cc.items?.map(i => {
                                            if (i.id === checklistItem.id)
                                                return {
                                                    ...i,
                                                    title: checklistItem.title,
                                                    isCompleted: checklistItem.isCompleted
                                                }
                                            return i;
                                        })
                                    }
                                return cc;
                            })!
                        ]
                    } as Card;
                });
            });

            store.boardStore.hubConnection!.on("DeleteChecklistItem", (checklistItem: CardChecklistItem) => {
                runInAction(() => {
                    this.card = {
                        ...this.card,
                        checklists: [
                            ...this.card?.checklists.map(cc => {
                                if (cc.id === checklistItem.cardChecklistId)
                                    return {
                                        ...cc,
                                        items: cc.items?.filter(i => i.id != checklistItem.id)
                                    }
                                return cc;
                            })!
                        ]
                    } as Card;
                });
            });

            store.boardStore.hubConnection!.on("LoadCardAttachments", (attachments: CardAttachment[]) => {
                runInAction(() => {
                    this.card = {
                        ...this.card,
                        attachments: attachments
                    } as Card;
                });
            });
            store.boardStore.hubConnection!.on("AddCardAttachments", (attachments: CardAttachment[]) => {
                runInAction(() => {
                    this.card = {
                        ...this.card,
                        attachments: attachments
                    } as Card;
                });
            });

            store.boardStore.hubConnection!.on("DeleteAttachment", (attachmentId: string) => {
                runInAction(() => {
                    this.card = {
                        ...this.card,
                        attachments: [...this.card?.attachments.filter(c => c.id !== attachmentId)!]
                    } as Card
                });
            });

            store.boardStore.hubConnection!.on("ReceiveUpdatedAttachment", (attachment: CardAttachment) => {
                runInAction(() => {
                    let currentAttachment = this.card?.attachments.find(c => c.id === attachment.id);
                    this.card = {
                        ...this.card,
                        attachments: this.card?.attachments.map(a => {
                            if (a.id === attachment.id)
                                return {
                                    ...currentAttachment,
                                    fileName: attachment.fileName
                                }
                            else
                                return a;
                        })
                    } as Card;
                });
            });
            store.boardStore.hubConnection!.on("ReceiveAttachment", (attachment: CardAttachment) => {
                //runInAction(() => {
                //    this.card = {
                //        ...this.card,
                //        attachments: this.card?.attachments.map(a => {
                //            if (a.id === attachment.id)
                //                return {
                //                    ...attachment,
                //                    fileName: attachment.fileName
                //                }
                //            else
                //                return a;
                //        })
                //    } as Card;
                //});
            });

            store.boardStore.hubConnection!.on("DeleteCommentAttachment", (commentAttachment: { commentId: string, attachmentId: string }) => {
                runInAction(() => {

                    this.card = {
                        ...this.card,
                        comments: this.card?.comments.map(a => {
                            if (a.id === commentAttachment.commentId)
                                return {
                                    ...a,
                                    attachments: a.attachments.filter(x => x.id != commentAttachment.attachmentId)
                                }
                            else
                                return a;
                        })
                    } as Card
                });
            });

            store.boardStore.hubConnection!.on("ReceiveUpdatedCommentAttachments", (commentAttachment: CommentAttachment[]) => {
                runInAction(() => {
                    this.card = {
                        ...this.card,
                        comments: [...this.card?.comments.map(c => {
                            if (c.id === commentAttachment[0].commentId) {
                                return {
                                    ...c,
                                    attachments: commentAttachment
                                }
                            }
                            else {
                                return c;
                            }
                        })!]
                    } as Card;
                });
            });
        }
    }

    loadCard = (cardId: string) => {
        store.cardChecklistStore.setIsChecklistItemChange(false);
        store.cardChecklistStore.setIsChecklistTitleChange(false);
        this.isLoading = true;

        if (store.boardStore.hubConnection) {
            store.boardStore.hubConnection!.invoke("LoadCard", cardId);
        } else
            this.isLoading = false;
    }

    stopListening = async (cardId: string) => {
        try {
            store.boardStore.hubConnection!.invoke('StopCardUpdates', cardId);
        } catch (e) {
            console.log(e);
        }
    }

    getAllCards = async (boardId: string) => {
        this.isLoading = true;
        try {
            let cards = await agent.Cards.getAll(boardId);
            this.isLoading = false;
            return cards;
        } catch (e) {
            this.isLoading = false;
            throw e;
        }
    }

    add = async (card: CardFormValues) => {
        try {
            let users: string[] = [];
            users.push(store.userStore.user!.id)
            card.id = uuid();
            store.boardStore.hubConnection!.invoke('SendCard', card);
            const board = store.boardStore.board;
            const currentCardGroup = store.boardStore.board?.cardGroups.find(cg => cg.id == card.cardGroupId);
            const cardGroups = store.boardStore.board!.cardGroups.filter(cg => cg.id != card.cardGroupId);

            const newBoard = {
                ...board,
                cardGroups: [...cardGroups,
                {
                    ...currentCardGroup,
                    order: currentCardGroup!.order ? [...currentCardGroup!.order, card.id] : [card.id],
                    cards: currentCardGroup!.cards ? [...currentCardGroup!.cards, card] : [card]
                }]
            }

            store.boardStore.board = newBoard as Board;
        } catch (e) {
            console.log(e);
        }
    }

    update = async (card: CardFormValues) => {
        try {
            store.boardStore.hubConnection!.invoke('UpdateCard', card);
            runInAction(() => {
            });
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    updatePriority = async (cardId: string, priorityId: string | null) => {
        try {
            store.boardStore.hubConnection!.invoke("UpdateCardPriority", {
                id: cardId,
                priorityId: priorityId
            });
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    deleteCard = async (id: string, cardGroupId: string) => {
        try {
            store.boardStore.hubConnection!.invoke('DeleteCard', id);
            runInAction(() => {
                toast.success('Task was deleted successfully.');

                const clonedState = store.boardStore.board;
                const currentList = clonedState?.cardGroups.find(c => c.id === cardGroupId);
                let cards: Card[] = [];
                if (currentList)
                    cards = currentList?.cards.filter(c => c.id !== id);
                const otherLists = clonedState?.cardGroups.filter(c => c.id !== cardGroupId);

                const newBoard = {
                    ...clonedState,
                    cardGroups: [
                        ...otherLists!,
                        {
                            ...currentList,
                            cards: [
                                ...cards
                            ]
                        }]
                }
                store.boardStore.board = newBoard as Board;
                store.modalStore.closeModal();
            });
        } catch (e) {
            console.log(e);
        }
    }

    reset = () => {
        this.isLoading = true;
    }
}