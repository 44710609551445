import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import styled from "styled-components";

const Video = styled.video`
        height: 100%;
        width: 100%;
`;

const ErrorContainer = styled.div`
    position: absolute;
    padding: 10px 15px 10px 15px;
    margin: 0;
    top: 50%;
    left: 50%;
    -ms-transfor -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    color: white;
`;

interface Props {
    videoUri: string;
    type: string;
}

const VideoAttachment = ({ videoUri, type }: Props) => {
    const [isSupportedFormat, setIsSupportedFormat] = useState<boolean>(true);

    useEffect(() => {
        setIsSupportedFormat(type.includes("avi"));

        return () => { setIsSupportedFormat(true) };

    }, [])


    return (
        <>
            {isSupportedFormat ?

                <ErrorContainer>The video format is not supported for playback. You can download the video using the icon below</ErrorContainer>
                :
                <Video controls autoPlay>
                    <source src={videoUri} type={type} />
                </Video>
            }
        </>
    );
}

export default observer(VideoAttachment);