import { IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { BsTrash } from 'react-icons/bs';
import { MdClear } from 'react-icons/md';
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import { Button, Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CardChecklistItem, CardChecklistItemFormValues } from '../../../app/models/cardChecklist';
import { useStore } from '../../../app/stores/store';

//#region Styling
const ItemContainer = styled.div`
padding:0px 8px !important;
display: flex;
 margin-bottom: 8px !important;
 margin-left: -8px !important;
  align-items: stretch;
    &:hover{
        background-color: #091e420a !important;
    }
`;
const ComponentWrapper = styled.div`
     && {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        margin: 0 !important;
        border-radius: 6px;
     }
`;

const LabelContainer = styled.div`
     && {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin:8px !important;
     }
`;
const DeleteContainer = styled.div`
     && {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin:8px 0px !important;
     }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
`;

const StyledTextArea = styled(TextareaAutosize) <{ isCompleted: boolean }>`
    && {
        width:100%;
        overflow: hidden;
        overflow-wrap: break-word;
        background: #0000;
        border-radius: 3px;
        line-height: 24px;
        min-height: 24px;
        padding: 4px 8px;
        resize: none;
        background-color: #fff;
        box-shadow: inset 0 0 0 2px #0079bf;
        border: none;
        outline:none;
        display:block;
       text-decoration: ${p => (p.isCompleted ? 'line-through' : 'none')};
    }
`;

const StyledText = styled.p<{ isCompleted: boolean }>`
    && {
        width:100%;
        white-space: pre-wrap;
        flex-grow:1;
        margin:0;
        text-decoration: ${p => (p.isCompleted ? 'line-through' : 'none')};
        overflow-wrap: break-word;
        word-break: break-word;
    }
`;

const DisplayContainer = styled.div`
    && {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        width:100%;
    }
`;

const DeleteIcon = styled(BsTrash)`
        &&{
        font-size: 20px;
        border-radius: 4px;
        width: 25px;
        height: 25px;
        align-self: flex-start;
        padding: 4px;
        color:#717171;
        cursor: pointer;
}
`;

//#endregion

interface Props {
    checklistItem: CardChecklistItem;
    activechecklistItemId?: string;
    onFocus?: (checkListItemId?: string) => void
    onCancel?: () => void;
}

const _swal = withReactContent(Swal);

const ChecklistItem = ({ activechecklistItemId, onFocus, checklistItem, onCancel }: Props) => {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState(checklistItem.title);
    const [completed, setCompleted] = useState(checklistItem.isCompleted);
    const { cardChecklistStore } = useStore();
    const { updateItem, deleteItem, setIsChecklistItemChange, checkListItemPreviousValue, isChecklistItemChange, setChecklistItemPreviousValue } = cardChecklistStore;

    useEffect(() => {
        setTitle(checklistItem.title);
    }, [checklistItem]);

    useEffect(() => {
        if (activechecklistItemId === checklistItem.id && isChecklistItemChange) {
            setTitle(checkListItemPreviousValue);
        }
    }, [isChecklistItemChange]);

    useEffect(() => {
        if (activechecklistItemId) {
            setTitle(checklistItem.title);
        }
    }, [activechecklistItemId]);

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTitle(event.target.value);
        setIsChecklistItemChange(event.target.value !== checklistItem.title);
        setChecklistItemPreviousValue(event.target.value);
    }

    const handleOnSave = async (completeProps?: boolean) => {
        if (title && title.length > 0 && title.length <= 512) {
            checklistItem.title = title;
            checklistItem.isCompleted = typeof completeProps === 'boolean' ? completeProps! : completed;
            updateItem(new CardChecklistItemFormValues(checklistItem));
            //setOpen(false);
            handleFocusOut();
        } else {
            setTitle(checklistItem.title);
            toast.error('Item should not be empty or exceed 512 characters');
            //setOpen(false);
            handleFocusOut();
        }
    }
    const handleOnChecked = async (completeProps?: boolean) => {
        if (title && title.length > 0 && title.length <= 512) {
            checklistItem.isCompleted = typeof completeProps === 'boolean' ? completeProps! : completed;
            updateItem(new CardChecklistItemFormValues(checklistItem));
        } else {
            setTitle(checklistItem.title);
            toast.error('Item should not be empty or exceed 512 characters');
        }
    }

    const handleClear = (e) => {
        e.stopPropagation();
        setTitle(checklistItem.title);
        //setOpen(false);
        if (onCancel) onCancel()
    }

    const toggleCompleted = () => {
        let newCompleteState = !completed;
        setCompleted(newCompleteState);
        handleOnChecked(newCompleteState);
    }

    const handleDelete = () => {
        _swal.fire({
            title: 'Delete?',
            text: "Are you sure you want to delete this Checklist item?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteItem(checklistItem.id);
            }
        });
    }

    const handleFocus = () => {
        if (onFocus) {
            onFocus(checklistItem.id)
        }
    }
    const handleFocusOut = () => {
        if (onCancel) onCancel()
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleOnSave();
        }
    }
    const handleOnFocus = (e) => {
        let val = e.target.value
        e.target.value = '';
        e.target.value = val;
    }

  

    return (
        <ItemContainer>
            <ComponentWrapper  >
                <Checkbox
                    style={{ margin: '8px 8px 8px 0px' }}
                    checked={checklistItem.isCompleted}
                    onClick={toggleCompleted}
                />
            </ComponentWrapper>
            <ComponentWrapper style={{ flexGrow: 8, cursor: "pointer" }} onClick={handleFocus}>
            
                <LabelContainer>
                    {activechecklistItemId === checklistItem.id ? (
                        <>
                            <StyledTextArea
                                autoFocus
                                value={title}
                                onChange={handleOnChange}
                                minRows={2}
                                placeholder='Add an item'
                                isCompleted={checklistItem.isCompleted}
                                onKeyDown={handleKeyDown}
                                onFocus={handleOnFocus}
                            />
                            <ButtonContainer>
                                <Button
                                    compact
                                    color='blue'
                                    size='small'
                                    onMouseDown={handleOnSave}>
                                    Save
                                </Button>
                                <IconButton onClick={handleClear}>
                                    <MdClear />
                                </IconButton>
                            </ButtonContainer>
                        </>
                    ) : (
                        <DisplayContainer>
                            <StyledText
                                isCompleted={checklistItem.isCompleted}
                              
                            >{title}</StyledText>
                        </DisplayContainer>
                    )
                    }
                </LabelContainer>
            </ComponentWrapper>
            <ComponentWrapper >
                {activechecklistItemId === checklistItem.id ? null :
                    <DeleteContainer>
                        <DisplayContainer>
                            <DeleteIcon onClick={handleDelete} />
                        </DisplayContainer>
                    </DeleteContainer>
                }
            </ComponentWrapper>
        </ItemContainer>
       
    );
}

export default ChecklistItem;