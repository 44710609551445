import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Form, Icon, Label } from "semantic-ui-react";
import styled from 'styled-components';
import { useStore } from "../../stores/store";

// #region Styling

const NewMessageContainer = styled.div<{ isOpen: boolean }>`
    && {
        display: ${p => p.isOpen ? 'block' : 'none'};
        overflow-y: auto;
        position: fixed;
        right: 15px;
        bottom: 0px;
        background-color: #fff;
        width: 350px;
        height: 450px;
        box-shadow: 0 0 5px #ccc;
        border-radius: 5px 5px 0 0;
    }
`;

const StyledLabel = styled(Label)`
    && {
        background-color: #f6fbff;
        height: 43px;
        font-size: 14px;
        padding: .6em .9em;
        bottom-margin: solid 1px #d1dbe3;
        font-family: Helvetica Neue,Segoe UI,Helvetica,Arial,sans-serif;
    }
`;

const StyledButton = styled(Button)`
    && {
        
        width: 100%;
        position: absolute  !important;
        bottom: 0;
        height: 42px;
        border-radius: 0 !important;
    }

`;
// #endregion

const NewMessage = () => {
    const { chatStore, employeeStore, messageStore, conversationStore } = useStore();
    const { searchEmployee, searchingEmployee, searchedEmployees } = employeeStore;
    const { newMessageOpen, setNewMessageOpen, selectedUser,
        messageContent, isValidating, setIsValidating, setMessageContent,
        setSelectedUser, hideChatBox, showMessageBox, connectionId } = chatStore;
    const { isMessageSending, postMessage, loadMessage, addMessageWithAttachment } = messageStore;
    const { getCoversationById, setSelectedConversation } = conversationStore;
    const handleSearchChange = (e, { searchQuery }) => {
        searchEmployee(searchQuery);
    }
    const [isLoading, setIsLoading] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const handleDropDownInputChange = (e, data) => {
        const { value } = data;
        value ? setSelectedUser(value) : setSelectedUser("");

        if (messageContent && value) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }

    const handleTextAreaChange = (e, data) => {
        const { value } = data;
        setMessageContent(value);
        if (value && selectedUser) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }

    const handlePostMessage = () => {
        setIsValidating(true);
        setIsLoading(true);
        if (messageContent && selectedUser) {
            addMessageWithAttachment(
                {
                    content: messageContent,
                    receiversId: [selectedUser],
                    files: []
                }
            ).then(async (data) => {
                if (data) {
                    await getCoversationById(data.conversationId!)
                        .then(conversation => {
                            loadMessage(conversation?.id!);
                            setSelectedConversation(conversation!);
                            setNewMessageOpen(false);
                            hideChatBox();
                            showMessageBox();
                            setIsValidating(false);
                            setIsLoading(false);
                        });
                }
            }, e => {
                toast.error(e);
                setIsLoading(false);
            });
            //postMessage({
            //    content: messageContent,
            //    receiversId: [selectedUser],
            //    connectionId: connectionId
            //}).then(async (data) => {
            //    if (data) {
            //        await getCoversationById(data.conversationId)
            //            .then(conversation => {
            //                loadMessage(conversation?.id!);
            //                setSelectedConversation(conversation!);
            //                setNewMessageOpen(false);
            //                hideChatBox();
            //                showMessageBox();
            //                setIsValidating(false);
            //                setIsLoading(false);
            //            });
            //    }
            //}, e => {
            //    toast.error(e);
            //    setIsLoading(false);
            //})
        } else {
            setIsLoading(false);
        }
    }

    const onNewMessageClose = () => {
        setNewMessageOpen(false);
    }

    useEffect(() => {
        return () => {
            employeeStore.reset();
        
        }
    }, [])

    useEffect(() => {
        if (newMessageOpen)
            searchEmployee("");
    }, [newMessageOpen]);

    return (
        <NewMessageContainer isOpen={newMessageOpen} >
            <StyledLabel attached='top'>
                New Chat
                <Icon onClick={onNewMessageClose} style={{ float: "right" }} size='small' name='delete' />
            </StyledLabel>
            <Form style={{ paddingTop: 13, height: '93%' }} autoComplete='off'>
                <Form.Dropdown
                    clearable
                    fluid
                    selection
                    search
                    required
                    closeOnChange
                    placeholder='Select Receiver...'
                    onSearchChange={handleSearchChange}
                    loading={searchingEmployee}
                    value={selectedUser}
                    options={searchedEmployees.map(e => {
                        return {
                            key: e.id,
                            text: e.name,
                            value: e.id
                        };
                    })}
                    onChange={handleDropDownInputChange}
                    error={(selectedUser === "" || selectedUser === undefined) && isValidating ? {
                        content: 'Select a receiver.',
                        pointing: 'above'
                    } : false}
                    style={{ margin: '5px', width: '97%' }}
                />
                <Form.TextArea
                    value={messageContent}
                    style={{ height: 221, margin: '5px', width: '97%' }}
                    error={!messageContent && isValidating ? {
                        content: 'Message field is required.',
                        pointing: 'above'
                    } : false}
                    required
                    placeholder='Message...'
                    onChange={handleTextAreaChange}
                />
            </Form>
            <StyledButton
                color='blue'
                content='Send'
                labelPosition='right'
                icon='send'
                loading={isMessageSending || isLoading}
                disabled={!isValid || isMessageSending || isLoading}
                onClick={handlePostMessage}
            />
        </NewMessageContainer>
    )
}

export default observer(NewMessage);