import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Grid, Header, Tab } from 'semantic-ui-react';
import PaginatedTable from '../../../app/common/table/PaginatedTable';
import DateHelper from '../../../app/helpers/dateHelper';
import { FilterValues } from '../../../app/models/common/filterValues';
import { Holiday } from '../../../app/models/employee';
import { useStore } from '../../../app/stores/store';

const dateHelper = new DateHelper();

interface Props {
    employeeId: string;
}

const EmployeeHoliday = ({ employeeId}: Props) => {
    const { employeeStore } = useStore();
    const { isHolidayLoaded, holidays, holidaysTotal, loadEmployeeHolidays } = employeeStore;

    const [filterValues, setFilterValues] = useState<FilterValues>({
        pageSize: 20,
        pageNumber: 1
    });

    const handleOnChangePage = (page: number) => {
        setFilterValues({ ...filterValues, pageNumber: page })
    }

    useEffect(() => {
        loadEmployeeHolidays(filterValues, employeeId);
    }, [filterValues, loadEmployeeHolidays])

    return (
        <Tab.Pane>
            <Grid divided='vertically'>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header>Holidays</Header>
                        <PaginatedTable<Holiday>
                                columns={[
                                { title: 'Date', render: (data: Holiday) => dateHelper.formatShortDate(data.date.toString()) },
                                { title: 'Day', render: (data: Holiday) => dateHelper.getDayOfWeek(new Date(data.date)) },
                                    { title: 'Name', field: 'name' },
                                    { title: 'Type', field: 'holidayType.name' },
                                ]}
                                data={holidays!}
                                total={holidaysTotal}
                                onChangePage={handleOnChangePage}
                                pageSize={filterValues.pageSize}
                                pageNumber={filterValues.pageNumber}
                                isLoading={!isHolidayLoaded}
                                style={{ boxShadow: 'none', padding: 0, marginTop: '30px' }}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Tab.Pane>
    )
}
export default observer(EmployeeHoliday);
