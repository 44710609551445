import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agents";
import { FilterValues } from "../models/common/filterValues";
import { EditPostForm, Post, PostForm } from "../models/post";
import { store } from "./store";

export default class PostStore {
    posts: Post[] = [];
    filters: FilterValues = {
        pageNumber: 0,
        pageSize: 10
    }
    isLoadingPosts = false;

    constructor() {
        makeAutoObservable(this);
    }

    get = async () => {
        this.isLoadingPosts = true;
        this.filters.pageNumber++;
        let posts = await agent.Posts.filter(this.filters.pageNumber, this.filters.pageSize, store.userStore.user?.clientId);

        runInAction(() => {
            this.posts = [...this.posts, ...posts]
            this.isLoadingPosts = false;
        })
    }

    add = ({ message, clientId, files }: PostForm) => {
        return new Promise((resolve, reject) => {
            try {
                agent.Posts.post({
                    message: message,
                    clientId: clientId
                }).then(async postId => {
                    if (files && files.length > 0) {
                        let postAttachmentsPromise = new Array<Promise<any>>();

                        files.forEach(file => {
                            let formData = new FormData();
                            formData.append(`File`, file);
                            formData.append(`PostId`, postId);

                            let promise = agent.PostAttachments.upload(formData);

                            postAttachmentsPromise.push(promise);
                        });

                        Promise.all(postAttachmentsPromise).then(async () => {
                            let post = await agent.Posts.getById(postId);
                            console.log(post);
                            this.posts = [post, ...this.posts];
                            resolve(postId);
                        })
                    }
                    else {
                        let post = await agent.Posts.getById(postId);
                        this.posts = [post, ...this.posts]
                        resolve(postId);
                    }
                }).catch(e => {
                    console.log(e);
                    reject(e);
                })

            } catch (e) {
                console.log(e);
                reject(e);
            }
        });
    }

    update = async (post: EditPostForm) => {
        try {
            let promises = new Array<Promise<any>>();

            let update = agent.Posts.update({
                id: post.id,
                message: post.message
            });

            promises.push(update);

            post.deletedFilesIds?.forEach(id => {
                promises.push(agent.PostAttachments.delete(id))
            });

            post.files?.forEach(file => {
                let formData = new FormData();
                formData.append(`File`, file);
                formData.append(`PostId`, post.id);

                let promise = agent.PostAttachments.upload(formData);

                promises.push(promise);
            });

            try {
                await Promise.all(promises);

                let updatedPost = await agent.Posts.getById(post.id);
                this.posts = this.posts.map(post => {
                    if (updatedPost.id !== post.id) {
                        return post;
                    }

                    return updatedPost;
                });
            } catch (e) {
                toast.error('Post exceeded the maximum limit');
                Promise.reject(e);
            }
        } catch (e) {
            Promise.reject(e);
        }
    }

    delete = async (postId: string) => {
        try {
            await agent.Posts.delete(postId);
            this.posts = this.posts.filter(p => p.id !== postId);
        } catch (e) {
            console.error(e);
        }
    }

    getAttachements = async (id: string) => {
        return await agent.Posts.getAttachments(id);
    }

    resetPosts = () => {
        this.posts = [];
        this.filters = {
            pageSize: 10,
            pageNumber: 0
        }
    }
}