import { useEffect, useState } from 'react';
import { AiFillCloseSquare } from 'react-icons/ai';
import FileHelper from '../../../src/app/helpers/fileHelper';
import { useStore } from '../../app/stores/store';
import LoadingAttachment from './LoadingAttachment';

interface Props {
    id: string
    blob?: Blob
    fileName?: string
    onClose?: (id: string, fileName: string) => void
    onClick?: () => void
    onImagesRendered?: (uri: string) => void
    overlay?: string
}

const ImageAttachment = ({ id, blob, onClose, fileName, onClick, onImagesRendered, overlay }: Props) => {
    const { mediaStore } = useStore();
    const [dataUri, setDataUri] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!blob) {
            mediaStore.getPostAttachment(id)
                .then(media => {
                    if (media) {
                        if (onImagesRendered)
                            onImagesRendered(media.url);

                        setDataUri(media.url);
                        setLoading(false);
                    }
                });
        }
        else {
            FileHelper.fileToDataUri(blob).then(base64 => {
                setLoading(false);
                setDataUri(base64 as string);
            })
        }
    }, [])

    if (loading) return <LoadingAttachment />

    return (
        <div onClick={onClick} className={`post-attachment-image ${onClick ? ' clickable' : ''}`} >
            <img src={dataUri || '/assets/default-profile-picture.svg'} />
            {onClose &&
                <AiFillCloseSquare
                    className='close-icon'
                    onClick={() => {
                        if (onClose) onClose(id, fileName || "");
                    }}
                />
            }
            {overlay && <label className='image-overlay'>+{overlay}</label>}
        </div>
    )
}

export default ImageAttachment;