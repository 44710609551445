import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { BsCheck2Square } from 'react-icons/bs';
import { Item } from 'semantic-ui-react';
import styled from 'styled-components';
import { CardChecklist } from '../../../app/models/cardChecklist';
import ChecklistItem from './ChecklistItem';
import ChecklistProgress from './ChecklistProgress';
import ChecklistTitleInput from './ChecklistTitleInput';
import ItemTitleInput from './ItemTitleInput';

//#region Styling
const ComponentWrapper = styled.div`
    && {
        margin-bottom: 24px;
        display:block
    }
`;

const ItemContent = styled(Item.Content)`
    &&{
        margin: 0 !important;
        width:100%;
    }
`;

const StyledItem = styled(Item)`
    &&{
        margin:16px 0 0 0 !important;
        width:100%;
        display:flex;
    }
`;

const ChecklistIcon = styled(BsCheck2Square)`
    margin: 4px 16px 0 0;
    font-size: 20px;
    color:#42526e;
`;

//#endregion

interface Props {
    checklist: CardChecklist;
    activeChecklistId?: string;
    handleTitleFocus?: (activeCheckListId?: string) => void;

    activeItemId?: string;
    handleItemFocus?: (activeCheckListId?: string) => void;
    onCancelItemEdit?: () => void;

    activeAddItemChecklistId?: string;
    handleAddItemTitleFocus?: (activeAddItemChecklistId?: string) => void;
    onCancelTitleEdit?: () => void;
}

const Checklist = ({ activeAddItemChecklistId, handleAddItemTitleFocus, handleTitleFocus, activeChecklistId, handleItemFocus, activeItemId, checklist, onCancelTitleEdit, onCancelItemEdit }: Props) => {
 
    return (
        <ComponentWrapper>
            <StyledItem>
                <ChecklistIcon />
                <ItemContent>
                    <ChecklistTitleInput activeChecklistId={activeChecklistId} onFocus={handleTitleFocus} checklist={checklist} onCancel={onCancelTitleEdit} />
                </ItemContent>
            </StyledItem>
            <ChecklistProgress items={checklist.items ?? []} />
            {checklist.items && checklist.items.map(c => (
                <ChecklistItem activechecklistItemId={activeItemId} onFocus={handleItemFocus} key={c.id} checklistItem={c} onCancel={onCancelItemEdit} />
            ))}
            <ItemTitleInput activechecklistId={activeAddItemChecklistId} onFocus={handleAddItemTitleFocus} cardId={checklist.cardId} cardChecklistId={checklist.id} />
        </ComponentWrapper>
    );
}

export default observer(Checklist);