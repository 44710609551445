import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { makeAutoObservable, runInAction } from 'mobx';
import { MultiValue } from 'react-select';
import agent from '../api/agents';
import { AddUsersToConversation, Conversation } from '../models/conversation';
import { Employee } from '../models/employee';
import { Message } from '../models/message';
import { OptionSelect } from '../models/option';
import { User } from '../models/user';
import { store } from './store';

export default class ChatStore {
    hubConnection: HubConnection | null = null;
    charUrl = process.env.REACT_APP_CHAT_URL;
    connectionId = "";

    contacts: User[] = [];
    employeesByConversation: User[] = [];
    employeesOnSelectedConversation: User[] = [];
    isContactsLoading: boolean = false;
    isEmployeesByConversationLoading: boolean = false;
    isEmployeesOnSelectedConversationLoading: boolean = false;
    selectedOption: MultiValue<{ value: string }> = [];

    isLoading = false;
    messageBoxShow = false;

    newMessageOpen = false;
    newGroupMessageOpen = false;
    isChatShow = false;
    selectedUser = "";
    selectedUsers: string[] = [];
    messageContent = "";
    groupName = "";
    isValidating = false;
    unReadConversationIds: string[] = [];
    notificationCount: number = 0;
    hasBackgroundColor: boolean = true;
    isDeletingMember = false;

    isImageAttachmentLoading = false;
    isMymessage = false;
    constructor() {
        makeAutoObservable(this);
    }

    createHubConnection = () => {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(this.charUrl + '', {
                accessTokenFactory: () => store.authStore.token!,
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.None)
            .build();

        this.hubConnection?.start().catch(e => console.log(e));

        this.hubConnection.on('RecieveConnectionId', (connectionId: string) => {
            this.connectionId = connectionId;
        })
        this.hubConnection.on('ReceiveOnUpdateConversationName', (conversation) => {
            store.conversationStore.selectedConversation!.name = conversation.name
            store.conversationStore.loadMoreConversation({ pageNumber: 0, pageSize: 0, searchKeyword: "" });
        });
        this.hubConnection.on('ReceiveOnRemove', (conversation) => {
            store.conversationStore.conversations = store.conversationStore.conversations.filter(c => {
                if (c.id != conversation.id) {
                    return c;
                }
            });
            if (conversation.id == store.conversationStore.selectedConversation?.id) {
                this.hideMessageBox()
                store.messageStore.setMessages([]);
            }
            
            
        });
        this.hubConnection.on('ReceiveAddedToConversation', (conversation) => {
            store.conversationStore.loadMoreConversation({ pageNumber: 0, pageSize: 0, searchKeyword: "" });

        });
        this.hubConnection.on('ReceiveOnUpdate', (conversation) => {
            this.getUsersByConverSation(conversation.id);
        });

        this.hubConnection.on("ReceiveMessage", (message: Message) => {
            this.isMymessage = false;
            if (message.senderId != store.userStore.user?.id) {
                store.conversationStore.loadMoreConversation({ pageNumber: 0, pageSize: 0, searchKeyword: "" });
            }
            if (message.conversationId == store.conversationStore.selectedConversation?.id) {
                store.messageStore.readMessages([message.id]);
            } 

            runInAction(() => {
                const conversation = store.conversationStore.conversations.find(c => c.id === message.conversationId);

                if (conversation) {
                    //store.conversationStore.conversations = [{
                    //    ...conversation,
                    //    isReadByReceiver: message.senderId == store.userStore.user?.id,
                    //    recentMessage: {
                    //        content: message.content,
                    //        date: message.createdAt,
                    //        sender: message.sender,
                    //        senderId: message.senderId,
                    //        createdAt: message.createdAt,
                    //        localTime: message.localTime,
                    //        sentDate: message.sentDate,
                    //        sentTime: message.sentTime,
                    //    },
                    //    readReceiptUserIds: message.readReceiptUserIds!,
                    //    notifiedReceiptUserIds: message.notifiedReceiptUserIds!
                    //}, ...store.conversationStore.conversations.filter(c => c.id !== message.conversationId)] as Conversation[];
                    store.conversationStore.loadMoreConversation({ pageNumber: 0, pageSize: 0, searchKeyword: "" });

                    let currentMessages = store.messageStore.messages;
                    if (message.senderId == store.userStore.user?.id) {
                        store.messageStore.messages = store.messageStore.messages.filter(m => {
                            if (!(m.loadingId == message.loadingId)) {
                                return m;
                            }
                        }) as Message[];
                    }
                        this.isMymessage = store.userStore.user?.id === message.senderId;
                    if (currentMessages.length && currentMessages[0].conversationId === message.conversationId) {
                        store.messageStore.messages = [...store.messageStore.messages,
                        {
                            ...message,
                            isMyMessage: store.userStore.user?.id === message.senderId,
                            isMessageUploading: false,
                        }];

                        store.messageStore.updateIsRead(message.conversationId!, store.userStore.user!.id);
                        store.conversationStore.updateNotificationReceipt([message.conversationId!], store.userStore.user!.id);
                    
                    }

                    if (this.unReadConversationIds.indexOf(message.conversationId!) == -1)
                        this.unReadConversationIds = [...this.unReadConversationIds, message.conversationId!]
                }
                
            });
          
        });

        this.hubConnection.on("ReceiveConversation", (conversation: Conversation) => {
            store.conversationStore.conversations = [
                conversation,
                ...store.conversationStore.conversations
            ]
            this.addConvesationIdToSession(conversation.id);
        });

    }

    stopHubConnection = () => {
        this.hubConnection?.stop().catch((error) => console.log("Error stopping connection: ", error));
    };


    addConvesationIdToSession = async (conversationId: string) => {
        try {
            this.hubConnection?.invoke("AddConversationToSessionAsync", conversationId);
        } catch (e) {
            console.log(e);
        }
    }

    showMessageBox = () => {
        this.messageBoxShow = true;
    }

    hideMessageBox = () => {
        this.messageBoxShow = false;
        store.conversationStore.selectedConversation = undefined;
        store.messageStore.unreadMessages = [];
    }

    setMessageContent = (value: string) => {
        this.messageContent = value;
    }
    setIsImageAttachmentLoading = (value: boolean) => {
        this.isImageAttachmentLoading = value;
    }

    setGroupName = (value: string) => {
        this.groupName = value;
    }

    setSelectedUser = (value: string) => {
        this.selectedUser = value;
    }

    setSelectedUsers = (value: string[]) => {
        this.selectedUsers = value;
    }

    setIsValidating = (state: boolean) => {
        this.isValidating = state;
    }

    setNewMessageOpen = (state: boolean) => {
        this.newMessageOpen = state;
        this.selectedUser = "";
        this.messageContent = "";
        this.isValidating = false;
    }

    setGroupNewMessageOpen = (state: boolean) => {
        this.newGroupMessageOpen = state;
        this.selectedUser = "";
        this.selectedUsers = [];
        this.messageContent = "";
        this.groupName = "";
        this.isValidating = false;
        this.setSelectedOption([]);
    }

    toggleChatBox = () => {
        this.isChatShow = !this.isChatShow;
    }

    showChatBox = () => {
        this.isChatShow = true;
    }

    hideChatBox = () => {
        this.isChatShow = false;
    }

    setNotificationCount = (value: number) => {
        this.notificationCount = value;
    }

    loadContacts = async (search?: string) => {
        this.isContactsLoading = true;
        try {
            const { total, employees } = await agent.Employees.filter({
                searchKeyword: search ? search : '',
                pageSize: 0,
                pageNumber: 0
            });
            runInAction(() => {
                //THIS WILL BE UPDATED TO USER UPON IMPLEMENTING SUBCLIENT MODULES.
                this.contacts = employees.map(e => {
                    return {
                        id: e.id,
                        firstName: e.firstName,
                        lastName: e.lastName,
                        hasProfilePicture: e.hasProfilePicture,
                        name: e.name,
                    } as User
                });
                this.isContactsLoading = false;
            });
        } catch (error) {
            this.isContactsLoading = false;
        }
    }
    loadEmployeesByConversationId = async (conversationId, search?: string) => {
        if (conversationId != null) {
            this.isEmployeesByConversationLoading = true;

            try {
                const { total, employees } = await agent.Conversations.filterEmployeeByConversation(conversationId, {
                    searchKeyword: search ? search : '',
                    pageSize: 0,
                    pageNumber: 0
                });
                runInAction(() => {
                    //THIS WILL BE UPDATED TO USER UPON IMPLEMENTING SUBCLIENT MODULES.
                    this.employeesByConversation = employees.map(e => {
                        return {
                            id: e.id,
                            firstName: e.firstName,
                            lastName: e.lastName,
                            hasProfilePicture: e.hasProfilePicture,
                            name: e.name,
                        } as User
                    });
                    this.isEmployeesByConversationLoading = false;
                });
            } catch (error) {
                this.isEmployeesByConversationLoading = false;
            }
        }
     
    }
    getUsersByConverSation = async (conversationId) => {
        if (conversationId != null) {
            this.isEmployeesOnSelectedConversationLoading = true;
            try {
                const employees  = await agent.Conversations.getUsersByConverSation(conversationId);
                runInAction(() => {
                    //THIS WILL BE UPDATED TO USER UPON IMPLEMENTING SUBCLIENT MODULES.
                    this.employeesOnSelectedConversation = employees.map(e => {
                        return {
                            id: e.id,
                            firstName: e.firstName,
                            lastName: e.lastName,
                            hasProfilePicture: e.hasProfilePicture,
                            name: e.name,
                        } as User
                    });
                    this.isEmployeesOnSelectedConversationLoading = false;
                });
            } catch (error) {
                this.isEmployeesOnSelectedConversationLoading = false;
            }
        }
       
    }
    addUsersToConversation = async (conversationId : string, users: string[]) => {
       
        try {
            let data = await agent.Conversations.addUsersToConversation(conversationId, users);
            runInAction(() => {
              
            });
            return Promise.resolve(data);
        } catch (e) {
            console.error(e);
     
            return Promise.reject(e);
        }
    }
    rename = async (conversationId: string, conversationName: string) => {

        try {
            let data = await agent.Conversations.rename(conversationId, conversationName);
            runInAction(() => {

            });
            return Promise.resolve(data);
        } catch (e) {
            console.error(e);

            return Promise.reject(e);
        }
    }
    deleteMember = async (conversationId: string, userId: string) => {
        this.isDeletingMember = true;
        try {
           await agent.Conversations.deleteUser(conversationId, userId);
            runInAction(() => {
                this.isDeletingMember = false;
            });
           
        } catch (e) {
            console.error(e);
            return Promise.reject(e);
        }
    }

    get contactsOptionValues(): OptionSelect[] {
        if (this.contacts.length) {
            return this.contacts.map(e => { return { id: e.id, value: e.name, label: e.name } as OptionSelect });
        } else {
            return [];
        }
    }
    get employeesIncludedOnConversation() {
        let hasBackGroundColor = false;
        if (this.employeesOnSelectedConversation.length) {
            return this.employeesOnSelectedConversation.map(e => { hasBackGroundColor = !hasBackGroundColor; return { id: e.id, hasPhoto: e.hasProfilePicture, name: e.name, hasBg: hasBackGroundColor } });
        } else {
            return [];
        }
    }
    get employeessOptionValues(): OptionSelect[] {
        if (this.contacts.length) {
            return this.employeesByConversation.map(e => { return { value: e.id, label: e.name } as OptionSelect });
        } else {
            return [];
        }
    }
    setSelectedOption = (values: MultiValue<{ value: string }>) => {
        this.selectedOption = values;
    }
    setHasBackgroundColor = (value) => {
        this.hasBackgroundColor = value;
     
    }
    toggleHasBackgroundColor = () => {
        this.hasBackgroundColor = !this.hasBackgroundColor;

        return this.hasBackgroundColor;
    }
    dataURLtoFile = (dataurl, filename) => {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

}