import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { FaCheck, FaFileDownload, FaRegEye, FaTimes } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { Header } from "semantic-ui-react";
import styled from "styled-components";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TableColumnPopover from "../../app/common/popover/TableColumnPopover";
import ViewRequestAttachment from "../../app/common/request-attachment/ViewRequestAttachment";
import PaginatedTable from "../../app/common/table/PaginatedTable";
import DateHelper from '../../app/helpers/dateHelper';
import { FilterValues } from "../../app/models/common/filterValues";
import { TimeAdjustmentModel } from "../../app/models/timeAdjustment";
import { useStore } from "../../app/stores/store";
import { history } from '../../index';

const dateHelper = new DateHelper();
const swal = withReactContent(Swal);

const StyledHeader = styled(Header)`margin-bottom: 20px !important;`;

const StyledContainer = styled.div`
        padding: ${props => props.theme.padding.featureContainer};
`;

interface RequestAttachment {
    attachmentId: string | null;
    attachmentType: string | null;
    attachmentName: string | null;
}

interface DownloadParameters {
    id: string;
    name: string
    type: string
}

const TimeAdjustmentList = () => {
    const { timeAdjustmentStore, mediaStore } = useStore();
    const { filterTimeAdjustment, timeAdjustments, isTimeAdjustmentListLoading, timeAdjustmentTotal, approve, reject } = timeAdjustmentStore;
    const { downloadAttachment } = mediaStore;
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const search = params.get("search");
    const page = params.get("page");
    const refresh = params.get("refresh");

    const [attachment, setAttachment] = useState<RequestAttachment | null>();
    const [filterValues, setFilterValues] = useState<FilterValues>({
        pageSize: 20,
        pageNumber: Number(page) !== 0 ? Number(page) : 1,
        searchKeyword: search ?? ""
    });

    const handleSearch = (searchText: string) => {
        params.set('search', searchText);
        params.set('page', "1");
        params.set('refresh', (Math.random() + 1).toString(36).substring(7));

        history.push({
            search: params.toString(),
        });

        if (filterValues.searchKeyword != searchText) {
            setFilterValues({ ...filterValues, pageNumber: 1, searchKeyword: searchText.trim() });
        } else {
            setFilterValues({ ...filterValues, searchKeyword: searchText.trim() });
        }
    }

    const handleOnChangePage = (page: number) => {
        params.set('page', page.toString());

        history.push({
            search: params.toString(),
        });

        setFilterValues({ ...filterValues, pageNumber: page })
    }

    const handleDownloadAttachment = ({ id, name, type }: DownloadParameters) => {
        if ((!type.includes('video') && !type.includes('image'))) {

            downloadAttachment(id, name);

        } else {
            setAttachment({ attachmentId: id, attachmentType: type, attachmentName: name })
        }
    }

    const handleCloseAttachment = () => {
        setAttachment(null);
    }

    useEffect(() => {
        setFilterValues({ ...filterValues, searchKeyword: search ?? "", pageNumber: Number(page) !== 0 ? Number(page) : 1 });
        filterTimeAdjustment({ ...filterValues, searchKeyword: search ?? "", pageNumber: Number(page) !== 0 ? Number(page) : 1 });
    }, [search, page, refresh])

    return (
        <>
            {attachment && <ViewRequestAttachment
                onPreviewClose={handleCloseAttachment}
                attachmentId={attachment.attachmentId!}
                attachmentType={attachment.attachmentType!}
                attachmentName={attachment.attachmentName!}
            />}
            <StyledContainer>
                <StyledHeader>
                    Time Adjustment
                </StyledHeader>
                <PaginatedTable<TimeAdjustmentModel>
                    columns={[
                        {
                            title: 'Employee',
                            field: 'user.name',
                            cellStyle: { width: '600px' }
                        },
                        { title: 'Adjustment Type', field: 'timeAdjustmentType.type' },
                        {
                            title: 'Requested Time',
                            cellStyle: { width: '450px' },
                            render: (data: TimeAdjustmentModel) => dateHelper.formatDateTime(data.dateTime.toString())
                        },
                        {
                            title: 'Date Filed',
                            cellStyle: { width: '450px' },
                            render: (data: TimeAdjustmentModel) => dateHelper.formatShortDate(data.dateFiled.toString())
                        },
                        {
                            title: 'Reason',
                            render: (data: TimeAdjustmentModel) => (
                                <TableColumnPopover width={ 200} content={data.reason} />
                            )
                        },
                        {
                            title: 'TL Approval',
                            field: 'teamLeadApprovalStatus.name'
                        },
                        {
                            title: 'HR Approval',
                            field: 'timeAdjustmentStatus.name'
                        },
                        {
                            title: 'Status',
                            field: 'clientApprovalStatus.name'
                        },
                        {
                            title: 'Date Approved',
                            cellStyle: { width: '450px' },
                            render: (data: TimeAdjustmentModel) => dateHelper.formatShortDate(data.dateApproved ? data.dateApproved.toString() : "")
                        },
                    ]}
                    isLoading={isTimeAdjustmentListLoading}
                    data={timeAdjustments!}
                    total={timeAdjustmentTotal}
                    onChangePage={handleOnChangePage}
                    pageSize={20}
                    sorting={false}
                    draggable={false}
                    pageNumber={filterValues.pageNumber}
                    onSearchChange={handleSearch}
                    toolbar={true}
                    search={true}
                    actions={[rowData => ({
                        icon: () => <FaFileDownload style={{ color: '#21ba45', fontSize: '16px' }} />,
                        tooltip: rowData.file != null ? rowData.file.name : '',
                        onClick: e => {
                            handleDownloadAttachment(rowData.file);
                        },
                        hidden: !rowData.file,
                    }),
                    rowData => ({
                        icon: () => <FaCheck style={{ color: '#97a4b5', fontSize: '16px' }} />,
                        tooltip: 'Approve',
                        onClick: e => {
                            swal.fire({
                                title: 'Approval Confirmation',
                                text: "Are you sure you want to approve this time adjustment request?",
                                icon: 'question',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Confirm'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    approve(rowData.id, filterValues);
                                }
                            });
                        },
                        hidden: rowData.clientApprovalStatus.name != 'Pending',
                    }),
                    rowData => ({
                        icon: () => <FaTimes style={{ color: '#97a4b5', fontSize: '16px' }} />,
                        tooltip: 'Reject',
                        onClick: e => {
                            swal.fire({
                                title: 'Rejection Confirmation',
                                text: "Are you sure you want to reject this time adjustment request?",
                                icon: 'question',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Confirm'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    reject(rowData.id, filterValues);
                                }
                            });
                        },
                        hidden: rowData.clientApprovalStatus.name != 'Pending',
                    }),
                    rowData => ({
                        icon: () => <FaRegEye style={{ color: '#97a4b5', fontSize: '18px' }} />,
                        tooltip: 'View Details',
                        onClick: e => {
                            history.push(`/requests/time-adjustments/${rowData.id}`)
                        },
                    }),
                    ]}
                />
            </StyledContainer>

        </>

    );
}

export default observer(TimeAdjustmentList);