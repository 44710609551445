import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { BsPlusLg } from 'react-icons/bs';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { useStore } from '../../../app/stores/store';
import CardMember from '../card-members/CardMember';


const Container = styled.div`
    && {
        display: flex;
        padding: 0 5px;
        flex-wrap: wrap;
        justify-content: flex-start;
    
    }
`;


interface Props {
    cardId: string;
    createdUserId: string;
}


const CardMembersContainer = ({ cardId, createdUserId }: Props) => {

    const { cardMemberStore, } = useStore();
    const { cardMembers, loadCardMembers, isLoadingCardMembers, setIsOpenPopOver } = cardMemberStore;

    useEffect(() => {
        loadCardMembers(cardId);
    }, [cardId]);

    if (isLoadingCardMembers) return <LoadingComponent content="Loading Cards..." />

    const handleClick = () => setIsOpenPopOver(true);

    return (
        <Container>
            {
                cardMembers.map(user => <CardMember key={user.id} user={user} cardId={cardId} createdUserId={createdUserId} />)
            }
            {cardMembers.length == 0 ? null : <Button
                icon
                style={{
                    borderRadius: "50%",
                    margin: '15px 5px',
                    height: "30px",
                    width: "30px"
                   
                }}
                size='mini'
                onClick={handleClick}
            >
                <Icon name='plus' />
            </Button>}
         
        </Container>
    );
}

export default observer(CardMembersContainer);