import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { Grid, Header, Tab, Table } from 'semantic-ui-react';
import DateHelper from '../../../app/helpers/dateHelper';
import { useStore } from '../../../app/stores/store';

const dateHelper = new DateHelper();

const EmployeeAdditionalDetails = () => {
    const { employeeStore } = useStore();
    const { workExperiences, educationRecords } = employeeStore;

    return (
        <Tab.Pane>
            <Grid divided='vertically'>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header>Work Experience</Header>
                        <Table striped style={{ padding: "2em 0", border: 'none' }}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        Previous Company
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Job Title
                                    </Table.HeaderCell>
                                    <Table.HeaderCell >
                                        From
                                    </Table.HeaderCell>
                                    <Table.HeaderCell >
                                        To
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {workExperiences && workExperiences.length != 0 ?
                                    workExperiences.map(({ companyName, jobTitle, from, to }) => (
                                        <Table.Row key={companyName}>
                                            <Table.Cell>{companyName}</Table.Cell>
                                            <Table.Cell>{jobTitle}</Table.Cell>
                                            <Table.Cell>{dateHelper.formatMonthYear(from.toString())}</Table.Cell>
                                            <Table.Cell>{dateHelper.formatMonthYear(to.toString())}</Table.Cell>
                                        </Table.Row>
                                    ))
                                    : (<Table.Row><Table.Cell colSpan='5'>
                                        <Header as='h4' style={{ textAlign: 'center', fontWeight: 600, padding: '1em' }}>
                                            No Records
                                        </Header>
                                    </Table.Cell></Table.Row>)
                                }
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header>Educational Background</Header>
                        <Table striped style={{ padding: "2em 0", border: 'none'  }}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        School
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Education Type
                                    </Table.HeaderCell>
                                    <Table.HeaderCell >
                                        Degree
                                    </Table.HeaderCell>
                                    <Table.HeaderCell >
                                        From
                                    </Table.HeaderCell>
                                    <Table.HeaderCell >
                                        To
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {educationRecords && educationRecords.length != 0 ?
                                    educationRecords.map(({ schoolName, educationType, degree, from, to }) => (
                                        <Table.Row key={schoolName}>
                                            <Table.Cell>{schoolName}</Table.Cell>
                                            <Table.Cell>{educationType.type}</Table.Cell>
                                            <Table.Cell>{degree}</Table.Cell>
                                            <Table.Cell>{dateHelper.formatMonthYear(from.toString())}</Table.Cell>
                                            <Table.Cell>{dateHelper.formatMonthYear(to.toString())}</Table.Cell>
                                        </Table.Row>
                                    ))
                                    : (<Table.Row><Table.Cell colSpan='5'>
                                        <Header as='h4' style={{ textAlign: 'center', fontWeight: 600, padding: '1em' }}>
                                            No Records
                                        </Header>
                                    </Table.Cell></Table.Row>)
                                }
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Tab.Pane>
    );
}

export default observer(EmployeeAdditionalDetails);