import { observer } from 'mobx-react-lite';
import moment from 'moment';
import * as React from 'react';
import { AiOutlineMenu } from "react-icons/ai";
import Moment from 'react-moment';
import { Link, NavLink } from 'react-router-dom';
import { Container, Dropdown, Header, Icon, Image, Label, Menu } from 'semantic-ui-react';
import AvatarImage from '../../common/image/AvatarImage';
import { useStore } from '../../stores/store';

import useSound from 'use-sound';
import popSound from '../../../assets/audio/sfx-pop3.mp3';
import { useEffect, useRef } from 'react';
import TimeZoneModal from '../../../features/time-zones/TimeZoneModal';


function useOuterClick(callback: any) {
    const callbackRef = useRef<any>(); // initialize mutable ref, which stores callback
    const innerRef = useRef<any>(); // returned to client, who marks "border" element

    // update cb on each render, so second useEffect has access to current value 
    useEffect(() => { callbackRef.current = callback; });

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick);

        function handleClick(ev: MouseEvent | TouchEvent) {
            if (innerRef.current && callbackRef.current

            ) callbackRef!.current!(ev);

            return false;

        }
    }, []); // no dependencies -> stable click listener

    return innerRef; // convenience for client (doesn't need to init ref himself) 
}

const NavBar = () => {
    const [playNotificationSound] = useSound(popSound);
    const { authStore, commonStore, userStore, chatStore, conversationStore, notificationStore, timeZoneStore } = useStore();
    const { toggleChatBox, isChatShow } = chatStore;
    const { toggleNotificationBox, unreadCount, isNotifcationShow, markRead } = notificationStore;
    const { pendingNotificationConversationIds, isLoadingConversation } = conversationStore;
    const { updateNotificationReceipt } = conversationStore;
    const { user } = userStore;
    const { toggleSidebar, isNotificationPage } = commonStore;
    const [isInitial, setIsInitial] = React.useState(true);

    const [showProfileMenu, toggleshowProfileMenu] = React.useState(false);
    const handleClickShowProfileMenu = () => {
        toggleshowProfileMenu(!showProfileMenu);
    };

    const handleClickOutside = () => {
        toggleshowProfileMenu(false)
    }

    const handleLogoutClick = () => {
        notificationStore.reset();
        authStore.logout();
    }

    const onChatBoxMenuClick = () => {
        toggleChatBox();
        if (pendingNotificationConversationIds.length) {
            updateNotificationReceipt(pendingNotificationConversationIds, userStore.user!.id);
        }
    }
    const onNotificationClick = () => {
        if (!isNotifcationShow) markRead();

        toggleNotificationBox();
    }

    let dateToday = user?.timeZone ? moment().tz(user?.timeZone) : moment();

    const innerRef = useOuterClick(
        (ev: any) => {

            if (!ev.target.classList.contains('dropdown-button')) {
                handleClickOutside();
            }
        }
    );

    useEffect(() => {
        return () => {
            conversationStore.reset();

        }
    }, [])
    //useEffect(() => {
    //    document.addEventListener('mousedown', handleClickOutside);
    //}, [showProfileMenu])
    useEffect(() => {
        if (!isInitial && !isLoadingConversation && pendingNotificationConversationIds.length)
            playNotificationSound();

        if (pendingNotificationConversationIds.length)
            setIsInitial(false);

    }, [pendingNotificationConversationIds]);

    return (
        <>
            <Menu fixed='top' borderless style={{ boxShadow: 'none' }} >
                <Container fluid >
                    <Menu.Item onClick={toggleSidebar} style={{ width: '80px', display: 'flex', justifyContent: 'center' }}>
                        <AiOutlineMenu fontSize='1.7em' />
                    </Menu.Item>
                    <Menu.Item as={Link} to="/" exact header style={{ paddingLeft: '.5em' }}>
                        <Image src='/assets/kasina-logo.svg' alt='logo' size='small' style={{ width: '135px' }} />
                    </Menu.Item>
                    <Menu.Menu position='right'>
                        <Menu.Item>
                            <Header as='h4' style={{ color: '#8d9eb4', fontWeight: '400' }}>
                                <Moment format='dddd, MMMM DD, YYYY' >
                                    {dateToday}
                                </Moment>
                            </Header>
                        </Menu.Item>
                        <Menu.Item className='chat-box-button' onClick={onChatBoxMenuClick} style={{ padding: '.9em' }} name='Messages'>
                            <Icon className='chat-box-button'
                                style={{
                                    backgroundColor: isChatShow ? '#1592ff' : '',
                                    color: isChatShow ? '#ffffff' : ''
                                }}
                                name='comment alternate outline' circular />
                            {pendingNotificationConversationIds.length ? (<Label color='red' size='mini'
                                style={{
                                    top: '.7em',
                                    left: '5em'
                                }}
                                circular floating>
                                {pendingNotificationConversationIds.length}
                            </Label>
                            ) : ''}
                        </Menu.Item>
                        <Menu.Item className='notification-box-button' onClick={onNotificationClick} style={{ padding: '.9em' }} name='Notifications'>
                            <Icon className='notification-box-button'
                                style={{
                                    backgroundColor: (isNotifcationShow || isNotificationPage) ? '#1592ff' : '',
                                    color: (isNotifcationShow || isNotificationPage) ? '#ffffff' : ''
                                }}
                                name='bell outline' circular />
                            {(unreadCount > 0 && !isNotificationPage) &&
                                <Label color='red' circular size='mini'
                                    style={{
                                        top: '.7em',
                                        left: '5em'
                                    }}
                                    floating>
                                    {unreadCount}
                                </Label>
                            }
                        </Menu.Item>
                        <Menu.Item className='small dropdown-button' style={{ padding: '.9em', marginRight: '1em', cursor: 'pointer' }} onClick={handleClickShowProfileMenu}  >
                            <AvatarImage userId={user?.id!} hasPhoto={user?.hasProfilePicture!} style={{ width: '40px', height: '40px' }} avatar circular className='small dropdown-button' />
                            <Dropdown ref={innerRef} open={showProfileMenu} icon='chevron down' onClick={handleClickShowProfileMenu} className='small dropdown-button' pointing='top right'>
                                <Dropdown.Menu >
                                    {/*  <Dropdown.Item text='Change Password' icon='user' />*/}
                                    <Dropdown.Item
                                        onClick={() => timeZoneStore.setModalOpen(true)}
                                        text='Time Zone'
                                        className='small dropdown-button'
                                        icon='time'
                                        style={{display: 'none'} }
                                    />
                                    <Dropdown.Item onClick={handleLogoutClick} className='small dropdown-button' text='Logout' icon='power' />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Item>
                    </Menu.Menu>
                </Container>
            </Menu>
            <TimeZoneModal />
        </>
    );
}

export default observer(NavBar);