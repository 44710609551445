import { observer } from 'mobx-react-lite';
import { Grid, Icon } from 'semantic-ui-react';
import styled from "styled-components";
import AvatarImage from "../image/AvatarImage";
import { Notification } from "../../models/notification";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { useStore } from "../../stores/store";
import parse from 'html-react-parser'
const Item = styled.div`
    && {
         display: flex;
         padding: 5px 5px 0px 5px;
         align-items: center;
         width: 100%;
         height: 66px;
         :hover {
                background-color: #f3f8fc;
            }

    }
`;

const NotificationContent = styled.div`
    && {
        font-size: 14px;
        line-height: 16px;
        width: 100%;
    }
`;

const NotificationTime = styled.div`
    && {
       margin: 3px 0px;
       font-size: 11px;
       color: #97a4b5;
    }
`;

interface Props {
    notification: Notification;
}

const NotificationPageItem = ({ notification }: Props) => {
    const { notificationStore: { hideNotificationBox, markOpened, generateContent, generateUrl, formatDate } } = useStore();

    const history = useHistory(),
        content = generateContent(notification),
        url = generateUrl(notification);

    const onItemClicked = () => {
        hideNotificationBox();
        markOpened(notification.id);
        history.push(url);
    };

    const getImage = () => {
        let clientId = "",
            userId = notification.actor.id,
            hasPhoto = notification.actor.hasProfilePicture;


        if (notification.otherActorId && notification.content.user.employeeCurrentJob?.clientId) {
            clientId = notification.content.user.employeeCurrentJob?.clientId;
            userId = notification.otherActorId;
            hasPhoto = true;
        }

        return (
            <AvatarImage
                userId={userId}
                hasPhoto={hasPhoto}
                clientId={clientId}
                circular
                style={{
                    width: 40,
                    height: 40,
                    objectFit: "cover",
                    border: '1px solid #1592ff'
                }}
            />
        )
    }

    return (
        <>
            <Item onClick={onItemClicked}>
                <Grid columns='equal' style={{
                    width: '100%',
                    margin: 0,
                    alignItems: 'center'
                }}>
                    <Grid.Column width={1}
                        style={{
                            padding: '0px 5px',
                        }}
                    >
                        {getImage()}
                    </Grid.Column>
                    <Grid.Column width={13}
                        style={{
                            padding: '0px 5px',
                            margin: '0px 10px'
                        }}
                    >
                        <NotificationContent>
                            {parse(content)}.
                            <NotificationTime>
                                {formatDate(notification.createdOn)}
                            </NotificationTime>
                        </NotificationContent>
                    </Grid.Column>
                    <Grid.Column width={1}
                        style={{
                            padding: '0px 5px',
                            textAlign: 'center',
                            marginLeft: '20px'
                        }}
                    >
                        <Icon
                            style={{
                                color: notification.opened ? '#bdbdbd' : '#1592ff',
                                fontSize: '8px'
                            }}
                            size='small' name='circle' />
                    </Grid.Column>

                </Grid>

            </Item>
            <hr
                style={{
                    border: '1px solid rgba(0,0,0,.1)',
                    width: "95%"
                }}
            />

        </>
    )
}

export default observer(NotificationPageItem);