import { observer } from 'mobx-react-lite';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { useStore } from '../../app/stores/store';

const StyledModalContent = styled(Modal.Content)`
    && {
        border-radius: 9px !important;
        background-color: #f4f5f7 !important;
        padding-left:0 !important;
        padding-right:0 !important;
    }
`;

const StyledModal = styled(Modal)`
    && {
        border-radius: 9px !important;
        background-color: #f4f5f7 !important;
    }
`;

function NotificationModalContainer() {
    const { notificationModalStore } = useStore()

    return (
        <StyledModal
            open={notificationModalStore.modal.open}
            onClose={notificationModalStore.closeModal}
            centered={true}
            size='mini'
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <StyledModalContent>
                {notificationModalStore.modal.body}
            </StyledModalContent>
        </StyledModal>
    );
}

export default observer(NotificationModalContainer);