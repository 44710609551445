import { useEffect, useRef, useState } from "react";
import agent from "../../app/api/agents";
import { Post as PostModel, PostLikes, PostForm as PostFormModel } from "../../app/models/post";
import CommentSection from "./CommentSection";
import PostActions from "./PostActions";
import PostAttachment from "./PostAttachment";
import PostHeader from "./PostHeader";
import PostStats from "./PostStats";
import PostMessage from "./PostMessage";
import PostForm from "./PostForm";
import { useStore } from "../../app/stores/store";
import { toast } from "react-toastify";

interface Props {
    post: PostModel
}

const Post = (props: Props) => {
    const { postStore } = useStore();
    const ref = useRef<any>(null);
    const [isLiked, setIsLiked] = useState(false);
    const [postLikes, setPostLikes] = useState<PostLikes>();
    const [isCommentActive, setIsCommentActive] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [removedAttactments, setRemovedAttachments] = useState<string[]>([]);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        agent.Posts.getLikes(props.post.id).then(postLikes => {
            setPostLikes(postLikes)
            setIsLiked(postLikes.liked);
        });
        setCount(props.post.commentCount);
    }, [isLiked]);

    const handleClickLike = () => {
        if (isLiked) {
            agent.PostLikes.delete(props.post.id).then(() => {
                setIsLiked(!isLiked);
            });
        }
        else {
            agent.PostLikes.post(props.post.id).then(() => {
                setIsLiked(!isLiked);
            });
        }
    }

    const handleClickComment = () => setIsCommentActive(!isCommentActive);

    const handleEditPost = () => setIsEditing(!isEditing);

    const hanldeEditCancel = () => {
        setIsEditing(!isEditing)
        ref.current.handleCancelEdit();
        setRemovedAttachments([]);
    }

    const handleAttachmentRemove = (id) => setRemovedAttachments(prev => [...prev, id]);

    const handleSubmitEditedPost = (post: PostFormModel) => {
        setIsLoading(true);
        const attachmentLength = ref.current.getOriginalPostAttachmentCount();

        if ((post.files && post.files?.length > 0)
            || (post.message && post.message.trim() && post.message.trim() != "<p></p>")
            || removedAttactments.length !== attachmentLength) {
            postStore.update({
                id: props.post.id,
                deletedFilesIds: removedAttactments,
                files: post.files,
                message: post.message ? post.message.trim() : ""
            }).then(() => {
                setRemovedAttachments([]);
                setIsEditing(false);
                setIsLoading(false);

                ref.current.retrieveAttachments();
            }).catch(e => {
                const { response } = e;
                setIsLoading(false);
                if (response.data.errors && response.data.status === 400) {
                    toast.error('Post exceeded the maximum limit.');
                }
            });
        }
        else {
            toast.error('You have nothing to post.');
            setIsLoading(false);
        }

    }

    const handleCommentChange = (number: number) => {
        setCount(number);
    };

    return (
        <div className='post'>
            {!isEditing ?
                <>
                    <PostHeader post={props.post} onClickEdit={handleEditPost} />
                    <PostMessage message={props.post.message} />
                </> :
                <PostForm isLoading={isLoading} onSubmit={handleSubmitEditedPost} message={props.post.message} isEditing={true} onClickCancel={hanldeEditCancel} />
            }
            <PostAttachment ref={ref} postId={props.post.id} isEditing={isEditing} onRemoveAttachment={handleAttachmentRemove} />
            <PostStats postLikes={postLikes!} commentCount={count} />
            <hr />
            <PostActions isLiked={isLiked} onClickLike={handleClickLike} onClickComment={handleClickComment} />
            <hr />
            <CommentSection
                postId={props.post.id}
                onCommentChanged={handleCommentChange}
                commentCount={count}
                isCommentActive={isCommentActive}
                onClickClose={handleClickComment}
            />
        </div>
    )
}

export default Post;