import { IconButton } from '@material-ui/core';
import { DH_CHECK_P_NOT_PRIME } from 'constants';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BsCardHeading, BsListUl, BsTextParagraph } from 'react-icons/bs';
import { MdClear } from 'react-icons/md';
import { Button, Divider, Grid, Header, Item } from 'semantic-ui-react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CardDate } from '../../../app/models/cardDate';
import { useStore } from '../../../app/stores/store';
import CardActivityList from '../card-activities/CardActivityList';
import AttachmentList from '../card-attachments/AttachmentList';
import Checklist from '../card-checklist/Checklist';
import CommentList from '../card-comments/CommentList';
import DateDetails from '../card-dates/DateDetails';
import CardMembersContainer from '../card-members/CardMemberContainer';
import AttachmentMenu from '../card-menus/AttachmentMenu';
import ChecklistMenu from '../card-menus/ChecklistMenu';
import DateMenu from '../card-menus/DateMenu';
import MembersMenu from '../card-menus/MembersMenu';
import PriorityMenu from '../card-menus/PriorityMenu';
import CardPlaceholder from './CardPlaceholder';
import DescriptionInput from './DescriptionInput';
import TitleInput from './TitleInput';

//#region Styling

const TitleIcon = styled(BsCardHeading)`
    margin: 2px 16px 0 0;
    font-size: 20px;
    color:#42526e;
`;

const DescriptionIcon = styled(BsTextParagraph)`
    margin: 4px 16px 0 0;
    font-size: 20px;
    color:#42526e;
`;

const ActivityIcon = styled(BsListUl)`
    margin: 4px 16px 0 0;
    font-size: 20px;
    color:#42526e;
`;

const ItemContent = styled(Item.Content)`
    &&{
        margin: 0 0 0 0 !important;
    }
`;
const ItemInListContent = styled(Item.Content)`
    &&{
        margin: -15px 0 0 47px !important;
    }
`;

const ItemInListContentPriority = styled(Item.Content)`
    &&{
        margin: 5px 0 0 47px !important;

        height: 23px;
        color: #767373;
        padding-top: 2px;
        padding-left: 10px;
        margin-bottom: 5px;
        font-size: 14px;
        word-break: break-word;
        width: 200px;
    }
`;

const StyledItem = styled(Item)`
    &&{
        margin:16px 0 !important;
    }
`;
const StyledMember = styled(Item)`
    &&{
        margin:0 0 16px 0 !important;
    }
`;

const Menu = styled(Button)`
    background:#091e4214 !important;
    margin-bottom: 8px !important;

    & i{
        background:none !important;
    }
`;

const ScheduleMenu = styled(Button)`
    background:#091e4214 !important;
    margin-bottom: 8px !important;
    padding: 5px 3px !important;
    font-weight: normal !important;
    font-size: 13px;

    & i{
        background:none !important;
    }
`;

const ScheduleCloseIconStyled = styled(IconButton)`
    && {
        right: -18px;
        position: absolute;
        top: -12px;
    }
`;

const getPriorityColor = (id) => {
    let color = "";

    switch (id) {
        case "7162b690-70cc-4754-b776-058bb1841dd9": //Low Priority
            color = "#2ecc71";
            break;
        case "1a45f09e-153e-48ce-a1d2-7aca99fb3bf2"://Medium Priority
            color = "#f1c40f";
            break;
        case "09058161-6b3e-41b3-9430-e3026eef86b4":// High Priority
            color = "#d35400";
            break;
        case "ff116d8c-42a2-4e81-b402-f0cf9253c8b2"://Urgent/Critical
            color = "#c0392b";
            break;
        default:
    }


    return color ? {
        borderLeft: `5px solid ${color}`,
        paddingLeft: 5,
        paddingTop: 2
    } : undefined;
}

//#endregion

const _swal = withReactContent(Swal);


interface Props {
    id: string;
    cardGroupName: string
}

const CardDetails = ({ id, cardGroupName }: Props) => {
    const { cardStore, boardStore, cardMemberStore, cardDateStore, cardChecklistStore, dateModalStore } = useStore();
    const { cardMembers } = cardMemberStore;
    const { isChecklistTitleChange, setIsChecklistTitleChange, setIsChecklistItemChange, isChecklistItemChange } = cardChecklistStore;
    const { openModal } = dateModalStore;
    const { isLoading, card, deleteCard } = cardStore;
    const { activeBoard } = boardStore;
    const { getSchedule, deleteSchedule, cardDate } = cardDateStore;
    const [schedule, setSchedule] = useState<CardDate | undefined>(undefined);
    const [activeCheckListId, setActiveCheckListId] = useState<string | undefined>()
    const [activeItemId, setActiveItemId] = useState<string | undefined>()

    const [activeAddItemChecklistId, setActiveAddItemChecklistId] = useState<string | undefined>()
    const [onOpenModal, setOnOpenModal] = useState<boolean | undefined>()

    const handleTitleFocus = (checklistId) => {
        if (isChecklistTitleChange) {
            _swal.fire({
                title: 'Discard Changes?',
                text: "You have an unsaved edits, Do you want to discard the changes?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    setActiveCheckListId("");
                    setActiveCheckListId(checklistId);
                    setActiveItemId("");
                    setIsChecklistTitleChange(false);
                    setActiveAddItemChecklistId("")
                } else {
                    setIsChecklistTitleChange(false);
                    let activeCheckList = activeCheckListId;
                    setActiveItemId("");
                    setActiveCheckListId("");
                    setActiveCheckListId(activeCheckList);
                    setActiveAddItemChecklistId("")
                    setIsChecklistTitleChange(true);
                }
            });
        } else {
            if (isChecklistItemChange) {
                _swal.fire({
                    title: 'Discard Changes?',
                    text: "You have an unsaved edits, Do you want to discard the changes?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'No',
                    confirmButtonText: 'Yes'
                }).then((result) => {
                    if (result.isConfirmed) {
                        setActiveItemId("")
                        setActiveItemId(activeItemId);
                        setActiveCheckListId(checklistId);
                        setActiveAddItemChecklistId("")
                        setActiveItemId("")
                        setIsChecklistItemChange(false);
                    } else {
                        setIsChecklistItemChange(false);
                        let activeItem = activeItemId;
                        setActiveItemId("");
                        setActiveItemId(activeItem);
                        setActiveCheckListId("")
                        setActiveAddItemChecklistId("")
                        setIsChecklistItemChange(true);
                    }
                });
            } else {
                setActiveCheckListId(checklistId);
                setActiveItemId("");
                setActiveAddItemChecklistId("")
            }

        }

    }

    const handleItemFocus = (itemId) => {
        if (isChecklistTitleChange) {
            _swal.fire({
                title: 'Discard Changes?',
                text: "You have an unsaved edits, Do you want to discard the changes?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    setActiveCheckListId("");
                    setActiveCheckListId(activeCheckListId);
                    setActiveItemId(itemId);
                    setIsChecklistTitleChange(false);
                    setActiveCheckListId("");
                    setActiveAddItemChecklistId("")
                } else {
                    setIsChecklistTitleChange(false);
                    let activeCheckList = activeCheckListId;
                    setActiveItemId("");
                    setActiveCheckListId("");
                    setActiveCheckListId(activeCheckList);
                    setActiveAddItemChecklistId("")
                    setIsChecklistTitleChange(true);
                }
            });
        } else {
            if (isChecklistItemChange && itemId != activeItemId) {
                _swal.fire({
                    title: 'Discard Changes?',
                    text: "You have an unsaved edits, Do you want to discard the changes?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'No',
                    confirmButtonText: 'Yes'
                }).then((result) => {
                    if (result.isConfirmed) {
                        setActiveItemId("")
                        setActiveItemId(itemId);
                        setActiveCheckListId("");
                        setActiveAddItemChecklistId("")
                        setIsChecklistItemChange(false);
                    } else {
                        setIsChecklistItemChange(false);
                        let activeItem = activeItemId;
                        setActiveItemId("");
                        setActiveItemId(activeItem);
                        setActiveCheckListId("")
                        setActiveAddItemChecklistId("")
                        setIsChecklistItemChange(true);
                    }
                });
            } else {
                setActiveItemId(itemId);
                setActiveCheckListId("");
                setActiveAddItemChecklistId("")
            }

        }

    }

    const handleAddItemTitleFocus = (activeAddItemChecklistId) => {
        if (isChecklistTitleChange) {
            _swal.fire({
                title: 'Discard Changes?',
                text: "You have an unsaved edits, Do you want to discard the changes?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    setActiveCheckListId("");
                    setActiveCheckListId(activeCheckListId);
                    setActiveAddItemChecklistId(activeAddItemChecklistId);
                    setActiveCheckListId("")
                    setActiveItemId("");
                    setIsChecklistTitleChange(false);
                } else {
                    setIsChecklistTitleChange(false);
                    let activeCheckList = activeCheckListId;
                    setActiveItemId("");
                    setActiveCheckListId("");
                    setActiveCheckListId(activeCheckList);
                    setActiveAddItemChecklistId("")
                    setIsChecklistTitleChange(true);
                }
            });
        } else {
            if (isChecklistItemChange) {
                _swal.fire({
                    title: 'Discard Changes?',
                    text: "You have an unsaved edits, Do you want to discard the changes?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'No',
                    confirmButtonText: 'Yes'
                }).then((result) => {
                    if (result.isConfirmed) {
                        setActiveItemId("")
                        setActiveItemId(activeItemId);
                        setActiveCheckListId("");
                        setActiveAddItemChecklistId(activeAddItemChecklistId);
                        setActiveItemId("")
                        setIsChecklistItemChange(false);
                    } else {
                        setIsChecklistItemChange(false);
                        let activeItem = activeItemId;
                        setActiveItemId("");
                        setActiveItemId(activeItem);
                        setActiveCheckListId("")
                        setActiveAddItemChecklistId("")
                        setIsChecklistItemChange(true);
                    }
                });
            } else {
                setActiveAddItemChecklistId(activeAddItemChecklistId);
                setActiveCheckListId("")
                setActiveItemId("");
            }

        }

    }

    const handleCancelTitleEdit = () => {
        setActiveCheckListId("")
        setIsChecklistTitleChange(false);
    }
    const handleOpenPopupCheckist = () => {

        if (isChecklistTitleChange) {
            _swal.fire({
                title: 'Discard Changes?',
                text: "You have an unsaved edits, Do you want to discard the changes?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    setActiveCheckListId("");
                    setActiveCheckListId(activeCheckListId);
                    setActiveAddItemChecklistId("");
                    setActiveCheckListId("")
                    setActiveItemId("");
                    setIsChecklistTitleChange(false);

                    setTimeout(function () {
                        setOnOpenModal(!onOpenModal);
                    }, 300);
                } else {
                    setTimeout(function () {
                        setIsChecklistTitleChange(false);
                        let activeCheckList = activeCheckListId;
                        setActiveItemId("");
                        setActiveCheckListId("");
                        setActiveCheckListId(activeCheckList);
                        setActiveAddItemChecklistId("")
                        setIsChecklistTitleChange(true);
                    }, 300);

                }
            });
        } else {
            if (isChecklistItemChange) {
                _swal.fire({
                    title: 'Discard Changes?',
                    text: "You have an unsaved edits, Do you want to discard the changes?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'No',
                    confirmButtonText: 'Yes'
                }).then((result) => {
                    if (result.isConfirmed) {
                        setActiveItemId("")
                        setActiveItemId(activeItemId);
                        setActiveCheckListId("");
                        setActiveAddItemChecklistId("");
                        setActiveItemId("")
                        setIsChecklistItemChange(false);
                        setTimeout(function () {
                            setOnOpenModal(!onOpenModal);
                        }, 300);
                    } else {
                        setTimeout(function () {
                            setIsChecklistItemChange(false);
                            let activeItem = activeItemId;
                            setActiveItemId("");
                            setActiveItemId(activeItem);
                            setActiveCheckListId("")
                            setActiveAddItemChecklistId("")
                            setIsChecklistItemChange(true);
                        }, 300);

                    }
                });
            } else {
                setActiveAddItemChecklistId("");
                setActiveCheckListId("")
                setActiveItemId("");
                setOnOpenModal(!onOpenModal);
            }

        }
        //orig
        //setOnOpenModal(!onOpenModal);
    }


    const handleCancelItemEdit = () => {
        setActiveItemId("")
        setIsChecklistItemChange(false);
    }


    useEffect(() => {
        setIsChecklistTitleChange(false);
        cardStore.loadCard(id);
        if (id) getSchedule(id);
        return () => {
            cardStore.stopListening(id);
            cardStore.reset();
        }
    }, [cardStore, id]);

    useEffect(() => {
        runInAction(async () => {
            setSchedule(cardDate);
        });
    }, [cardDate]);

    const handleOnDelete = () => {
        _swal.fire({
            title: 'Delete?',
            text: "Are you sure you want to delete this Task?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                _swal.disableButtons();
                deleteCard(card!.id, card!.cardGroupId);
            }
        });
    };

    const handleOnScheduleIndicatorClick = () => {
        openModal(<DateDetails cardId={card!.id} />)
    };

    const formattedScheduleDetails = () => {
        let date = new Date(schedule!.startDate + 'Z'),
            formattedDate = moment(date).format('MMM D'),
            startTime = new Date(schedule!.startTime + 'Z'),
            formattedStartTime = moment(startTime).format('hh:mm a');

        return {
            formattedDate,
            formattedStartTime
        };
    };

    const handleScheduleCloseIconClick = (e) => {
        _swal.fire({
            title: 'Remove?',
            text: "Are you sure you want to remove this schedule?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                _swal.disableButtons();
                deleteSchedule(card!.id);
            }
        });
    }

    if (!card) return <CardPlaceholder />

    return (
        <>
            <Grid>
                <Grid.Column width={15}>
                    <Item.Group>
                        <Item key="card-title">
                            <TitleIcon />
                            <ItemContent>
                                <TitleInput card={card!} />
                            </ItemContent>
                        </Item>

                        <Item key="card-group-name">
                            <ItemInListContent> <span style={{ color: 'rgba(0, 0, 0, .6)' }} >in list </span><span style={{ color: 'rgba(0, 0, 0, .6)', fontWeight: 'bold' }} >{cardGroupName}</span></ItemInListContent>
                        </Item>
                        <Item.Group key="card-priority" style={{ display: card?.priority ? "flex" : "none", flexDirection: "column", marginTop: -1 }}>
                            <span style={{
                                marginLeft: '47px',
                                color: "rgba(0, 0, 0, 0.6)",
                                fontWeight: "bold",
                                fontSize: "1em",
                                lineHeight: 1.4
                            }}>Priority</span>
                            <ItemInListContentPriority style={card?.priority ? getPriorityColor(card.priority.id) : {}}>{card?.priority?.name}</ItemInListContentPriority>
                        </Item.Group>
                    </Item.Group>
                </Grid.Column>
                <Grid.Column style={{ paddingTop: 0 }} width={12}>
                    <Grid.Row>

                        {schedule && (
                            <span style={{ width: 178, display: 'inline-block', marginLeft: '47px', position: 'relative' }} >
                                <ScheduleCloseIconStyled size="small" onClick={handleScheduleCloseIconClick} >
                                    <MdClear />
                                </ScheduleCloseIconStyled>
                                <ScheduleMenu onClick={handleOnScheduleIndicatorClick} fluid>
                                    <i className="long alternate left calendar icon"></i> {formattedScheduleDetails()?.formattedDate + " at " + formattedScheduleDetails()?.formattedStartTime} &nbsp;<i className="down angle icon"></i>
                                </ScheduleMenu>
                            </span>)}

                        <Item.Group style={{ marginLeft: '35px', color: 'rgba(0, 0, 0, .6)', fontWeight: 'bold' }}>
                            {cardMembers.length == 0 ? null : <span style={{ marginLeft: '15px' }}>Members</span>}
                            <StyledMember>
                                <ItemContent>
                                    <CardMembersContainer cardId={card?.id!} createdUserId={card?.createdUserId} />
                                </ItemContent>
                            </StyledMember>
                        </Item.Group>
                        <Item.Group>
                            <StyledItem>
                                <DescriptionIcon />
                                <ItemContent>
                                    <DescriptionInput card={card!} />
                                </ItemContent>
                            </StyledItem>
                        </Item.Group>
                        <Item.Group style={{ marginTop: '32px', padding: '1em 0' }}>
                            {card?.attachments && card?.attachments.length != 0 && (<AttachmentList attachments={card!.attachments!} cardId={id} />)}
                            {card?.checklists && card.checklists.map(c => (
                                <Checklist
                                    activeAddItemChecklistId={activeAddItemChecklistId}
                                    handleAddItemTitleFocus={handleAddItemTitleFocus}
                                    activeItemId={activeItemId}
                                    handleItemFocus={handleItemFocus}
                                    activeChecklistId={activeCheckListId}
                                    handleTitleFocus={handleTitleFocus} key={c.id}
                                    checklist={c}
                                    onCancelTitleEdit={handleCancelTitleEdit}
                                    onCancelItemEdit={handleCancelItemEdit}
                                />
                            ))}
                            <StyledItem>
                                <ActivityIcon />
                                <ItemContent>
                                    <CardActivityList />
                                </ItemContent>
                            </StyledItem>
                            <StyledItem>
                                <ItemContent style={{ display: 'flex', flexDirection: 'column' }}>
                                    <CommentList card={card!} />
                                </ItemContent>
                            </StyledItem>
                        </Item.Group>

                    </Grid.Row>
                </Grid.Column>
                <Grid.Column width={4} style={{ padding: '1em 1em 1em 0' }}>
                    <Grid.Row>
                        <Header as='h4' style={{ fontSize: '14px' }}>
                            Add to task
                        </Header>
                        <Divider />
                        <MembersMenu cardId={card?.id!} workspaceId={activeBoard?.workSpaceId!} />
                        <PriorityMenu cardId={card?.id!} priorityId={card.priority ? card.priority.id : ""} />
                        <ChecklistMenu onOpen={handleOpenPopupCheckist} openModal={onOpenModal} cardId={card?.id!} />
                        <DateMenu cardId={card?.id!} />
                        <AttachmentMenu cardId={card?.id!} />
                    </Grid.Row>
                    <Grid.Row style={{ marginTop: '24px' }}>
                        <Header as='h4' style={{ fontSize: '14px' }}>
                            Actions
                        </Header>
                        <Divider />
                        <Menu icon='long arrow alternate right' onClick={handleOnDelete} labelPosition='left' content='Delete' fluid />
                    </Grid.Row>
                </Grid.Column>
            </Grid>
        </>
    );
}

export default observer(CardDetails);