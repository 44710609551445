import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useStore } from "../../stores/store";
import NotificationItem from "./NotificationItem";
import { Icon, Header } from "semantic-ui-react";

import NotificationPlaceholder from "./NotificationPlaceholder";

const NotificationContainer = styled.div<{ isShow: boolean }>`
    && {
        display: ${p => (p.isShow ? 'grid' : 'none')};
        position: fixed;
        right: 15px;
        top: 68px;
        background-color: #fff;
        max-height: 85vh;
        min-height: 402px;
        box-shadow: 0 0 5px #b2b2b2;
        width: 350px;
        border-radius: 6px;
        padding: 0px 0px;
        grid-template-rows: auto 1fr auto;
    }
`;
const NotificationHeader = styled.div`
    && {
        background-color: #1692ff;
        height: 45px;
        display: flex;
        width: auto;
        justify-content: center;
        align-items: center;
    }
`;
const NotificationList = styled.div`
    && {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: auto;

        ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
            border-radius: 10px;
            background-color: #F5F5F5;
        }

        ::-webkit-scrollbar {
            width: 5px;
            background-color: #c6e3fa;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
            background-color: #c6e3fa;
        }
    }
`;

const NotificationFooter = styled.div`
    && {
        height: 49px;
        display: flex;
        width: auto;
        justify-content: center;
        align-items: center;
        margin-top: -1px;
    }
`;
const Placeholder = styled.div`
    && {
      display: flex;
        flex-direction: column;
        align-content: center;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
    }
`;

const NoMessagesPlaceholder = styled(Header)`
    &&{
        opacity: .45;
        margin-top: 5px;
    }
`;


function useOuterClick(callback: any) {
    const callbackRef = useRef<any>(); // initialize mutable ref, which stores callback
    const innerRef = useRef<any>(); // returned to client, who marks "border" element

    // update cb on each render, so second useEffect has access to current value 
    useEffect(() => { callbackRef.current = callback; });

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick);

        function handleClick(ev: MouseEvent | TouchEvent) {
            if (innerRef.current && callbackRef.current &&
                !innerRef!.current!.contains!(ev.target)
            ) callbackRef!.current!(ev);

            return false;
        }
    }, []); // no dependencies -> stable click listener

    return innerRef; // convenience for client (doesn't need to init ref himself) 
}

function NotificationPopupList() {
    const history = useHistory();

    const { notificationStore, commonStore } = useStore();

    const { isNotifcationShow, hideNotificationBox, filter, notifications } = notificationStore;
    const { isNotificationPage } = commonStore;
    const [isLoading, setIsLoading] = useState(true);

    const handleOnClick = () => {
        hideNotificationBox();
        filter();
        history.push(`/notifications`)
    }

    useEffect(() => {
        if (!isNotificationPage && isNotifcationShow && notifications.length === 0) {
            filter().then(() => {
                setIsLoading(false);
            });
        }
        if (notifications.length) {
            setIsLoading(false);
        }

    }, [isNotifcationShow]);


    const innerRef = useOuterClick(
        (ev: any) => {
            if (!ev.target.classList.contains('notification-box-button') && !ev.target.classList.contains('swal2-styled')) {
                hideNotificationBox();
            }
        }
    );

    return (

        <NotificationContainer ref={innerRef} isShow={(isNotifcationShow && !isNotificationPage)}>
            <NotificationHeader>
                <span style={{
                    color: 'white',
                    fontSize: '15px',
                    fontWeight: 600

                }}>Notifications</span>
            </NotificationHeader>
            <NotificationList>
                {!isLoading &&
                    <>
                    {
                        notifications.length ?

                            notifications.slice(0,10).map(notification => {
                                return <NotificationItem key={notification.id} notification={notification} />
                            }) :
                            <Placeholder>
                                <Icon name='bell outline' size='huge' disabled />
                                <NoMessagesPlaceholder as='h4'>No Notifications</NoMessagesPlaceholder>
                            </Placeholder>
                    }
                    </>
                }
                {isLoading && <NotificationPlaceholder />}

            </NotificationList>
            <NotificationFooter>
                {notifications.length ?
                    <a style={{
                        color: '#1592ff',
                        padding: '7px',
                        fontSize: '15px'

                    }}
                        onClick={handleOnClick}
                        href="javascript:void(0)">See all notifications</a>
                    : null
                 }
               
            </NotificationFooter>

        </NotificationContainer>

    )
}

export default observer(NotificationPopupList);