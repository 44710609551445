import React, { useState } from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { SemanticDatepickerProps } from 'react-semantic-ui-datepickers/dist/types';
import styled from 'styled-components';


const ContainerStyled = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: flex-start;
    justify-content: flex-start;

    & .field input {
        width: 245px !important;
    }

`;

interface Props {
    toolbarProps: any,
    dateFilterLabel?: string;
    onDateRangeFilter?: (startDate?: Date, endDate?: Date) => void | undefined;
    dateRangeValues?: Date[];
}

const DateRangeFilter = ({ toolbarProps, dateFilterLabel, onDateRangeFilter, dateRangeValues }: Props) => {
    let initialValues:Date[]| null = null;
    if (dateRangeValues && dateRangeValues![0] != null && dateRangeValues![1] != null)
        initialValues = dateRangeValues!;

    const [dates, setDates] = useState<Date[]|null>(initialValues!);

    const handleDatesChange = (event: React.SyntheticEvent | undefined, data: SemanticDatepickerProps) => {
        const dates = data.value as Date[];
        setDates(dates);
        if (onDateRangeFilter) {
            if (dates)
                onDateRangeFilter(dates[0], dates[1]);
            else
                onDateRangeFilter(undefined, undefined);
        }
    };
    return (
        <ContainerStyled>
            {dateFilterLabel && (<label style={{ textAlign: 'left', color: '#6a7e99', fontSize: '14px', marginBottom:'4px' }}>
                {dateFilterLabel}
            </label>)}
            <SemanticDatepicker
                iconPosition='left'
                allowOnlyNumbers={true}
                placeholder='Select Date Range'
                value={dates}
                onChange={handleDatesChange}
                type="range" />
        </ContainerStyled>
    );
}

export default DateRangeFilter;