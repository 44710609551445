import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import agent from '../api/agents';
import { FilterValues } from '../models/common/filterValues';
import { SubClient, SubClientFormValues } from '../models/subClient';
import { history } from '../../index';

export default class WorkSpaceStore {
    subClients: SubClient[] = [];
    subClientsTotal: number = 0;
    isSubClientListLoading: boolean = false;
    isLoading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    filterSubClients = async (filters: FilterValues) => {
        this.isSubClientListLoading = true;

        try {
            const { total, data } = await agent.SubClients.filter({
                id: filters.id,
                pageNumber: filters.pageNumber,
                pageSize: filters.pageSize,
            });
            runInAction(() => {
                this.subClients = data;
                this.subClientsTotal = total;
                this.isSubClientListLoading = false;

            });
        } catch (e) {
            console.log(e);
            this.isSubClientListLoading = false;
        }
    }

    loadSubClient = async (id: string) => {
        this.isLoading = true;

        try {
            let subClient = await agent.SubClients.get(id);
            this.isLoading = false;
            return subClient;
        } catch (e) {
            throw e;
        }
    }

    add = async (subClient: SubClientFormValues) => {
        try {
            const subClientId = await agent.SubClients.add(subClient);
            runInAction(() => {
                if (subClientId) {
                    toast.success('External user created successfully.');

                    // redirect?

                    return true;
                }
                return false;
            });
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    update = async (subClient: SubClientFormValues) => {
        try {
            const subClientId = await agent.SubClients.update(subClient);
            runInAction(() => {
                if (subClientId) {
                    toast.success('External user updated successfully.');
                    history.goBack();
                    return true;
                }
                return false;
            });
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    deleteSubClient = async (id: string, filters: FilterValues) => {
        try {
            await agent.SubClients.delete(id);
            this.filterSubClients(filters);
            runInAction(() => {
                toast.success('External user deleted successfully.');
            });
        } catch (e) {
            console.log(e);
        }
    }

}