import { Icon } from "semantic-ui-react"

const LoadingAttachment = () => {
    return (
        <div className='post-attachment-image'>
            <div className='loading-image'>
                <Icon.Group className='loading' size='huge'>
                    <Icon loading size='big' name='circle notch' />
                    <Icon name='image' />
                </Icon.Group>
            </div>
        </div>
    )
}

export default LoadingAttachment;