import { makeAutoObservable, runInAction } from "mobx";
import { User } from "../models/user";
import agent from "../api/agents";
import { OptionSelect } from "../models/option";
import { store } from './store';

export default class CardMemberStore {

    workSpaceMembers: User[] = [];
    cardMembers: User[] = [];
    isLoading: boolean = true;
    isLoadingCardMembers: boolean = true;
    isOpenPopOver: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    attachHubEvents = () => {
        if (store.boardStore.hubConnection) {
            store.boardStore.hubConnection!.on("LoadMembers", (cardId) => {
                runInAction(() => {
                });
            });

            store.boardStore.hubConnection!.on('ReceiveCardMembers', (data) => {
                this.cardMembers = data

            });
            store.boardStore.hubConnection!.on('ReceiveCardMembersUpdate', (data) => {
                this.cardMembers = data

            });

        }
    }

    getAllWorkSpaceUsers = async (cardId: string, workSpaceId: string) => {
        this.isLoading = true;
        try {

            this.workSpaceMembers = await agent.CardUser.getAll(cardId, workSpaceId, store.userStore.user!.clientId!, store.boardStore.createdById!);
            runInAction(() => {
                this.isLoading = false;
                return this.workSpaceMembers;
            });
           
        } catch (e) {
            this.isLoading = false;
            throw e;
        }
    }
    //getAllCardMembers = async (cardId: string) => {

    //    this.isLoadingCardMembers = true;
    //    try {
       
    //        const cardMembers = await agent.CardUser.getAllCardMembers(cardId);
    //        runInAction(() => {
    //            this.isLoadingCardMembers = false;
    //            this.cardMembers = cardMembers;
    //            return this.cardMembers;
    //        });
           
    //    } catch (e) {
    //        this.isLoadingCardMembers = false;
    //        throw e;
    //    }
    //}

    loadCardMembers = (cardId: string) => {
        this.isLoadingCardMembers = true;

        if (store.boardStore.hubConnection) {
            store.boardStore.hubConnection!.invoke("LoadMembers", cardId);
            this.isLoadingCardMembers = false;
        } else
            this.isLoadingCardMembers = false;
    }

    get memberOptionValues(): OptionSelect[]  {
        if (this.workSpaceMembers.length) {
            return this.workSpaceMembers
                .map(e => { return { value: e.id, label: e.name } as OptionSelect });
        } else {
            return [];
        }
    }


    addMultiple = async (cardId: string, userIds: string[]) => {
        try {
            await agent.CardUser.addMultiple(cardId, userIds);
            runInAction(() => {
                store.boardStore.hubConnection!.invoke("UpdateCardMembers", cardId);
                return Promise.resolve;
            });
        } catch (e) {
            return Promise.reject(e);
        }
    }

    removeMember = async (cardId: string, userId: string) => {
        try {
            await agent.CardUser.delete(cardId, userId);
            store.boardStore.hubConnection!.invoke("UpdateCardMembers", cardId);
        } catch (e) {
            console.log(e);
        }
    }


    setIsOpenPopOver = (value: boolean) => {
        this.isOpenPopOver = value;
    }




}



