import { Form, Formik, FormikHelpers } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import * as Yup from 'yup';
import FormSelect from '../../../app/common/form/FormSelect';
import FormTextArea from '../../../app/common/form/FormTextArea';
import TextInput from '../../../app/common/form/TextInput';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { WorkSpaceFormValues } from '../../../app/models/workSpace';
import { useStore } from '../../../app/stores/store';

const StyledColumn = styled(Grid.Column)`
        width: 436px !important;
        position: relative !important;
        top: 10em !important;
        padding: 54px 32px !important;
        box-shadow: 0px 3px 6px rgb(21 146 255 / 16%) !important;
        border-radius: 16px !important;
        background-color: #ffffff !important;
`;

const WorkSpaceForm = () => {
    const { workSpaceStore, workSpaceTypeStore: { getAllWorkSpaceTypes, isLoading: typeLoading } } = useStore();
    const { id } = useParams<{ id: string }>();
    const { loadWorkSpace, isLoading } = workSpaceStore;

    const [workSpace, setWorkSpace] = useState<WorkSpaceFormValues>(new WorkSpaceFormValues);

    const validationSchema = Yup.object({
        name: Yup.string().required('Workspace Name field is required.').max(64, 'Workspace Name field must be at most 64 characters'),
        description: Yup.string().max(255, 'Workspace Description field must be at most 255 characters'),
    });

    useEffect(() => {
        if (id) loadWorkSpace(id).then(type => setWorkSpace(new WorkSpaceFormValues(type)));
    }, [id, loadWorkSpace]);

    const handleSubmit = (values: WorkSpaceFormValues, { resetForm, setSubmitting }: FormikHelpers<WorkSpaceFormValues>) => {
        if (!workSpace.id) {
            workSpaceStore.add(values).then(isSuccess => {
                if (isSuccess) {
                    resetForm();
                }
                setSubmitting(false);
            })
        } else {
            workSpaceStore.update(values).then(isSuccess => {
                if (isSuccess) resetForm();
                setSubmitting(false);
            })
        }
    }

    if (isLoading) return <LoadingComponent content="Loading..." />

    return (
        <Formik
            enableReinitialize
            initialValues={workSpace}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}>
            {
                ({ handleSubmit, isSubmitting, dirty, isValid }) => (

                    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='top'>
                        <StyledColumn>
                            <Header as='h3' style={{ marginBottom: '32px', textAlign: 'left' }}>{workSpace.id ? 'Edit' : 'Add'} Workspace</Header>
                            <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                <TextInput name='name' placeholder='Workspace Name' label='Workspace Name' type='text' />
                                <FormTextArea rows={3} name='description' label='Workspace Description' placeholder='Workspace Description' />
                                <Grid columns='equal' style={{ marginTop: '30px', alignItems: 'center' }}>
                                    <Grid.Column style={{ textAlign: 'right' }}>
                                        <Button loading={isSubmitting}
                                            disabled={!isValid || isSubmitting || !dirty}
                                            color='blue' style={{ backgroundColor: '#1592ff', whiteSpace: 'nowrap' }}
                                            content='Save' type='submit' size='medium' />
                                        <Button color='grey' content='Cancel' as={Link} to='/workspaces' size='medium' />
                                    </Grid.Column>
                                </Grid>
                            </Form>
                        </StyledColumn>
                    </Grid>
                )
            }
        </Formik>
    );
}

export default observer(WorkSpaceForm);