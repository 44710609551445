import { IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { MdClear } from 'react-icons/md';
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import { Button, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { Card, CardFormValues } from '../../../app/models/card';
import { useStore } from '../../../app/stores/store';

//#region Styling

const ComponentWrapper = styled.div`
     && {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
     }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    flex-wrap: nowrap;
    margin-bottom: 16px;
`;

const StyledTextArea = styled(TextareaAutosize)`
    && {
        width:100%;
        overflow: hidden;
        overflow-wrap: break-word;
        background: #0000;
        border-radius: 3px;
        line-height: 24px;
        min-height: 24px;
        padding: 4px 8px;
        resize: none;
        background-color: #fff;
        box-shadow: inset 0 0 0 2px #0079bf;
        border: none;
        outline:none;
}
`;

const FakeTextbox = styled.p`
    background-color: #091e420a;
    border: none;
    border-radius: 3px;
    box-shadow: none;
    display: block;
    min-height: 60px;
    padding: 8px 12px;
    text-decoration: none;
    width:100%;
`;

//#endregion

interface Props {
    card: Card
}

const DescriptionInput = ({ card }: Props) => {
    const minRows = 3;
    const [open, setOpen] = useState(false);
    const [description, setDescription] = useState(card.description);
    const { cardStore } = useStore();
    const { update } = cardStore;

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(event.target.value);
    }

    const handleOnSave = async () => {
        if (description && description.length > 0 && description.length <= 5124) {
            card.description = description;
            update(new CardFormValues(card));
            setOpen(false);
        } else {
            toast.error('Card description should not be empty or exceed 5124 characters');
        }
    }

    const handleClear = () => {
        setDescription(card.description);
        setOpen(false);
    }

    useEffect(() => {
        setDescription(card.description);
    }, [card.description]);

    return (
        <ComponentWrapper>
            <HeaderContainer>
                <Header style={{ marginRight: '8px' }} as='h4'>Description</Header>
                {!open && description && description.length ? (<Button
                    compact
                    size='small'
                    onClick={() => setOpen(true)}
                >Edit</Button>) : ''}
            </HeaderContainer>
            {open ? (
                <>
                    <StyledTextArea
                        autoFocus
                        value={description}
                        onChange={handleOnChange}
                        minRows={minRows}
                        placeholder='Add a more detailed description...'
                    />
                    <ButtonContainer>
                        <Button compact color='blue' size='small' onMouseDown={handleOnSave}>
                            Save
                        </Button>
                        <IconButton onClick={handleClear}>
                            <MdClear />
                        </IconButton>
                    </ButtonContainer>
                </>
            ) : (description && description.length ?
                (<p style={{ whiteSpace: 'pre-wrap'}}>{description}</p>) :
                (<FakeTextbox onClick={() => setOpen(true)}>Add a more detailed description...</FakeTextbox>)
            )}

        </ComponentWrapper>
    );
}

export default DescriptionInput;