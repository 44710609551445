import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { Board } from '../../../app/models/board';
import { useStore } from '../../../app/stores/store';
import DateModalContainer from '../card-dates/DateModalContainer';
import CardList from '../card-lists/CardList';
import InputListContainer from '../card-lists/InputListContainer';

//#region Styling

const BoardContainer = styled.div`
        &&{
        display: flex;
        width: 100%;
        overflow-x: auto;
        margin-top:1.5em;
        height:93%;
        }
`;

const StyledContainer = styled.div`
        && {
            background: url("../../assets/kanban-bg.png");
            background-position: 50%;
            background-size: cover;
            padding-top: 1.5em;
            height: 94vh;
        }
`;

const StyledHeader = styled.div`
        && {
            color: #eee;
            font-size: 1.8em !important;
            margin-right:8px;
            font-weight: bold;
        }
`;


const Styledfooter = styled.div`
        && {
            color: #eee;
            font-size: 1.8em !important;
            margin-right:8px;
            margin-top: -50px;
        }
`;



//#endregion

const BoardDetails = () => {
    const { id } = useParams<{ id: string }>();
    const { boardStore, cardGroupStore } = useStore();
    const { board: boardData, isBoardDetailsLoading, updateBoardOrder } = boardStore;
    const history = useHistory()
    const backPreviousPage = () => {
        history.push(`/workspaces/board/${boardData?.workSpaceId}`)
    }

    useEffect(() => {
        if (id) boardStore.createHubConnection(id);

        return () => {
            boardStore.stopHubConnection();
        }
    }, [boardStore, id]);

    if (isBoardDetailsLoading) return <LoadingComponent content='Loading Project...' />

    const onDragEnd = (result: DropResult) => {
        if (boardData) {

            const { destination, source, draggableId, type } = result;

            if (!destination) return;

            if (type === 'list') {
                const newListOrder = boardData!.order;
                newListOrder.splice(source.index, 1);
                newListOrder.splice(destination.index, 0, draggableId);
                updateBoardOrder({ id: boardData!.id, order: newListOrder })
                return;
            }

            let clonedList = [...boardData.cardGroups];

            const sourceList = clonedList.find(l => l.id == source.droppableId);
            const destinationList = clonedList.find(l => l.id == destination.droppableId);

            if (sourceList && destinationList) {

                const draggingCard = sourceList.cards.filter(c => c.id === draggableId)[0];
                if (sourceList.order.length)
                    sourceList.order.splice(source.index, 1);

                if (destinationList.order)
                    destinationList!.order.splice(destination.index, 0, draggingCard.id);
                else
                    destinationList.order = [draggingCard.id];

                if (source.droppableId === destination.droppableId) {
                    const newState = {
                        ...boardData,
                        cardGroups: [...clonedList.filter(l => l.id != destinationList.id), destinationList]
                    };
                    boardStore.board = newState as Board;
                 
                } else {
                    let draggedCardIdIndex = sourceList.cards.findIndex(c => c.id == draggingCard.id);

                    if (draggedCardIdIndex != -1)
                        sourceList!.cards.splice(draggedCardIdIndex, 1);

                    if (destinationList.cards)
                        destinationList!.cards.splice(destination.index, 0, draggingCard);
                    else
                        destinationList.cards = [draggingCard];

                    const newState = {
                        ...boardData,
                        cardGroups: boardData?.cardGroups.map(l => {
                            if (l.id === destinationList?.id)
                                return destinationList;
                            else if (l.id === sourceList?.id)
                                return sourceList;
                            return l;
                        })
                    };
                    boardStore.board = newState as Board;
                }

                cardGroupStore.updateCardGroupOrder({
                    sourceCardGroup: { id: sourceList.id, order: sourceList.order },
                    destinationCardGroup: { id: destinationList.id, order: destinationList.order },
                    draggedId: draggingCard.id,
                    isSameDestination: source.droppableId === destination.droppableId
                });
            }
        }
    }

    return (
        <StyledContainer>
            <StyledHeader>
                {/*<button className="ui labeled icon button active blue" style={{ float: 'left' }} onClick={backPreviousPage} >*/}
                {/*    <i className="left chevron icon"></i>*/}
                {/*    Back*/}
                {/*</button>*/}
                <i className="left chevron icon" style={{ marginLeft: '10px', marginRight: '10px', cursor: 'pointer' }} title='Back' onClick={backPreviousPage}></i>
                {boardData!.name}
               
            </StyledHeader>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={boardData!.id} type='list' direction='horizontal'>
                    {(provided) => (
                        <BoardContainer ref={provided.innerRef} {...provided.droppableProps}>
                            {boardData?.order && boardData?.order && boardData!.order.map((id, index) => {
                                if (boardData.cardGroups) {
                                    let cardGroup = boardData!.cardGroups.find(c => c.id === id);

                                    if (cardGroup)
                                        return (<CardList list={cardGroup} key={id} index={index} />);
                                }
                            })}
                            {provided.placeholder}
                            <InputListContainer boardId={boardData!.id} />
                        </BoardContainer>
                    )}
                </Droppable>
            </DragDropContext>
            <Styledfooter>
              
                {/*<Button style={{ marginRight: '8px' }} color='blue' floated='right' onClick={backPreviousPage} content='Back' />*/}
            </Styledfooter>
            <DateModalContainer/>
        </StyledContainer>

    );
}

export default observer(BoardDetails);