import { useState } from 'react';
import Carousel, { Modal, ModalGateway } from "react-images";

interface Props {
    open: boolean,
    images: { source: string }[]
    onClose: any
    index?: number
}


const ImageAttachmentModal = (props: Props) => {
    if (!props.open) return <></>;

    return (
        <ModalGateway>
            {props.open ? (
                <Modal onClose={props.onClose}>
                    <Carousel
                        currentIndex={props.index ? props.index : 0}
                        views={props.images}
                    />
                </Modal>
            ) : null}
        </ModalGateway>
    )
}

export default ImageAttachmentModal;