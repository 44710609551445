import { Icon } from "semantic-ui-react"

interface Props {
    onClickLike: any
    isLiked: boolean
    onClickComment: any
}

const PostActions = ({ onClickLike, isLiked, onClickComment }: Props) => {
    return (
        <div className='post-actions'>
            <div onClick={onClickLike} className={`post-action ${isLiked && 'liked'}`} >
                <Icon name='thumbs up outline' /><span>Like</span>
            </div>
            <div onClick={onClickComment} className='post-action'>
                <Icon name='comment' /><span>Comment</span>
            </div>
        </div>
    )
}

export default PostActions;