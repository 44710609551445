import { makeAutoObservable } from "mobx";
import { CardCommentFormValues } from "../models/cardComment";
import { store } from "./store";

export default class CardCommentStore {
    isEdit: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    add = async (cardComment: CardCommentFormValues) => {
        try {
            store.boardStore.hubConnection?.invoke('SendComment', cardComment);

        } catch (e) {
            console.log(e);
        }
    }

    update = async (cardComment: CardCommentFormValues) => {
        try {
            store.boardStore.hubConnection?.invoke('UpdateComment', cardComment);
        } catch (e) {
            console.log(e);
        }
    }

    delete = async (commentId: string) => {
        try {
            store.boardStore.hubConnection?.invoke('DeleteComment', commentId);
        } catch (e) {
            console.log(e);
        }
    }

    setEdit = (isEdit: boolean) =>{
        this.isEdit = isEdit;
    }
}
