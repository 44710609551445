import { makeAutoObservable } from "mobx";
import agent from "../api/agents";
import { TimeZoneModel } from "../models/timeZone";

export default class TimeZoneStore {
    keyId: number = 0;
    isLoading: boolean = false;
    timeZones: TimeZoneModel[] = [];
    openModal: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    getAll = async () => {
        this.setIsLoading(true);

        try {
            this.timeZones = await agent.TimeZone.getAll();
        } catch (e) {
            console.log(e);
        }

        this.setIsLoading(false);
    }

    setIsLoading = (loading: boolean) => {
        this.isLoading = loading;
    };

    setModalOpen = (isOpen: boolean) => this.openModal = isOpen;

    incKeyId = () => this.keyId++;

}