import React, { useEffect, useState } from 'react';
import { useStore } from '../../stores/store';

interface Props {
    attachmentId: string;
}

const fileToDataUri = (file: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
        resolve(event.target!.result)
    };
    reader.readAsDataURL(file);
})

const HiddenImage = ({ attachmentId}: Props) => {
    const { commentAttachmentStore } = useStore();
    const [dataUri, setDataUri] = useState('');

    useEffect(() => {
        let unmounted = false;
        try {
            commentAttachmentStore.getBlob(attachmentId).then(v => {
                if (v != undefined && !unmounted)
                    fileToDataUri(v).then(dataUri => {
                        setDataUri(dataUri as string)
                    });
            });
        } catch (e) {
            if (!unmounted)
                setDataUri('/assets/default-profile-picture.svg');
        }

        return () => { unmounted = true };
    }, [attachmentId, commentAttachmentStore])

    return (
        <img src={dataUri || '/assets/default-profile-picture.svg'} style={{ display: 'none' }} />
    );
}

export default HiddenImage;