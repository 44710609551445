import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import styled from 'styled-components';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { useStore } from '../../../app/stores/store';
import EmployeeDetails from './EmployeeDetails';

const StyledContainer = styled.div`
        padding: ${props => props.theme.padding.featureContainer};
`;

const StyledCardContainer = styled(Container)`
    background-color: #fff;
    padding:2em;
    border-radius:16px;
    box-shadow: 0px 3px 6px rgb(21 146 255 / 16%);

`;

const EmployeeDetailsPage = () => {
    const { id } = useParams<{ id: string }>();
    const { employeeStore } = useStore();
    const { employee, loadEmployee, isEmployeeLoaded } = employeeStore;

    useEffect(() => {
        loadEmployee(id);
    }, [loadEmployee, id]);

    if (!isEmployeeLoaded) return <LoadingComponent content='Loading employee...' />

    return (
        <StyledContainer>
            <StyledCardContainer fluid >
                <EmployeeDetails employee={employee!} />
            </StyledCardContainer>
        </StyledContainer>
    );
}

export default observer(EmployeeDetailsPage);